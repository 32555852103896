import axios from "axios";
import {getRootUrl} from "./utils";

/**
 * @Author Rikenson JACQUES (The Tiger ^(..)^)
 * @Description: find dashboard data
 * ----------------------------------------------
 * */

export const findDashboardItem = (params) => {
    const {apiName, moduleName, suffix} = params;
    return axios.get(`
            ${getRootUrl(apiName ? apiName : `/`)}
            ${moduleName ? moduleName : `/`}
            ${suffix ? suffix : ``}`);
};

/**
 * @Author Rikenson JACQUES (The Tiger ^(..)^)
 * @Description: find dashboard data
 * ----------------------------------------------
 * */

export const findDashboard = (params) => {
    const {apiName, moduleName} = params;
    return axios.get(
        `${getRootUrl(apiName ? apiName : ``)}/${
            moduleName ? moduleName : ``
        }/dashboard`
    );
};

/**
 * @Author Rikenson JACQUES (The Tiger ^(..)^)
 * @Param entity :  Item to Save or Update
 * @Description: Save|Update the provided item in specified api
 * ------------------------------------------------------------
 * */
export const saveOrUpdate = (params) => {
    const {apiName, options, item, properties, verb} = params;

    console.log(item, 'in api ....')
    let curretItem = {...item};
    let itemS = {...item};
    delete itemS.id;
    delete curretItem.id;
    let lastItem = {...curretItem, id: curretItem?.itemId}
    delete lastItem.itemId;

    const saveUrl = `${getRootUrl(apiName ? apiName : ``)}${options ? `/${getRequestOptions(options)}` : ``}${properties ? `/${getRequestOptions(properties)}` : ``}`;
    const updateUrl = `${getRootUrl(apiName ? apiName : ``)}${options ? `/${getRequestOptions(options)}` : ``}${item ? `/${item.id}` : ``}`;
    return (item.id && verb) ? axios.put(updateUrl, item) : (item.id && !verb) ? axios.patch(updateUrl, lastItem) : axios.post(saveUrl, itemS);
};


/**
 * @Author Rikenson JACQUES (The Tiger ^(..)^)
 * @Param entity :  Item to Save or Update
 * @Description: Save|Update the provided item in specified api
 * ------------------------------------------------------------
 * */
export const patchEntity = (params) => {
    const {apiName, options, item} = params;

    const updateUrl = `${getRootUrl(apiName ? apiName : ``)}${options ? `/${getRequestOptions(options)}` : ``}`;
    return axios.patch(updateUrl, item);
};

/**
 * @Author Rikenson JACQUES (The Tiger ^(..)^)
 * @Param entity : item to delete
 * @Description: Delete the provided item in specified api
 * ----------------------------------------------------
 * */
export const deleteOne = (params) => {
    const {options, item, apiName, param} = params;
    return axios.delete(
        `${getRootUrl(apiName ? apiName : ``)}${options ? `/${getRequestOptions(options)}` : ``}${item ? `/${item.id}` : ``}${param ? `/${param}` : ''} `
    );
};

/**
 * @Author Rikenson JACQUES (The Tiger ^(..)^)
 * @Description: find data by specified property
 * ----------------------------------------------
 * */
export const findOne = params => {
    const {options, item, apiName} = params;

    const url = `${getRootUrl(apiName ? apiName : ``)}${options ? `/${getRequestOptions(options)}` : ``}${item ? `/${item.id}` : ``}`

    return axios.get(url);
};

/**
 * @Author Rikenson JACQUES (The Tiger ^(..)^)
 * @Description: change item status by specified property
 * -------------------------------------------------------
 * */
export const changeStatus = (params) => {
    const {apiName, options, item, status} = params;
    return axios.patch(
        `${getRootUrl(apiName ? apiName : ``)}${
            options ? `${options ? `/${getRequestOptions(options)}` : ``}/${item ? `${item.id}/` : ''}${status}` : ``
        }`
    );
};

/**
 * @Author Rikenson JACQUES (The Tiger ^(..)^)
 * @Description: find all items or item collection by criteria
 * ------------------------------------------------------------
 * */

export const findCollection = params => {

    const {apiName, options, criteria, itemId, filter, param} = params;
    const url = `${getRootUrl(apiName ? apiName : ``)}${options ? `/${getRequestOptions(options)}` : ``}${itemId ? `/${itemId}` : ``}${filter ? `?status=` + filter : ``}${criteria ? `?criteria=` + criteria : ``}${param ? param : ''}`

    return axios.get(url);
};

/**
 * @Author Rikenson JACQUES (The Tiger ^(..)^)
 * @Description: Verify if a specified property exists
 * ----------------------------------------------------
 * */
export const isPropertyExists = (params) => {
    const {apiName, moduleName, entity, property} = params;
    return axios.get(
        `${getRootUrl(apiName ? apiName : ``)}${
            moduleName ? `/${moduleName}/` : ``
        }${
            entity ? `/${entity}/` : ``
        }is-property-exists?property=${property}`
    );
};


export const getRequestOptions = (options) => {
    let reqOptions = ''

    options.forEach((element, key) => {
        reqOptions += `${element.toString().toLowerCase()}${key < (options.length - 1) ? '/' : ''}`;
    })
    return reqOptions;
};
