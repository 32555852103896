export class SlideModel {

    constructor(params) {
        if (params) {

            const {
                slides,
                fileName
            } = params;
            this.slides = slides && slides.map(v => ({
                file: v.slideFile?.fileName ? v.slideFile : null,
                description: {
                    enContent: v.enDesc,
                    frContent: v.frDesc,
                    htContent: v.htDesc
                },
                name: {
                    enContent: v.enName,
                    frContent: v.frName,
                    htContent: v.htName
                },
                fileName: !v.slideFile?.fileName ? v.slideFile : null
            }))
        }
    }
}


export class SliderModel {

    constructor(params) {
        if (params) {

            const {
                endescription,
                frdescription,
                htdescription,
                enname,
                frname,
                htname,
                pageName

            } = params;


            this.description = {
                enContent: endescription,
                frContent: frdescription,
                htContent: htdescription
            }

            this.name = {
                enContent: enname,
                frContent: frname,
                htContent: htname
            }
            this.pageName = pageName;
        }
    }
}


export class SliderMapper {

    constructor(params) {
        if (params) {

            const {
                name,
                description,
                pageName,
                slides

            } = params;

            this.slides = slides ? slides.map(v =>(
                {
                    slideFile: v.fileName,
                    frName: v.name.frContent,
                    enName: v.name.enContent,
                    htName: v.name.htContent,
                    frDesc: v.description.frContent ? v.description.frContent : null,
                    enDesc: v.description.enContent ? v.description.enContent : null,
                    htDesc: v.description.htContent ? v.description.htContent : null,
                }
            )) : [];

            this.endescription = description?.enContent ? description?.enContent : null
            this.frdescription = description?.frContent ? description?.frContent : null
            this.htdescription = description?.htContent ? description?.htContent : null

            this.enname = name?.enContent
            this.frname = name?.frContent
            this.htname = name?.htContent
            this.pageName = pageName;
        }
    }
}




