import types from "../../actions/types/dashboard/actionTypes";
import initialState from "../../../constants/utils/initialState";

const reducer = (state = initialState.dbReducer, action) => {

        switch (action.type) {



            /**  LOAD REQUEST DASHBOARD BY A SPECIFIC PROPERTY CASE */
            case types.LOAD_REQUESTS_DASHBOARD:
                return {...state.request, loadingItems: true};
            case types.LOAD_REQUESTS_DASHBOARD_SUCCEEDED:
                return {...state.request, items: action.payload, loadingItems: false};
            case types.LOAD_REQUESTS_DASHBOARD_FAILED:
                return {...state.request, loadingItems: false};


            /**  LOAD REQUEST DASHBOARD BY A SPECIFIC PROPERTY CASE */
            case types.LOAD_LEADERSHIP_DASHBOARD:
                return {...state, leadership: {loadingItems: true}};
            case types.LOAD_LEADERSHIP_DASHBOARD_SUCCEEDED:
                return {...state, leadership: {items: action.payload, loadingItems: false}};
            case types.LOAD_LEADERSHIP_DASHBOARD_FAILED:
                return {...state, leadership: {loadingItems: false}};


            /**  LOAD REQUEST DASHBOARD BY A SPECIFIC PROPERTY CASE */
            case types.LOAD_INDICATOR_DASHBOARD:
                return {...state, indicator: {loadingItems: true}};
            case types.LOAD_INDICATOR_DASHBOARD_SUCCEEDED:
                return {...state, indicator: {items: action.payload, loadingItems: false}};
            case types.LOAD_INDICATOR_DASHBOARD_FAILED:
                return {...state, indicator: {loadingItems: false}};


            case types.LOAD_COMMON_COUNT_ENTRIES_SUCCEEDED:
                return {...state, db: {counts: action.payload}};


            default:
                return state;
        }
    }
;

export default reducer;
