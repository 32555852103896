const actionTypes = {


    /**
     * @Author Louidor Jean-Paul
     * LOAD COMPANY ACTION TYPES
     * -------------------------------------------
     * */
    LOAD_COMPANY: "LOAD_COMPANY",
    LOAD_COMPANY_SUCCEEDED: "LOAD_COMPANY_SUCCEEDED",
    LOAD_COMPANY_FAILED: "LOAD_COMPANY_FAILED",


    LOAD_COMPANY_HELPFAQ: "LOAD_COMPANY_HELPFAQ",
    LOAD_COMPANY_HELPFAQ_SUCCEEDED: "LOAD_COMPANY_HELPFAQ_SUCCEEDED",
    LOAD_COMPANY_HELPFAQ_FAILED: "LOAD_COMPANY_HELPFAQ_FAILED",

    RESET_HELPFAQ: "RESET_HELPFAQ",


    UPDATE_COMPANY_HELPFAQ: "UPDATE_COMPANY_HELPFAQ",
    UPDATE_COMPANY_HELPFAQ_SUCCEEDED: "UPDATE_COMPANY_HELPFAQ_SUCCEEDED",
    UPDATE_COMPANY_HELPFAQ_FAILED: "UPDATE_COMPANY_HELPFAQ_FAILED",

    UPDATE_COMPANY_ABOUT_SUCCEEDED: "UPDATE_COMPANY_ABOUT_SUCCEEDED",
    UPDATE_COMPANY_VISION_SUCCEEDED: "UPDATE_COMPANY_VISION_SUCCEEDED",
    UPDATE_COMPANY_MISSION_SUCCEEDED: "UPDATE_COMPANY_MISSION_SUCCEEDED",
    UPDATE_COMPANY_ORIGIN_SUCCEEDED: "UPDATE_COMPANY_ORIGIN_SUCCEEDED",
    UPDATE_COMPANY_SUCCEEDED: "UPDATE_COMPANY_SUCCEEDED",
    LOAD_COMPANY_LOGO_SUCCEEDED: "LOAD_COMPANY_LOGO_SUCCEEDED",
    DELETE_COMPANY_LOGO_SUCCEEDED: "DELETE_COMPANY_LOGO_SUCCEEDED",
    UPDATE_COMPANY_LOGO_SUCCEEDED: "UPDATE_COMPANY_LOGO_SUCCEEDED",
    RESET_LOGO: "RESET_LOGO",
    UPDATE_COMMON_HELP_FAQ_SUCCEEDED: "UPDATE_COMMON_HELP_FAQ_SUCCEEDED",


};


export default actionTypes;
