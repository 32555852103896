import React, {useContext, useEffect, useState} from "react"
import {Field, reduxForm, change} from "redux-form";
import {FormattedMessage, injectIntl} from 'react-intl';
import {
    renderCheckbox,
    renderField,
    renderFreeField,
    renderRadio,
    renderSelect
} from "../../../../../common/utils/FormComponent";
import {withRouter} from "react-router";
import {getIntlMsg} from "../../../../../../constants/utils";
import {FileInput} from "../../../../../common/utils/FileInput";
import {connect, useDispatch, useSelector} from "react-redux";
import actions from "../../../../../../thunks/settings/categoryThunks";
import {GlobalContext} from "../../../../../index";
import {validate} from "./validator";
import FormFooter from "../../../../common/form/FormFooter";
import params from "../../../../../../constants/utils/params";
import TextEditorWYSIWYG from "../../../../../common/utils/RichTextEditorWYSIWYG";

import {
    Member,
    MemberMapper,
    News,
    NewsMapper,
    Sector,
    SectorMapper
} from "../../../../../../constants/models/adminModel";


const NewsForm = injectIntl(props => {
    const {
        intl, handleSubmit, pristine, valid, submitting, reset, history, touched, error
    } = props
    const validate = {}

    const {hideModal} = useContext(GlobalContext)

    const dispatch = useDispatch();


    const {item, loading, sectorItem, status, values} = useSelector(state => ({
        loading: state.companyReducer.loadingItem,
        item: state.companyReducer.item,
        logoItem: state.companyReducer.logoItem,
        status: state.adminReducer.status,
        sectorItem: state.adminReducer?.sectorItem,
        values: state.form?.sectorForm?.values
    }));

    const {languages} = params;
    const {lang, setLang} = useContext(GlobalContext);


    const onFormSubmit = element => {
        element.nameId = sectorItem?.name ? sectorItem?.name?.id : null
        element.descId = sectorItem?.description ? sectorItem?.description?.id : null
        element.aboutId = sectorItem?.about ? sectorItem?.about?.id : null
        const entity = new Sector(element)
        entity.id = sectorItem?.id ? sectorItem.id : null

        dispatch(actions.saveOrUpdateItem({
            options: ['sectors'], intl, action: sectorItem && sectorItem.id ? '' : 'save', item: entity,
        }))
    };

    const resetFormLogo = () => {
        hideModal('sectors')
        reset('sectors')
        dispatch(actions.resetItem({options: ['sectors']}))
    }


    const [dirty, setDirty] = useState(false)
    const handleChangeLanguage = val => {
        setLang(val)
        setDirty(false)
    }

    const handleChangeDirty = val => {
        setDirty(true)
    }

    const options = {options: ['sliders']};

    useEffect(() => {
        dispatch(actions.loadItems(options));
    }, [dispatch]);

    const galeries = useSelector(state => state.componentReducer?.items || []);
    const formattedGaleries = [];
    galeries.forEach(g => g.pageName === 'SECTOR' ?
        formattedGaleries.push({code: g?.id, name: g?.name?.frContent}) : {})


    if (status === "sectorSucceeded") resetFormLogo()
    return (<div className="container col-lg-12">
            <form onSubmit={handleSubmit(onFormSubmit)}>

                <div className="row pb-5">
                    {lang === 'fr' ?
                        <div className="label label-light-primary label-pill label-inline col-md-12 p-6"><span
                            className="">Francais</span></div> :
                        lang === 'en' ?
                            <div className="label label-light-info label-pill label-inline col-md-12 p-6"><span
                                className="">Anglais</span></div> :
                            <div className="label label-light-danger label-pill label-inline col-md-12 p-6"><span
                                className="">Kreyol</span></div>}
                </div>

                <div className="row pb-5">
                    <div className="col-sm-3">
                        {languages && languages.map((language, key) =>
                                <span className="mr-4" key={key}>
                            <label htmlFor={language.id} style={{cursor: "pointer"}}
                                   onClick={() => handleChangeLanguage(language.id)}>
                                <b className={'mr-1'}><FormattedMessage id={`app.label.${language.label}`}/></b>
                                <Field type="radio" component="input" name={'lang'} value={language.id} id={language.id}
                                       onClick={() => handleChangeLanguage(language.id)}/>
                            </label>
                        </span>
                        )}

                    </div>
                </div>

                <div className={'row'}>
                    <div className="col-md-2">
                        <div className={'row'}>
                            <div className="col-md-12">
                                <label htmlFor="test_type" className="font-weight-bold">
                                    Photo
                                </label>
                                <div>
                                    <Field component={FileInput} name={`sectorFile`} id={`logo`} type="file"
                                           validate={validate.error}/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-10">
                        <div className={'row'}>
                            <div className="col-sm-8">
                                <Field
                                    className="form-control form-control-sm"
                                    id={`${lang}Name`}
                                    name={`${lang}Name`}
                                    component={renderField}
                                    type="text"
                                    label={`${lang}name`}
                                    validate={validate.error}
                                />
                            </div>

                            <div className="col-sm-4">
                                <div className="form-group">

                                    <label htmlFor="test_type" className="font-weight-bold">
                                        Gallerie :
                                    </label>

                                    <Field name={`galleryId`} component="select" className="form-control">
                                        <option value={''}></option>
                                        {formattedGaleries && formattedGaleries.length > 0 ? formattedGaleries.map((item, i) =>
                                            <option value={item?.code} key={i}>
                                                {item && item?.name?.toUpperCase()}
                                            </option>
                                        ) : null}
                                    </Field>
                                    {touched && error &&
                                    <span className="form-text text-primary">
                                <FormattedMessage id={validate.error}/>
                        </span>}

                                </div>

                            </div>

                            <div className="col-sm-12 pt-4 pb-5">
                                <label><FormattedMessage id={`app.label.${lang}About`} defaultMessage={''}/></label>
                                <div style={{border: '1px solid #000', width: '100%'}}>
                                    <Field label={`${lang}About`}
                                           onChange={() => handleChangeDirty()}
                                           name={`${lang}About`}
                                           meta={{dirty: dirty}}
                                           lang={lang}
                                           id={`${lang}About`}
                                           component={TextEditorWYSIWYG}
                                    />
                                </div>

                            </div>

                            <div className="col-sm-12 pt-4 pb-5">
                                <label><FormattedMessage id={`app.label.${lang}description`}
                                                         defaultMessage={''}/></label>
                                <div style={{border: '1px solid #000', width: '100%'}}>
                                    <Field
                                        onChange={(e) => handleChangeDirty(e)}
                                        label={`${lang}Desc`}
                                        name={`${lang}Desc`}
                                        id={`${lang}Desc`}
                                        component={TextEditorWYSIWYG}
                                        meta={{dirty: dirty}}
                                        validate={validate.errors}
                                    />
                                </div>

                            </div>
                            <div className="col-sm-12">

                                {error && <li className="error">{error}</li>}

                                <FormFooter {...{
                                    item,
                                    loading,
                                    pristine,
                                    submitting,
                                    reset,
                                    valid,
                                    dispatch,
                                    history,
                                    module
                                }}/>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
})
const mapStateToProps = ({adminReducer: {sectorItem}, form}) => {
        const defaultState = {
            lang: 'fr',
            frAbout: " ",
            enAbout: " ",
            htAbout: " ",
            frDesc: " ",
            enDesc: " ",
            htDesc: " ",
            category: null,
            sectorFile: null
        }
        const initVal = sectorItem ? new SectorMapper(sectorItem) : null
        console.log("-------___+++++++==-----", initVal)
        return {
            initialValues: sectorItem !== null ? {...initVal, lang: 'fr'} : {...defaultState}
        }
    }
;

export default React.memo(withRouter(connect(mapStateToProps,)(reduxForm(
    {
        form: "sectorForm",
        enableReinitialize: true,
        validate,
    })(NewsForm))));
