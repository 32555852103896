import * as validator from "../../../../../../constants/utils/validator";


export const validate = values => {
    const errors = {};
    errors.newsFile = values.newsFile === null;
    errors.category = values.category === null;
    errors.frName = validator.validateString('frName', values.frName, 3, 500, true);
    errors.lastName = validator.validateString('lastName', values.lastName, 3, 150, true);
    errors.position = validator.validateString('position', values.position, 3, 150, true);

    if (!values.phones || !values.phones.length) {
        errors.phones = 'Au moins un telephone doit être inscrit'
    } else {
        const phonesArrayErrors = [];
        values.phones.forEach((phone, phoneIndex) => {
            const phoneErrors = {}
            if (!phone || !phone.phone) {
                phoneErrors.phone = 'app.label.required'
                phonesArrayErrors[phoneIndex] = phoneErrors
            }
        });
        if (phonesArrayErrors.length) {
            errors.phones = phonesArrayErrors
        }
    }

    if (!values.addresses || !values.addresses.length) {
        errors.addresses = {_error: 'Au moins une adresse doit être inscrite'}
    } else {
        const addressesArrayErrors = [];
        values.addresses.forEach((address, addressIndex) => {
            const addressesErrors = {}
            if (!address || !address.address) {
                addressesErrors.address = 'app.label.required'
                addressesArrayErrors[addressIndex] = addressesErrors
            }
        });
        if (addressesArrayErrors.length) {
            errors.addresses = addressesArrayErrors
        }
    }

    if (!values.emails || !values.emails.length) {
        errors.emails = 'Au moins un email doit être inscrit';
    } else {
        const emailsArrayErrors = [];
        values.emails.forEach((email, emailIndex) => {
            const emailsErrors = {}
            if (!email || !email.email) {
                emailsErrors.email = 'app.label.required'
                emailsArrayErrors[emailIndex] = emailsErrors
            }
        });
        if (emailsArrayErrors.length) {
            errors.emails = emailsArrayErrors
        }
    }

    // if (!values.services || !values.services.length) {
    //     errors.services = 'Au moins un service doit être inscrit';
    // } else {
    //     const servicesArrayErrors = [];
    //     values.services.forEach((service, serviceIndex) => {
    //         const serviceErrors = {}
    //         if (!service || !service.id) {
    //             serviceErrors.id = 'app.label.required'
    //             servicesArrayErrors[serviceIndex] = serviceErrors
    //         }
    //     });
    //     if (servicesArrayErrors.length) {
    //         errors.services = servicesArrayErrors
    //     }
    // }


    return errors
};


