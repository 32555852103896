import * as React from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {useParams, withRouter} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {useContext, useEffect} from "react";
import params from "../../../../../../constants/utils/params";
import {GlobalContext} from "../../../../../index";
import MaterialTable from 'material-table';
import {getIntlMsg, Localization} from "../../../../../../constants/utils";
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";
import actions from "../../../../../../thunks/common/coreThunks";
import moment from 'moment'
import Slides from "../form/formComponent";
import swal from "sweetalert";
import {canDisplay} from "../../../../../../constants/utils/security";

const HelpList = injectIntl((props) => {

    const dispatch = useDispatch();
    const {intl, formView, setFormView, handleSetModal} = props;
    const {page, id} = useParams();
    const options = {options: ['categories']};

    const {items, loading} = useSelector(state => ({
        loading: state.companyReducer.loadingItems,
        items: state.adminReducer?.grants || [],
    }));


    const {languages} = params;
    const {lang, setLang} = useContext(GlobalContext);

    const formattedItems = [];
    items.forEach((item, i) => {
        formattedItems.push({
            campaign_name: item.campaign?.name,
            fullName: item.supporter?.firstName + " " + item.supporter?.lastName,
            email: item.supporter?.email,
            paymentMethod: item.donation?.paymentMethod,
            amount: item.donation?.amount,
            currency: item.donation?.currency,
            feesCovered: item.donation?.feesCovered,
            count: i,
            // created: moment(item?.created).format('DD-MM-YYYY'),
        })
    })

    const columns = [
        {
            title: "Nom de la campagne",
            type: 'text',
            field: 'campaign_name'
        },
        {
            title: "Nom complet",
            type: 'text',
            field: 'fullName'
        },
        {
            title: "Email",
            type: 'text',
            field: 'email'
        }, {
            title: "Methode de paiement",
            type: 'text',
            field: 'paymentMethod'
        },
        {
            title: "Montant",
            type: 'text',
            field: 'amount'
        }, {
            title: "Type de monnaie",
            type: 'text',
            field: 'currency'
        },
        {
            title: "Frais",
            field: 'feesCovered'
        },
        // {
        //     title: "Date de creation",
        //     field: 'created'
        // },
    ]

    const handlePublishUnPublish = (id, status) => {

        swal({
            title: "voulez-vous vraiment",
            text: status === "publish" ? "le Publier" : "ne le pas Publier",
            icon: "warning",
            buttons: ["Non", "Oui"],
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    dispatch(actions.saveOrUpdateItem({
                        options: ['common', 'statistics'],
                        intl,
                        action: '',
                        item: {id: id},
                    }))
                } else {
                }
            });
    }
    const {userSession: {roles}} = useContext(GlobalContext);

    const sumSupporter = (data, currency) => {
        let sum = 0;
        data.forEach(v => {
            if (v.currency === currency) {
                sum = sum + 1;
            }
        })
        return sum
    }

    return (
        <React.Fragment>

            {items && items.length > 0 ?
                <>
                    <div
                        className="col-md-12 pt-4">
                        <MaterialTable
                            className="col-md-12"
                            title="Liste des dons"
                            columns={columns}
                            data={formattedItems ? formattedItems : []}
                            icon='unfold_less'
                            localization={Localization('fr')}
                            options={{
                                headerStyle: {
                                    backgroundColor: '#ff7f26',
                                    color: '#FFF',
                                    fontFamily: 'Ki',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontVariant: 'normal',
                                    lineHeight: 1,
                                    fontSize: '15px'
                                },
                                actionsColumnIndex: -1,
                                exportButton: true,
                                exportAllData: true,
                                pageSize: 10,
                                pageSizeOptions: [5, 10],

                            }}

                        />
                    </div>
                    <div className="col-md-8">
                            <div className="table-responsive">
                                <table className="table" style={{textAlign:'center'}}>
                                    <thead>
                                    <tr>
                                        <th className="font-weight-bold text-muted text-uppercase">Type de monnaie</th>
                                        <th className="font-weight-bold text-muted text-uppercase">Total</th>
                                        <th className="font-weight-bold text-muted text-uppercase">Quantite Supporteur
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {formattedItems.length > 0 ? getGroubByCurrency(formattedItems).map(v =>
                                        <tr className="font-weight-bolder">
                                            <td>{v.currency}</td>
                                            <td>{v.total}</td>
                                            <td>{sumSupporter(formattedItems, v.currency)}</td>
                                        </tr>) : null}

                                    </tbody>
                                </table>
                            </div>
                    </div>
                </>
                :
                <SkeletonTheme color={'rgba(255,127,38,0.68)'} highlightColor="#444">
                    <p>
                        <Skeleton count={3}/>
                    </p>
                </SkeletonTheme>}
        </React.Fragment>
    );
})

const getGroubByCurrency = data => {
    const amountByCurrency = {};
    for (const {currency, amount} of data) {
        amountByCurrency[currency] = parseFloat(parseFloat(amountByCurrency[currency] || 0) + parseFloat(amount)).toFixed(2)
    }
    const obj = Object.entries(amountByCurrency)
        .map(([currency, total]) => ({
            currency,
            total
        }))
    ;

    return obj;
}


export default React.memo(withRouter(HelpList))

