import * as validator from "../../../../../../constants/utils/validator";


export const validate = values => {
    const errors = {};
    errors.frtitle = validator.validateString('frtitle', values.frtitle, 3, 1000, true);
    errors.frdescription = validator.validateString('frdescription', values.frdescription, 10, 1000000, true);

    return errors
};


