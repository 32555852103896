import types from "../../actions/types/component/actionTypes";
import initialState from "../../../constants/utils/initialState";


const reducer = (state = initialState.componentReducer, action) => {

        switch (action.type) {

            case types.RESET_SLIDER:
                return {...state, status: null, sliderItem: null};

            case types.RESET_STATISTIC:
                return {...state, status: null, statisticItem: null};

            case types.LOAD_SLIDERS:
                return {...state, loadingItems: action.payload};

            case types.LOAD_SLIDERS_SUCCEEDED:
                return {...state, items: action.payload};

            case types.DELETE_SLIDER_SUCCEEDED:
                return {
                    ...state,
                    items: state.items.map(item => item.id === action.payload.item.id ? action.payload.item : item),
                    status: 'sliderSucceeded'
                };

            case types.LOAD_STATISTICS_SUCCEEDED:
                return {...state, statistics: action.payload};

            case types.LOAD_SLIDERS_FAILED:
                return {...state, loadingItems: action.payload};

            case types.LOAD_SLIDER_SUCCEEDED:
                return {...state, sliderItem: action.payload};

            case types.UPDATE_COMMON_SLIDE_SUCCEEDED:
                const st = {...state.sliderItem};
                const f = st.slides.map(v => v.id === action.payload.id ? action.payload : v)
                delete st.slides;
                return {...state, sliderItem: {...st, slides: f}}

            case types.SAVE_SLIDER_SUCCEEDED:
                return {
                    ...state,
                    items: [...state.items, action.payload],
                    status: 'sliderSucceeded'
                }


            case types.UPDATE_SLIDER_SUCCEEDED:
            case types.UPDATE_COMMON_SLIDER_SUCCEEDED:
                return {
                    ...state,
                    items: state.items.map(item => item.id === action.payload.id ? action.payload : item),
                    status: 'sliderSucceeded'
                };


            case types.SAVE_STATISTIC_SUCCEEDED:
                return {
                    ...state,
                    statistics: [...state.statistics, action.payload],
                    status: 'statSucceeded'
                }


            case types.UPDATE_STATISTIC_SUCCEEDED:
            case types.UPDATE_COMMON_STATISTIC_SUCCEEDED:
                return {
                    ...state,
                    statistics: state.statistics.map(item => item.id === action.payload.id ? action.payload : item),
                    status: 'statSucceeded'
                };
            case types.LOAD_STATISTIC_SUCCEEDED:
                return {...state, statisticItem: action.payload};

            default:
                return state;
        }
    }
;

export default reducer;
