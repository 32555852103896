import * as validator from "../../../../../../constants/utils/validator";


export const validate = values => {
    const errors = {};
    errors.donateFile=values.donateFile === null;
    errors.category=values.category === null;
    errors.Name = validator.validateString('Name', values.Name, 3, 150, true);
    // errors.frAbout = validator.validateString('frAbout', values.frAbout, 3, 150, true);
    // errors.frDesc = validator.validateString('frDesc', values.frAbout, 3, 150, true);


    return errors
};


