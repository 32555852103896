import * as React from "react";
import useDocumentTitle from "../../../../../common/hooks/useDocumentTitle";
import {getIntlMsg, nameAcronym} from "../../../../../../constants/utils";
import {FormattedMessage, injectIntl} from "react-intl";
import {useParams, withRouter} from "react-router";
import {connect, useDispatch, useSelector} from "react-redux";
import useEntityManager from "../../../../../common/hooks/useEntityManager";
import actions from "../../../../../../thunks/common/coreThunks";
import {Field, reduxForm, FieldArray} from "redux-form";
import {validate} from "./validator";
import RenderSlider from "./slider";
import FormFooter from "../../../../common/form/FormFooter";
import {SliderMapper, SliderModel} from "../../../../../../constants/models/SliderModel";

const SliderForm = injectIntl((props) => {

    const {
        intl,
        handleSubmit, pristine, valid, submitting, reset, initialValues, history, touched, setView, view,error
    } = props;

    const {page} = useParams();
    useDocumentTitle(getIntlMsg(intl, `app.label.${page}`));


    const dispatch = useDispatch();


    const options = {options: ['sliders']};

    const params = {options: [page]};
    const {handleLoadItems} = useEntityManager(params, page)
    const {item, loading, status} = useSelector(state => ({
        loading: state.componentReducer.loadingItem,
        item: state.componentReducer.sliderItem,
        status: state.componentReducer.status,
    }));


    const onFormSubmit = element => {

        const entity = new SliderModel(element)
        entity.id = item?.id ? item.id : null

        dispatch(actions.saveOrUpdateItem({
            options: ['sliders'], intl, action: item && item.id ? '' : 'save', item: entity,
        }))
    };


    return (
        <React.Fragment>
            <div className="flex-column-fluid pt-5">
                <div className="container">
                    <div className="card card-custom">
                        <div className="card-body">
                            <form onSubmit={handleSubmit(onFormSubmit)}>

                                <div className={'row'}>
                                    <div className={'col-sm-12'}>
                                        <RenderSlider reset={reset} status={status} error={error}/>
                                    </div>
                                </div>

                                <FormFooter {...{
                                    item,
                                    loading,
                                    pristine,
                                    submitting,
                                    reset,
                                    valid,
                                    dispatch,
                                    history,
                                    module
                                }}/>

                            </form>


                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>

    );
});

const mapStateToProps = ({componentReducer: {sliderItem}}) => {
    const defaultState = {
        lang: 'fr',
        frdescription: " ",
        endescription: " ",
        htdescription: " ",
    }
    const initVal = sliderItem ? new SliderMapper(sliderItem) : null;
    return {
        initialValues: sliderItem !== null ? {...initVal, lang: 'fr'} : {...defaultState}
    }
};

export default React.memo(withRouter(connect(mapStateToProps,)(reduxForm({
    form: "sliderForm",
    enableReinitialize: true,
    validate,
})(SliderForm))));


