import * as React from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {useParams, withRouter} from "react-router";
import {connect, useDispatch, useSelector} from "react-redux";
import useEntityManager from "../../../../../common/hooks/useEntityManager";
import actions from "../../../../../../thunks/common/coreThunks";
import {Field, reduxForm, FieldArray} from "redux-form";
import {validate} from "./validator";
import renderSlides from "./slides";
import FormFooter from "../../../../common/form/FormFooter";
import {SlideModel, SliderMapper} from "../../../../../../constants/models/SliderModel";
import swal from "sweetalert";

const SlideForm = injectIntl((props) => {

    const {
        intl,
        handleSubmit, pristine, valid, submitting, reset, history,
    } = props;

    const {page} = useParams();


    const dispatch = useDispatch();

    const params = {options: [page]};

    const {item, loading, status} = useSelector(state => ({
        loading: state.componentReducer.loadingItem,
        item: state.componentReducer.sliderItem,
        status: state.componentReducer.status,
    }));


    const onFormSubmit = element => {
        element.fileName = item && item?.fileName
        const entity = new SlideModel(element)
        entity.id = item?.id ? item.id : null
        entity.itemId = item?.id ? item.id : null
        dispatch(actions.saveOrUpdateItem({
            options: ['sliders'], intl, action: item && item.id ? '' : 'save', item: entity,
        }))
    };


    return (

        <div className="card card-custom">
            <div className="card-body">
                <form onSubmit={handleSubmit(onFormSubmit)}>

                    <div className={'row'}>
                        <div className={'col-sm-12'}>

                            <FieldArray name="slides" component={renderSlides}/>

                        </div>
                    </div>

                    <FormFooter {...{
                        item,
                        loading,
                        pristine,
                        submitting,
                        reset,
                        valid,
                        dispatch,
                        history,
                        module
                    }}/>

                </form>


            </div>
        </div>

    );
});

const mapStateToProps = ({componentReducer: {sliderItem}}) => {
    const defaultState = {
        lang: 'fr',
        frDesc: " ",
        enDesc: " ",
        htDesc: " ",
        frName: " ",
        enName: " ",
        htName: " ",
    }

    const initVal = sliderItem ? new SliderMapper(sliderItem) : null;
    return {
        initialValues: sliderItem !== null ? {...initVal, lang: 'fr'} : {...defaultState}
    }
};

export default React.memo(withRouter(connect(mapStateToProps,)(reduxForm({
    form: "slideForm",
    enableReinitialize: true,
    validate,
})(SlideForm))));


