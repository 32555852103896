import * as React from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {useParams, withRouter} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {useContext, useEffect} from "react";
import params from "../../../../../../constants/utils/params";
import {GlobalContext} from "../../../../../index";
import MaterialTable from 'material-table';
import {getIntlMsg, Localization} from "../../../../../../constants/utils";
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";
import actions from "../../../../../../thunks/common/coreThunks";
import moment from 'moment'
import Slides from "../form/formComponent";
import swal from "sweetalert";
import {canDisplay} from "../../../../../../constants/utils/security";

const HelpList = injectIntl((props) => {

    const dispatch = useDispatch();
    const {intl, formView, setFormView, handleSetModal} = props;
    const {page, id} = useParams();
    const options = {options: ['categories']};

    const {items, loading} = useSelector(state => ({
        loading: state.companyReducer.loadingItems,
        items: state.adminReducer?.messages || [],
    }));


    const {languages} = params;
    const {lang, setLang} = useContext(GlobalContext);

    const formattedItems = [];
    items.forEach(item => {
        formattedItems.push({
            fullName: item?.fullName,
            email: item?.email,
            phone: item?.phone,
            message: item?.message,
            created: moment(item?.created).format('DD-MM-YYYY'),
            id: item?.id,
            status: item?.status,
        })
    })

    const columns = [
        {
            title: "Nom complet",
            type: 'text',
            field: 'fullName'
        },
        {
            title: "E-mail",
            type: 'text',
            field: 'email'
        },
        {
            title: "Telephone",
            type: 'text',
            field: 'phone'
        },
        {
            title: "Message",
            render: rowData => (<div className={'overflow-ellipsis'}>
                <p
                    dangerouslySetInnerHTML={{
                        __html: rowData?.message
                    }}>
                </p>
            </div>),
        },
        {
            title: "Date de creation",
            field: 'created'
        },
    ]

    const handlePublishUnPublish = (id, status) => {

        swal({
            title: "voulez-vous vraiment",
            text: status === "publish" ? "le Publier" : "ne le pas Publier",
            icon: "warning",
            buttons: ["Non", "Oui"],
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    dispatch(actions.saveOrUpdateItem({
                        options: ['common', 'statistics'],
                        intl,
                        action: '',
                        item: {id: id},
                    }))
                } else {
                }
            });
    }
    const {userSession: {roles}} = useContext(GlobalContext);

    return (
        <div
            className="col-md-12 pt-4" style={{}}>

            {items && items.length > 0 ?
                <MaterialTable
                    className="col-md-12"
                    title="Liste des Messages"
                    columns={columns}
                    data={formattedItems ? formattedItems : []}
                    icon='unfold_less'
                    localization={Localization('fr')}
                    options={{
                        headerStyle: {
                            backgroundColor: '#ff7f26',
                            color: '#FFF',
                            fontFamily: 'Ki',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            fontVariant: 'normal',
                            lineHeight: 1,
                            fontSize: '15px'
                        },
                        actionsColumnIndex: -1,
                        exportButton: true,
                        exportAllData: true,
                        pageSize: formattedItems?.length,
                        pageSizeOptions: [10, 20, 25, 50, 100],

                    }}
                    actions={[{
                        icon: row => (
                            <span className="material-icons">visibility</span>
                        ),
                        tooltip: 'view',
                        onClick: (e, rowData) => {
                            handleSetModal(rowData)
                        }
                    },
                    ]
                    }

                /> :
                <SkeletonTheme color={'rgba(255,127,38,0.68)'} highlightColor="#444">
                    <p>
                        <Skeleton count={3}/>
                    </p>
                </SkeletonTheme>}
        </div>
    );
})

export default React.memo(withRouter(HelpList))

