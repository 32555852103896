import React from "react"
import {Field} from "redux-form";
import {FormattedMessage, injectIntl} from 'react-intl';
import {
    renderCheckbox,
    renderField,
    renderFreeField,
    renderRadio,
    renderSelect
} from "../../../../../common/utils/FormComponent";
import {withRouter} from "react-router";
import {getIntlMsg} from "../../../../../../constants/utils";
import {countries} from "../../../../../../constants/options/countries";
import {useSelector} from "react-redux";
import {nameAcronym} from "../../../../../../constants/utils";
import {validate} from "./validator";

let renderEmail = injectIntl(props => {
    const {intl, fields, meta: {error}, isAdd} = props
    const validate = {}
    
    if (!fields.length) fields.push();
    return (
        <div className="row">
            <div className="col-md-12 offset-5 pb-10">
                    <span className="btn btn-sm btn-outline-primary" onClick={() => fields.push()}>
                        <FormattedMessage id="app.label.add_email"/>
                    </span>
            </div>
            <div className="col-sm-12">
                {fields.map((ad, index) => (
                    <div className={'row'} key={index}>
                        <div className="col-sm-1">
                            <h1 className="mt-5 text-center">{index + 1}</h1>
                        </div>
                        <div className="col-sm-9 ml-5">
                            <div className="form-group">
                                <Field className="form-control form-control-sm" id={`${ad}email`}
                                       name={`${ad}email`} component={renderField} type="text"
                                       label="email" placeholder="email"
                                       validate={validate.error}/>

                            </div>

                        </div>

                        <div className="col-sm-1">
                            <button type="button" title={getIntlMsg(intl, 'app.label.remove_trip')}
                                    className="btn btn-icon btn-light-primary btn-circle mr-2 mt-5"
                                    onClick={() => fields.remove(index)}>
                                <i className="flaticon2-trash"/>
                            </button>
                        </div>
                    </div>
                ))}
                {error && <li className="error">{error}</li>}
            </div>
        </div>
    );
})
export default React.memo(withRouter(renderEmail))
