import * as validator from "../../../../../../constants/utils/validator";


export const validate = values => {
    const errors = {};
    // errors.symptomsStartDate=values.symptomsStartDate === undefined;
    errors.symptomsStartDate = validator.validateString('symptomsStartDate', values.symptomsStartDate, 3, 150, true);
    errors.frname = validator.validateString('frname', values.frname, 3, 200, true);
    errors.page = validator.validateString('page', values.page, 3, 150, true);
    errors.frdescription = validator.validateString('frdescription', values.frdescription, 10, 1000, true);
    return errors
};


