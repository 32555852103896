import * as React from "react";
import {Link} from "react-router-dom";
import {getIntlMsg} from "../../../../constants/utils";
import {injectIntl} from "react-intl";
import {useParams} from "react-router";
import {useState} from "react";


const PrimaryMenuItem = injectIntl((props) => {
    const {intl, url = '#', title, icon, active, target, hasTarget, history, setDisplay} = props

    const [ph, setPh] = useState('');

    const path = history?.location?.pathname?.split('/')[2];

    return (
        <li className="nav-item mb-3" data-toggle="tooltip" data-placement="right" data-container="body"
            data-boundary="window" title={getIntlMsg(intl, `app.label.${title}`)}>

            {hasTarget ?
                <Link to={url}
                      className={`nav-link btn btn-icon btn-icon-white btn-hover-transparent-white ${title === path ? 'active' : ''}`}
                      data-toggle="tab" data-target={target} role="tab">
				<span className="svg-icon svg-icon-xl" onClick={() => setPh(title)}>
                    {icon}
				</span>
                </Link> :
                <Link to={url} className={`nav-link btn btn-icon btn-icon-white btn-hover-transparent-white`}>
                    <span className="svg-icon svg-icon-xl" onClick={() => setDisplay(title)}> {icon}</span>
                </Link>

            }


        </li>);
})

export default React.memo(PrimaryMenuItem)

