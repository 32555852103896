import React, {useContext, useEffect} from "react"
import {Field, reduxForm} from "redux-form";
import {FormattedMessage, injectIntl} from 'react-intl';
import {
    renderCheckbox,
    renderField,
    renderFreeField,
    renderRadio,
    renderSelect
} from "../../../../../common/utils/FormComponent";
import {withRouter} from "react-router";
import {getIntlMsg} from "../../../../../../constants/utils";
import {FileInput} from "../../../../../common/utils/FileInput";
import {connect, useDispatch, useSelector} from "react-redux";
import actions from "../../../../../../thunks/settings/categoryThunks";
import {GlobalContext} from "../../../../../index";
import {Company, CompanyLogo, CompanyLogoMapper, CompanyMapper} from "../../../../../../constants/models/company";
import {validate} from "../validator";
import FormFooter from "../../../../common/form/FormFooter";


let LogoForm = injectIntl(props => {
    const {
        intl, applicant, payment, specimenCenter, state, symptoms, destination, departure, id,
        handleSubmit, pristine, valid, submitting, reset, initialValues, history, touched, error
    } = props
    const validate = {}

    const {hideModal} = useContext(GlobalContext)

    const dispatch = useDispatch();
    const options = {options: ['categories','entity-type', 'LOGO']};

    useEffect(() => {
        dispatch(actions.loadItems(options));
    }, [dispatch]);

    const categories = useSelector(state => state.categoryReducer.items);
    const formattedCats = [];
    categories.forEach(category => {
        formattedCats.push({code: category?.id, name: category?.name?.frContent})
    })


    const {item, loading, logoItem,status} = useSelector(state => ({
        loading: state.companyReducer.loadingItem,
        item: state.companyReducer.item,
        logoItem: state.companyReducer.logoItem,
        status: state.companyReducer.status,
    }));


    const onFormSubmit = element => {
        const entity = new CompanyLogo(element)
        entity.id = item?.id ? item.id : null
        entity.itemId = logoItem?.id ? logoItem.id : null

        dispatch(actions.saveOrUpdateItem({
            options: ['company', 'logo'], intl, action: item && item.id ? '' : 'save', item: entity,
        }))
    };

    const resetFormLogo=()=>{
        hideModal('logo')
        reset('logoForm')
        dispatch(actions.resetItem({options: ['logo']}))
    }


    if(status==="updateLogoSucceeded")resetFormLogo()
    return (<form onSubmit={handleSubmit(onFormSubmit)}>
            <div className="row">
                <div className="col-sm-12">
                    <div className={'row'}>
                        <div className="col-sm-3">
                            <Field component={FileInput} name={`fileLogo`} id={`logoId`} type="file"/>
                        </div>
                        <div className="col-sm-6 ml-5">
                            <div className="form-group">

                                <label htmlFor="test_type" className="font-weight-bold">
                                    <FormattedMessage id="app.label.logo_category"/> :
                                </label>

                                <Field name={`type`} component="select" className="form-control" required>
                                    <option value={''}></option>
                                    {formattedCats && formattedCats.length > 0 ? formattedCats.map((item, i) =>
                                        <option value={item?.code} key={i}>
                                            {item && item?.name.toUpperCase()}
                                        </option>
                                    ) : null}
                                </Field>
                                {touched && error &&
                                <span className="form-text text-primary">
                                <FormattedMessage id={validate.error}/>
                        </span>}

                            </div>

                        </div>

                    </div>
                    {error && <li className="error">{error}</li>}

                    <FormFooter {...{
                        item,
                        loading,
                        pristine,
                        submitting,
                        reset,
                        valid,
                        dispatch,
                        history,
                        module
                    }}/>
                </div>
            </div>
        </form>
    );
})
const mapStateToProps = ({companyReducer: {logoItem}}) => {
    const defaultState = {
        lang: 'fr'
    }
    const initVal = logoItem ? new CompanyLogoMapper(logoItem) : null
    return {
        initialValues: logoItem !== null ? {...initVal, lang: 'fr'} : {...defaultState}
    }
};

export default React.memo(withRouter(connect(mapStateToProps,)(reduxForm({
    form: "logoForm",
    enableReinitialize: true,
    validate,
})(LogoForm))));
