export class ResearchModel {

    constructor(params) {
        if (params) {

            const {
                frdescription,
                htdescription,
                endescription,
                enname,
                frname,
                htname,
                idname,
                iddesc

            } = params;


            this.description = {
                enContent: endescription,
                frContent: frdescription,
                htContent: htdescription,
                id: iddesc ? iddesc : null
            }
            this.name = {
                enContent: enname,
                frContent: frname,
                htContent: htname,
                id: idname ? idname : null
            }
        }
    }
}


export class ResearchMapper {

    constructor(params) {
        if (params) {

            const {
                name,
                description,
            } = params;

            this.endescription = description?.enContent ? description?.enContent : null
            this.frdescription = description?.frContent ? description?.frContent : null
            this.htdescription = description?.htContent ? description?.htContent : null

            this.enname = name?.enContent
            this.frname = name?.frContent
            this.htname = name?.htContent
        }
    }
}

