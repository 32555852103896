import * as React from "react";

export function getPrimaryMenu(institution) {
    return [
        {
            "title": "dashboard",
            "url": `#`,
            "active": true,
            "target": '#dashboard',
            "hasTarget": true,
            "icon": <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <rect x="0" y="0" width="24" height="24"/>
                    <path
                        d="M4.00246329,12.2004927 L13,14 L13,4.06189375 C16.9463116,4.55399184 20,7.92038235 20,12 C20,16.418278 16.418278,20 12,20 C7.64874861,20 4.10886412,16.5261253 4.00246329,12.2004927 Z"
                        fill="#000000" opacity="0.3"/>
                    <path
                        d="M3.0603968,10.0120794 C3.54712466,6.05992157 6.91622084,3 11,3 L11,11.6 L3.0603968,10.0120794 Z"
                        fill="#000000"/>
                </g>
            </svg>,
        },
        {
            "title": "admin",
            "url": `#admin_management`,
            "active": true,
            "target": '#admin_management',
            "hasTarget": true,
            "icon": <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <rect x="0" y="0" width="24" height="24"/>
                    <rect fill="#000000" x="4" y="4" width="7" height="7" rx="1.5"/>
                    <path
                        d="M5.5,13 L9.5,13 C10.3284271,13 11,13.6715729 11,14.5 L11,18.5 C11,19.3284271 10.3284271,20 9.5,20 L5.5,20 C4.67157288,20 4,19.3284271 4,18.5 L4,14.5 C4,13.6715729 4.67157288,13 5.5,13 Z M14.5,4 L18.5,4 C19.3284271,4 20,4.67157288 20,5.5 L20,9.5 C20,10.3284271 19.3284271,11 18.5,11 L14.5,11 C13.6715729,11 13,10.3284271 13,9.5 L13,5.5 C13,4.67157288 13.6715729,4 14.5,4 Z M14.5,13 L18.5,13 C19.3284271,13 20,13.6715729 20,14.5 L20,18.5 C20,19.3284271 19.3284271,20 18.5,20 L14.5,20 C13.6715729,20 13,19.3284271 13,18.5 L13,14.5 C13,13.6715729 13.6715729,13 14.5,13 Z"
                        fill="#000000" opacity="0.3"/>
                </g>
            </svg>,
        },
        {
            "title": "components",
            "url": `#`,
            "active": false,
            "target": '#components',
            "hasTarget": true,
            "icon": <span className="material-icons">wysiwyg</span>,
        },
        // {
        //     "title": "reports",
        //     "url": `#`,
        //     "hasTarget": true,
        //     "active": false,
        //     "target": '#reports',
        //     "icon": <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
        //         <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        //             <rect x="0" y="0" width="24" height="24"/>
        //             <path
        //                 d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z"
        //                 fill="#000000" opacity="0.3"/>
        //             <path
        //                 d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z"
        //                 fill="#000000"/>
        //             <rect fill="#000000" opacity="0.3" x="10" y="9" width="7" height="2" rx="1"/>
        //             <rect fill="#000000" opacity="0.3" x="7" y="9" width="2" height="2" rx="1"/>
        //             <rect fill="#000000" opacity="0.3" x="7" y="13" width="2" height="2" rx="1"/>
        //             <rect fill="#000000" opacity="0.3" x="10" y="13" width="7" height="2" rx="1"/>
        //             <rect fill="#000000" opacity="0.3" x="7" y="17" width="2" height="2" rx="1"/>
        //             <rect fill="#000000" opacity="0.3" x="10" y="17" width="7" height="2" rx="1"/>
        //         </g>
        //     </svg>,
        // },
        // {
        //     "title": "sharing",
        //     "url": `/${institution}/sharing`,
        //     "hasTarget": true,
        //     "active": false,
        //     "target": '#sharing',
        //     "icon": <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
        //         <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        //             <rect x="0" y="0" width="24" height="24"/>
        //             <path
        //                 d="M5,3 L6,3 C6.55228475,3 7,3.44771525 7,4 L7,20 C7,20.5522847 6.55228475,21 6,21 L5,21 C4.44771525,21 4,20.5522847 4,20 L4,4 C4,3.44771525 4.44771525,3 5,3 Z M10,3 L11,3 C11.5522847,3 12,3.44771525 12,4 L12,20 C12,20.5522847 11.5522847,21 11,21 L10,21 C9.44771525,21 9,20.5522847 9,20 L9,4 C9,3.44771525 9.44771525,3 10,3 Z"
        //                 fill="#000000"/>
        //             <rect fill="#000000" opacity="0.3"
        //                   transform="translate(17.825568, 11.945519) rotate(-19.000000) translate(-17.825568, -11.945519)"
        //                   x="16.3255682" y="2.94551858" width="3" height="18" rx="1"/>
        //         </g>
        //     </svg>,
        // },
        {
            "title": "settings",
            "url": `/${institution}/settings`,
            "hasTarget": false,
            "active": false,
            "target": '#settings',
            "icon": <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <rect x="0" y="0" width="24" height="24"/>
                    <path
                        d="M5,8.6862915 L5,5 L8.6862915,5 L11.5857864,2.10050506 L14.4852814,5 L19,5 L19,9.51471863 L21.4852814,12 L19,14.4852814 L19,19 L14.4852814,19 L11.5857864,21.8994949 L8.6862915,19 L5,19 L5,15.3137085 L1.6862915,12 L5,8.6862915 Z M12,15 C13.6568542,15 15,13.6568542 15,12 C15,10.3431458 13.6568542,9 12,9 C10.3431458,9 9,10.3431458 9,12 C9,13.6568542 10.3431458,15 12,15 Z"
                        fill="#000000"/>http://localhost:3001/zl/settings#
                </g>
            </svg>,
        },
        // {
        //     "title": "security",
        //     "url": `#`,
        //     "hasTarget": true,
        //     "active": false,
        //     "target": '#security',
        //     "icon": <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
        //         <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        //             <rect x="0" y="0" width="24" height="24"/>
        //             <path
        //                 d="M4,4 L11.6314229,2.5691082 C11.8750185,2.52343403 12.1249815,2.52343403 12.3685771,2.5691082 L20,4 L20,13.2830094 C20,16.2173861 18.4883464,18.9447835 16,20.5 L12.5299989,22.6687507 C12.2057287,22.8714196 11.7942713,22.8714196 11.4700011,22.6687507 L8,20.5 C5.51165358,18.9447835 4,16.2173861 4,13.2830094 L4,4 Z"
        //                 fill="#000000" opacity="0.3"/>
        //             <path
        //                 d="M14.5,11 C15.0522847,11 15.5,11.4477153 15.5,12 L15.5,15 C15.5,15.5522847 15.0522847,16 14.5,16 L9.5,16 C8.94771525,16 8.5,15.5522847 8.5,15 L8.5,12 C8.5,11.4477153 8.94771525,11 9.5,11 L9.5,10.5 C9.5,9.11928813 10.6192881,8 12,8 C13.3807119,8 14.5,9.11928813 14.5,10.5 L14.5,11 Z M12,9 C11.1715729,9 10.5,9.67157288 10.5,10.5 L10.5,11 L13.5,11 L13.5,10.5 C13.5,9.67157288 12.8284271,9 12,9 Z"
        //                 fill="#000000"/>
        //         </g>
        //     </svg>,
        // },


    ]


}