import * as React from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {useParams, withRouter} from "react-router";
import {connect, useDispatch, useSelector} from "react-redux";
import useEntityManager from "../../../../../common/hooks/useEntityManager";
import actions from "../../../../../../thunks/common/coreThunks";
import {Field, reduxForm, FieldArray} from "redux-form";
import {validate} from "./validator";
import renderSlides from "./slides";
import FormFooter from "../../../../common/form/FormFooter";
import {SlideModel, SliderMapper} from "../../../../../../constants/models/SliderModel";
import swal from "sweetalert";
import {SectorMapper} from "../../../../../../constants/models/adminModel";

const GalleryForm = injectIntl((props) => {

    const {
        intl,
        handleSubmit, pristine, valid, submitting, reset, history,
    } = props;

    const {page} = useParams();


    const dispatch = useDispatch();

    const params = {options: [page]};

    const {item, loading, status,sectorItem} = useSelector(state => ({
        loading: state.componentReducer.loadingItem,
        item: state.componentReducer.sliderItem,
        status: state.componentReducer.status,
        sectorItem: state.adminReducer?.sectorItem,
    }));


    const onFormSubmit = element => {
        const entity = new SlideModel(element)
        entity.itemId = item?.id ? item.id : null
        dispatch(actions.saveOrUpdateItem({
            options: ['sectors', 'gallery'], intl, action: sectorItem && sectorItem.id ? '' : 'save', item: entity,
        }))
    };


    return (

        <div className="card card-custom">
            <div className="card-body">
                <form onSubmit={handleSubmit(onFormSubmit)}>

                    <div className={'row'}>
                        <div className={'col-sm-12'}>

                            <FieldArray name="galleries" component={renderSlides}/>

                        </div>
                    </div>

                    <FormFooter {...{
                        item,
                        loading,
                        pristine,
                        submitting,
                        reset,
                        valid,
                        dispatch,
                        history,
                        module
                    }}/>

                </form>


            </div>
        </div>

    );
});

const mapStateToProps = ({adminReducer: {sectorItem}, form}) => {
        const defaultState = {
            lang: 'fr',
            frAbout: " ",
            enAbout: " ",
            htAbout: " ",
            frDesc: " ",
            enDesc: " ",
            htDesc: " ",
            category: null,
            sectorFile: null
        }
        const initVal = sectorItem ? new SectorMapper(sectorItem) : null
        console.log("-------___+++++++==-----", initVal)
        return {
            initialValues: sectorItem !== null ? {...initVal, lang: 'fr'} : {...defaultState}
        }
    }
;

export default React.memo(withRouter(connect(mapStateToProps,)(reduxForm(
    {
        form: "galleryForm",
        enableReinitialize: true,
        validate,
    })(GalleryForm))));

