import React, {useContext} from 'react'
import PageHeader from "../../../../common/report/PageHeader";
import {FormattedMessage} from "react-intl";
import moment from 'moment'
import PageFooter from "../../../../common/report/PageFooter";
import {GlobalContext} from '../../../../../index'


const styles = {
    wrapper: {
        width: "80mm",
        fontFamily: "Calibri",
    },

    rowThreeColumn: {
        display: 'block',

        columnSize: {
            width: '400px',
            margin: '5px',
            fontFamily: "Calibri",

        }
    },
    titleWeight: {fontWeight: 'normal', fontSize: '18px', color: 'black', fontFamily: "Calibri",},
    threeColumnTd: {width: "200px"},
    rowMargin: {marginTop: "2"},
    textLabel: {
        fontSize: '16px',
        fontWeight: 'normal',
        marginLeft: '5px',
        fontFamily: "Calibri",
        textOverflow: 'ellipsis'
    },
    textTitle: {fontSize: '16px', color: 'black'}

}


class Notice extends React.Component {


    render() {
        const {item, userSession} = this.props;
        return (

            <div style={styles.wrapper}>
                <PageHeader zlOnly item={item}/>



                <div style={{...styles.rowThreeColumn, marginTop: "40px"}}>

                    <div style={styles.rowThreeColumn.columnSize}>
                        <h5 style={styles.titleWeight}>
                            Client :
                            <span style={styles.textLabel}>
                                    {item?.applicant?.personalInfos?.lastName?.toUpperCase()} {' '}
                                {item?.applicant?.personalInfos?.firstName}
                                </span>
                        </h5>

                    </div>
                    <div style={styles.rowThreeColumn.columnSize}>
                        <h5 style={styles.titleWeight}>
                            <FormattedMessage id={'app.label.amount'}/> :
                            <span style={styles.textLabel}>{item?.payment?.amount} GDES</span>

                        </h5>
                    </div>
                    <div style={styles.rowThreeColumn.columnSize}>
                        <h5 style={styles.titleWeight}>
                            Payé par :
                            <span style={styles.textLabel}>{item?.payment?.paymentType}</span>

                        </h5>
                    </div>


                    {/*</div>*/}

                    <div style={{...styles.rowThreeColumn.columnSize, paddingTop: '20px', fontSize: '16px'}}>
                        <h5 style={styles.titleWeight}>
                            <span style={{...styles.textLabel, fontSize: '22px'}}>{item?.payment?.trxCode}</span>

                        </h5>
                    </div>

                    <div style={{...styles.rowThreeColumn.columnSize, paddingTop: '4px'}}>
                        <h5 style={styles.titleWeight}>
                            Caissier (ère):
                            <span style={styles.textLabel}>
                                    {userSession?.name}
                            </span>
                        </h5>

                    </div>

                </div>
            </div>
        )
    }
}

export default Notice;

