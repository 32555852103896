import * as React from "react";
import ModuleMenu from "./ModuleMenu";
import {FormattedMessage} from "react-intl";
import {Link} from "react-router-dom";
import {useContext} from "react";
import {GlobalContext} from "../../../../index";
import {canDisplay} from "../../../../../constants/utils/security";


const ModuleItem = props => {
    const {module, models} = props
    const {userSession: {roles, name, keycloak}, institution} = useContext(GlobalContext);


    return (
        <React.Fragment>
            <li className="menu-section">
                <h4 className="menu-text">
                    <FormattedMessage id={`app.label.${module}`}/>
                </h4>
                <i className="menu-icon ki ki-bold-more-hor icon-md"/>
            </li>

            {models.map((item, i) =>
                canDisplay(roles, item.title) &&
                <li className="menu-item menu-item-submenu" aria-haspopup="true"
                    data-menu-toggle="hover" key={i}>
                    <Link to={item.url} className="menu-link menu-toggle">
                        <span className="menu-icon material-icons">{item.icon}</span>
                        {/*<span className="svg-icon menu-icon">*/}
                        {/*   <i className={item.icon}/>*/}
                        {/*</span>*/}
                        <span className="menu-text">
                            <FormattedMessage id={`app.label.${item.title}`}/>
                        </span>
                        {item.actions.length > 0 && <i className="menu-arrow"/>}
                    </Link>

                    {/* Module menu */}
                    {item.actions.map((action, j) => <ModuleMenu {...action} key={j}/>)}

                </li>
            )}

        </React.Fragment>
    )
}

export default React.memo(ModuleItem)

