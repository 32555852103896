import * as React from "react";

export function getModuleItems(institution) {
    return [
        {
            module: "admin_management",
            models: [
                {
                    title: "news",
                    url: `/${institution}/admin/news`,
                    hasChildren: false,
                    icon: `article`,
                    actions: [],
                },
                {
                    title: "sectors",
                    url: `/${institution}/admin/sectors`,
                    hasChildren: false,
                    icon: `bookmarks`,
                    actions: [],
                },
                {
                    title: "testimonials",
                    url: `/${institution}/admin/testimonials`,
                    hasChildren: false,
                    icon: `record_voice_over`,
                    actions: [],
                },
                {
                    title: "members",
                    url: `/${institution}/admin/members`,
                    hasChildren: false,
                    icon: `people`,
                    actions: [],
                },
                // {
                //     title: "partners",
                //     url: `/${institution}/admin/partners`,
                //     hasChildren: false,
                //     icon: `pan_tool`,
                //     actions: [],
                // },
                {
                    title: "services",
                    url: `/${institution}/admin/services`,
                    hasChildren: false,
                    icon: `design_services`,
                    actions: [],
                },
                {
                    title: "health-centers",
                    url: `/${institution}/admin/health-centers`,
                    hasChildren: false,
                    icon: `location_city`,
                    actions: [],
                },
                {
                    title: "messages",
                    url: `/${institution}/admin/messages`,
                    hasChildren: false,
                    icon: `mail`,
                    actions: [],
                },
                {
                    title: "research",
                    url: `/${institution}/admin/research`,
                    hasChildren: false,
                    icon: `science`,
                    actions: [],
                },
                {
                    title: "staffing",
                    url: `/${institution}/admin/staffing`,
                    hasChildren: false,
                    icon: `group`,
                    actions: [],
                },
                {
                    title: "donation",
                    url: `/${institution}/admin/donation`,
                    hasChildren: false,
                    icon: `volunteer_activism`,
                    actions: [],
                },
                {
                    title: "donate",
                    url: `/${institution}/admin/donate`,
                    hasChildren: false,
                    icon: `volunteer_activism`,
                    actions: [],
                },
            ],
        },
    ];
}

export function getComponentItems(institution) {
    return [
        {
            module: "components",
            models: [
                {
                    title: "sliders",
                    url: `/${institution}/components/sliders`,
                    hasChildren: false,
                    icon: `slideshow`,
                    actions: [],
                },
                {
                    title: "statistics",
                    url: `/${institution}/components/statistics`,
                    hasChildren: false,
                    icon: `analytics`,
                    actions: [],
                },
                {
                    title: "institution",
                    url: `/${institution}/components/institution`,
                    hasChildren: false,
                    icon: `house`,
                    actions: [],
                },
            ],
        },
    ];
}

export function getSecurityItems(institution) {
    return [
        {
            module: "security_management",
            models: [
                {
                    title: "all_users",
                    url: `/${institution}/security/users`,
                    hasChildren: false,
                    icon: (
                        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                <polygon points="0 0 24 0 24 24 0 24"/>
                                <path
                                    d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z"
                                    fill="#000000"
                                    fillRule="nonzero"
                                    opacity="0.3"
                                />
                                <path
                                    d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z"
                                    fill="#000000"
                                    fillRule="nonzero"
                                />
                            </g>
                        </svg>
                    ),
                    actions: [],
                },
                {
                    title: "all_roles",
                    url: `/${institution}/security/roles`,
                    hasChildren: false,
                    icon: (
                        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                <polygon points="0 0 24 0 24 24 0 24"/>
                                <path
                                    d="M22,15 L22,19 C22,20.1045695 21.1045695,21 20,21 L8,21 C5.790861,21 4,19.209139 4,17 C4,14.790861 5.790861,13 8,13 L20,13 C21.1045695,13 22,13.8954305 22,15 Z M7,19 C8.1045695,19 9,18.1045695 9,17 C9,15.8954305 8.1045695,15 7,15 C5.8954305,15 5,15.8954305 5,17 C5,18.1045695 5.8954305,19 7,19 Z"
                                    fill="#000000"
                                    opacity="0.3"
                                />
                                <path
                                    d="M15.5421357,5.69999981 L18.3705628,8.52842693 C19.1516114,9.30947552 19.1516114,10.5758055 18.3705628,11.3568541 L9.88528147,19.8421354 C8.3231843,21.4042326 5.79052439,21.4042326 4.22842722,19.8421354 C2.66633005,18.2800383 2.66633005,15.7473784 4.22842722,14.1852812 L12.7137086,5.69999981 C13.4947572,4.91895123 14.7610871,4.91895123 15.5421357,5.69999981 Z M7,19 C8.1045695,19 9,18.1045695 9,17 C9,15.8954305 8.1045695,15 7,15 C5.8954305,15 5,15.8954305 5,17 C5,18.1045695 5.8954305,19 7,19 Z"
                                    fill="#000000"
                                    opacity="0.3"
                                />
                                <path
                                    d="M5,3 L9,3 C10.1045695,3 11,3.8954305 11,5 L11,17 C11,19.209139 9.209139,21 7,21 C4.790861,21 3,19.209139 3,17 L3,5 C3,3.8954305 3.8954305,3 5,3 Z M7,19 C8.1045695,19 9,18.1045695 9,17 C9,15.8954305 8.1045695,15 7,15 C5.8954305,15 5,15.8954305 5,17 C5,18.1045695 5.8954305,19 7,19 Z"
                                    fill="#000000"
                                />
                            </g>
                        </svg>
                    ),
                    actions: [],
                },
                {
                    title: "all_institutions",
                    url: `/${institution}/security/institutions`,
                    hasChildren: false,
                    icon: (
                        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                <rect x="0" y="0" width="24" height="24"/>
                                <path
                                    d="M13.5,21 L13.5,18 C13.5,17.4477153 13.0522847,17 12.5,17 L11.5,17 C10.9477153,17 10.5,17.4477153 10.5,18 L10.5,21 L5,21 L5,4 C5,2.8954305 5.8954305,2 7,2 L17,2 C18.1045695,2 19,2.8954305 19,4 L19,21 L13.5,21 Z M9,4 C8.44771525,4 8,4.44771525 8,5 L8,6 C8,6.55228475 8.44771525,7 9,7 L10,7 C10.5522847,7 11,6.55228475 11,6 L11,5 C11,4.44771525 10.5522847,4 10,4 L9,4 Z M14,4 C13.4477153,4 13,4.44771525 13,5 L13,6 C13,6.55228475 13.4477153,7 14,7 L15,7 C15.5522847,7 16,6.55228475 16,6 L16,5 C16,4.44771525 15.5522847,4 15,4 L14,4 Z M9,8 C8.44771525,8 8,8.44771525 8,9 L8,10 C8,10.5522847 8.44771525,11 9,11 L10,11 C10.5522847,11 11,10.5522847 11,10 L11,9 C11,8.44771525 10.5522847,8 10,8 L9,8 Z M9,12 C8.44771525,12 8,12.4477153 8,13 L8,14 C8,14.5522847 8.44771525,15 9,15 L10,15 C10.5522847,15 11,14.5522847 11,14 L11,13 C11,12.4477153 10.5522847,12 10,12 L9,12 Z M14,12 C13.4477153,12 13,12.4477153 13,13 L13,14 C13,14.5522847 13.4477153,15 14,15 L15,15 C15.5522847,15 16,14.5522847 16,14 L16,13 C16,12.4477153 15.5522847,12 15,12 L14,12 Z"
                                    fill="#000000"
                                />
                                <rect fill="#FFFFFF" x="13" y="8" width="3" height="3" rx="1"/>
                                <path
                                    d="M4,21 L20,21 C20.5522847,21 21,21.4477153 21,22 L21,22.4 C21,22.7313708 20.7313708,23 20.4,23 L3.6,23 C3.26862915,23 3,22.7313708 3,22.4 L3,22 C3,21.4477153 3.44771525,21 4,21 Z"
                                    fill="#000000"
                                    opacity="0.3"
                                />
                            </g>
                        </svg>
                    ),
                    actions: [],
                },
            ],
        },
    ];
}


export function getSharingItems(institution) {
    return [
        {
            module: "sharing_management",
            models: [
                {
                    title: "partners",
                    url: `/${institution}/sharing/partners`,
                    hasChildren: false,
                    icon: 'flaticon2-graphic-design',
                    actions: [],
                }
            ],
        },
    ];
}


export function getReportsItems(institution) {
    return [
        {
            module: "reports_management",
            models: [
                {
                    title: "tests_performed",
                    url: `/${institution}/requests/reports/applicants`,
                    hasChildren: false,
                    icon: 'flaticon2-drop',
                    actions: [
                        {title: `test_applicant_list`, url: ``}
                    ],
                },
                {
                    title: "cashier_repport",
                    url: `/${institution}/requests/reports/cart-report`,
                    hasChildren: false,
                    icon: 'flaticon2-drop',
                    actions: [
                        {title: `test_applicant_list`, url: ``}
                    ],
                },

            ],

        },
    ];
}


export function getDashboardItems(institution) {
    return [
        {
            module: "dashboards",
            models: [
                {
                    title: "global_dashboard",
                    url: `/${institution}/dashboard`,
                    hasChildren: false,
                    icon: "dashboard",
                    actions: [],
                },
            ]
        },
    ];
}
