import * as validator from "../../../../../../constants/utils/validator";


export const validate = values => {
    const errors = {};
    errors.sectorFile=values.sectorFile === null;
    errors.category=values.category === null;
    errors.frName = validator.validateString('frName', values.frName, 3, 150, true);
    // errors.frAbout = validator.validateString('frAbout', values.frAbout, 3, 150, true);
    // errors.frDesc = validator.validateString('frDesc', values.frAbout, 3, 150, true);


    return errors
};


