const actionTypes = {


    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * LOAD GLOBAL_DASHBOARD ACTION TYPES
     * -------------------------------------------
     * */
    LOAD_GLOBAL_DASHBOARD: "LOAD_GLOBAL_DASHBOARD",
    LOAD_GLOBAL_DASHBOARD_SUCCEEDED: "LOAD_GLOBAL_DASHBOARD_SUCCEEDED",
    LOAD_GLOBAL_DASHBOARD_FAILED: "LOAD_GLOBAL_DASHBOARD_FAILED",


    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * LOAD REQUESTS_DASHBOARD ACTION TYPES
     * -------------------------------------------
     * */
    LOAD_REQUESTS_DASHBOARD: "LOAD_REQUESTS_DASHBOARD",
    LOAD_REQUESTS_DASHBOARD_SUCCEEDED: "LOAD_REQUESTS_DASHBOARD_SUCCEEDED",
    LOAD_REQUESTS_DASHBOARD_FAILED: "LOAD_REQUESTS_DASHBOARD_FAILED",

    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * LOAD SECURITY_DASHBOARD ACTION TYPES
     * -------------------------------------------
     * */
    LOAD_SECURITY_DASHBOARD: "LOAD_SECURITY_DASHBOARD",
    LOAD_SECURITY_DASHBOARD_SUCCEEDED: "LOAD_SECURITY_DASHBOARD_SUCCEEDED",
    LOAD_SECURITY_DASHBOARD_FAILED: "LOAD_SECURITY_DASHBOARD_FAILED",

    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * LOAD LEADERSHIP_DASHBOARD ACTION TYPES
     * -------------------------------------------
     * */
    LOAD_LEADERSHIP_DASHBOARD: "LOAD_LEADERSHIP_DASHBOARD",
    LOAD_LEADERSHIP_DASHBOARD_SUCCEEDED: "LOAD_LEADERSHIP_DASHBOARD_SUCCEEDED",
    LOAD_LEADERSHIP_DASHBOARD_FAILED: "LOAD_LEADERSHIP_DASHBOARD_FAILED",


    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * LOAD FINANCIAL ACTION TYPES
     * -------------------------------------------
     * */
    LOAD_FINANCIAL_DASHBOARD: "LOAD_FINANCIAL_DASHBOARD",
    LOAD_FINANCIAL_DASHBOARD_SUCCEEDED: "LOAD_FINANCIAL_DASHBOARD_SUCCEEDED",
    LOAD_FINANCIAL_DASHBOARD_FAILED: "LOAD_FINANCIAL_DASHBOARD_FAILED",

    LOAD_INDICATOR_DASHBOARD:"LOAD_INDICATOR_DASHBOARD",
    LOAD_INDICATOR_DASHBOARD_SUCCEEDED:"LOAD_INDICATOR_DASHBOARD_SUCCEEDED",
    LOAD_INDICATOR_DASHBOARD_FAILED:"LOAD_INDICATOR_DASHBOARD_FAILED",
    LOAD_COMMON_COUNT_ENTRIES_SUCCEEDED:"LOAD_COMMON_COUNT_ENTRIES_SUCCEEDED",


};


export default actionTypes;
