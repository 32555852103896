import React from "react"
import {withRouter} from "react-router";
import {FormattedMessage, injectIntl} from "react-intl";
import ListOptions from "../../../../../common/utils/ListOptions";

let Item = injectIntl(props => {

    const {id, name, description, entityName, status, entityType, setFormView} = props

    return (
        <tr data-row="0" className="datatable-row" style={{left: "0px"}}>

            <td className="datatable-cell">
                <span style={{width: "137px"}}><b>FR: </b>{name?.frContent || ''}</span>
                <span style={{width: "137px"}}><b>EN: </b>{name?.enContent || ''}</span>
                <span style={{width: "137px"}}><b>HT: </b>{name?.htContent || ''}</span>
            </td>

            <td className="datatable-cell">
                <span style={{width: "300px"}}><b>FR: </b>{description?.frContent || ''}</span>
                <span style={{width: "300px"}}><b>EN: </b>{description?.enContent || ''}</span>
                <span style={{width: "300px"}}><b>HT: </b>{description?.htContent || ''}</span>
            </td>
            <td className="datatable-cell">
                <span style={{width: "137px"}}>{entityType || ''} </span>
            </td>

            <td className="datatable-cell">
                <span style={{width: "137px"}}>
                    <span
                        className={`label font-weight-bold label-lg  label-light-${status ? 'success' : 'danger'} label-inline`}>
                        {status
                            ? <FormattedMessage id="app.label.online"/>
                            : <FormattedMessage id="app.label.offline"/>
                        }
                    </span>
                </span>
            </td>

            <ListOptions {...{id, viewAction: setFormView, entities: 'categories'}}/>
        </tr>

    )
})
export default React.memo(withRouter(Item))
