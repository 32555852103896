import * as React from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {useParams, withRouter} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {useContext, useEffect} from "react";
import params from "../../../../../../constants/utils/params";
import {GlobalContext} from "../../../../../index";
import MaterialTable from 'material-table';
import {getIntlMsg, Localization} from "../../../../../../constants/utils";
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";
import actions from "../../../../../../thunks/common/coreThunks";
import moment from 'moment'
import Slides from "../form/formComponent";
import swal from "sweetalert";
import {canDisplay} from "../../../../../../constants/utils/security";

const HelpList = injectIntl((props) => {

        const dispatch = useDispatch();
        const {intl, EditRecruitment, showDocument, showApplications} = props;

        const {items} = useSelector(state => ({
            loading: state.companyReducer.loadingItems,
            items: state.adminReducer?.recruitments || [],
        }));


        const formattedItems = [];
        items.forEach(item => {
            formattedItems.push({
                name: item?.name?.frContent,
                category: item?.category?.name?.frContent,
                dateStarted: moment(item?.dateStarted).format('DD-MM-YYYY'),
                dateEnded: moment(item?.dateEnded).format('DD-MM-YYYY'),
                attachment: item?.documents?.length,
                created: moment(item?.created).format('DD-MM-YYYY'),
                id: item?.id,
                status: item?.status,
                documents: item?.documents,
                postulantCount: item?.postulantCount,
            })
        })

        const columns = [
            {
                title: "Nom",
                type: 'text',
                render: rowData => (<div className={'overflow-ellipsis'}>
                    <p
                        dangerouslySetInnerHTML={{
                            __html: rowData?.name
                        }}>
                    </p>
                </div>),
            },
            {
                title: "Categorie",
                type: 'text',
                render: rowData => (<div className={'overflow-ellipsis'}>
                    <p
                        dangerouslySetInnerHTML={{
                            __html: rowData?.category
                        }}>
                    </p>
                </div>)
            },
            {
                title: "Date debut",
                type: 'text',
                field: 'dateStarted'
            },
            {
                title: "Date fin",
                type: 'text',
                field: 'dateEnded'
            },
            {
                title: "Attachement",
                field: 'attachment',
                render: rowData => (<>
                    <span className="text-danger">{rowData.attachment}</span>
                    <span className="material-icons"
                          onClick={() => showDocument(rowData.documents)}
                          style={{
                              fontSize: '20px',
                              cursor: 'pointer'
                          }}>source</span></>)
            },
            {
                title: "Status",
                render: rowData => rowData.status ? <span className="text-success">publié</span> :
                    <span className="text-danger">Non publié</span>
            },
            {
                title: "Date de creation",
                field: 'created'
            },
            {
                title: "Postulants",
                field: 'applicant',
                render: rowData => (<>
                    <span className="text-danger"> {rowData.postulantCount} </span>
                    <span className="material-icons"
                          onClick={() => showApplications(rowData)}
                          style={{
                              fontSize: '20px',
                              cursor: 'pointer'
                          }}>people</span></>)
            },
        ]

        const handlePublishUnPublish = (id, status) => {

            swal({
                title: "voulez-vous vraiment",
                text: status === "publish" ? "le Publier" : "ne le pas Publier",
                icon: "warning",
                buttons: ["Non", "Oui"],
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        dispatch(actions.saveOrUpdateItem({
                            options: ['common', 'recruitment'],
                            intl,
                            action: '',
                            item: {id: id},
                        }))
                    } else {
                    }
                });
        }
        const {userSession: {roles}} = useContext(GlobalContext);

        return (
            <div
                className="col-md-12 pt-4" style={{}}>

                {items && items.length > 0 ?
                    <MaterialTable
                        className="col-md-12"
                        title="Liste des Recrutements"
                        columns={columns}
                        data={formattedItems ? formattedItems : []}
                        icon='unfold_less'
                        localization={Localization('fr')}
                        options={{
                            headerStyle: {
                                backgroundColor: '#ff7f26',
                                color: '#FFF',
                                fontFamily: 'Ki',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                fontVariant: 'normal',
                                lineHeight: 1,
                                fontSize: '15px'
                            },
                            actionsColumnIndex: -1,
                            exportButton: true,
                            exportAllData: true,

                        }}
                        actions={[{
                            icon: row => (
                                <span className="material-icons">edit</span>
                            ),
                            tooltip: 'Edit',
                            onClick: (e, rowData) => {
                                EditRecruitment(rowData.id)
                            }
                        },
                            {
                                icon: row => (
                                    canDisplay(roles, 'publish') && <a href="#"
                                                                       className="btn btn-light-primary font-weight-bolder font-size-sm m-0 p-2 ml-3">
                                        <i className="material-icons font-size-sm p-0">publish</i>
                                    </a>
                                ),
                                tooltip: 'Publier',
                                onClick: (e, row) => {

                                    if (row.status) {
                                        handlePublishUnPublish(row.id, 'unpublish')
                                    } else {
                                        handlePublishUnPublish(row.id, 'publish')
                                    }
                                }
                            }
                        ]
                        }

                    /> :
                    <SkeletonTheme color={'rgba(255,127,38,0.68)'} highlightColor="#444">
                        <p>
                            <Skeleton count={3}/>
                        </p>
                    </SkeletonTheme>}
            </div>
        );
    }
)

export default React.memo(withRouter(HelpList))

