import * as React from "react";
import actions from "../../../../../../thunks/common/coreThunks";
import {nameAcronym} from "../../../../../../constants/utils";
import {FormattedMessage, injectIntl} from "react-intl";
import {useParams, withRouter} from "react-router";
import {Link} from "react-router-dom";
import InfoBar from "./infobar";
import {useDispatch, useSelector} from "react-redux";
import SpecBtn from "./buttons/SpecBtn";
import ResultBtn from "./buttons/ResultBtn";
import ReceptBtn from "./buttons/ReceptBtn";
import CartBtn from "./buttons/CartBtn";
import NotificationBtn from "./buttons/NotifyBtn";
import ArchivedBtn from "./buttons/ArchivedBtn";
import LabBtn from "./buttons/LabBtn";
import UnfinishedBtn from "./buttons/UnfinisedBtn";


const Item = injectIntl((props) => {

    const {module, page} = useParams();
    const {intl, item} = props;

    const {applicant, payment, id, codeLaboratory} = item
    const dispatch = useDispatch()

    const params={options: ['requests','status','results']}
    const param={options: ['results']}

    const handleStateChange = status => {
        const options = {options: [module, 'change'], intl, item, status}
        dispatch(actions.changeStatus(options))
    }

    const {status} = useSelector(state => ({
        status: state?.requestReducer?.status,
    }))

    const resetStateResult=(status)=>{
        if(status==='notify'){
            const pr={options: ['requests','status','notification']}
            const p={options: ['notification']}
            dispatch(actions.loadItems(pr))
            dispatch(actions.resetItem(p))
        }else {
            dispatch(actions.loadItems(params))
            dispatch(actions.resetItem(param))
        }

    }

    if(status==='result'|| status==='notify')resetStateResult(status)

    return (
        <div className="card card-custom gutter-b" style={{borderLeft: "5px solid #28a745"}}>
            <div className="card-body">

                <div className="d-flex">
                    <div className="flex-shrink-0 mr-7">
                        <div className="symbol symbol-50 symbol-lg-80 symbol-circle symbol-light-primary">
                            <span className="font-size-h3 symbol-label font-weight-boldest">
                                {nameAcronym(applicant?.personalInfos?.firstName || 'F', applicant?.personalInfos?.lastName || 'L')}
                            </span>
                        </div>
                    </div>

                    <div className="flex-grow-1">
                        <div className="d-flex align-items-center justify-content-between flex-wrap mt-2">
                            <div className="mr-3">
                                <a href="#"
                                   className="d-flex align-items-center text-dark text-hover-primary font-size-h5 font-weight-bold mr-3">
                                    {`${applicant?.personalInfos?.firstName || 'Firstname'} ${applicant?.personalInfos?.lastName?.toUpperCase() || 'Lastname'}`}
                                    <span className="border-info ml-2">
                                        {` | `}
                                        <FormattedMessage
                                            id={`app.label.${applicant?.personalInfos?.gender?.toLowerCase()||'MALE'}`}/>
                                        {` | `}
                                        {codeLaboratory?.toUpperCase()}
                                    </span>
                                    {payment?.fileName &&
                                    <Link to={`#`} className="ml-5">
                                        <span className="flaticon2-image-file" style={{fontSize: "20px"}}/>
                                    </Link>
                                    }
                                </a>


                                <div className="">
                                    <div className="d-flex flex-wrap my-2">
                                        {applicant?.socialInfos?.emails[0] &&
                                        <a href="#"
                                           className="text-black-50 text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2">
                                        <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                                            <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                    <rect x="0" y="0" width="24" height="24"/>
                                                    <path
                                                        d="M21,12.0829584 C20.6747915,12.0283988 20.3407122,12 20,12 C16.6862915,12 14,14.6862915 14,18 C14,18.3407122 14.0283988,18.6747915 14.0829584,19 L5,19 C3.8954305,19 3,18.1045695 3,17 L3,8 C3,6.8954305 3.8954305,6 5,6 L19,6 C20.1045695,6 21,6.8954305 21,8 L21,12.0829584 Z M18.1444251,7.83964668 L12,11.1481833 L5.85557487,7.83964668 C5.4908718,7.6432681 5.03602525,7.77972206 4.83964668,8.14442513 C4.6432681,8.5091282 4.77972206,8.96397475 5.14442513,9.16035332 L11.6444251,12.6603533 C11.8664074,12.7798822 12.1335926,12.7798822 12.3555749,12.6603533 L18.8555749,9.16035332 C19.2202779,8.96397475 19.3567319,8.5091282 19.1603533,8.14442513 C18.9639747,7.77972206 18.5091282,7.6432681 18.1444251,7.83964668 Z"
                                                        fill="#000000"/>
                                                          <circle fill="#000000" opacity="0.3" cx="19.5" cy="17.5"
                                                                  r="2.5"/>
                                                </g>
                                            </svg>
                                        </span>
                                            {applicant?.socialInfos?.emails[0]?.current || ''}
                                        </a>
                                        }

                                        {applicant?.socialInfos?.identifier[0] &&
                                        <a href="#"
                                           className="text-black-50 text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2">
                                        <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                                           <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                    <rect x="0" y="0" width="24" height="24"/>
                                                    <path
                                                        d="M11.7573593,15.2426407 L8.75735931,15.2426407 C8.20507456,15.2426407 7.75735931,15.6903559 7.75735931,16.2426407 C7.75735931,16.7949254 8.20507456,17.2426407 8.75735931,17.2426407 L11.7573593,17.2426407 L11.7573593,18.2426407 C11.7573593,19.3472102 10.8619288,20.2426407 9.75735931,20.2426407 L5.75735931,20.2426407 C4.65278981,20.2426407 3.75735931,19.3472102 3.75735931,18.2426407 L3.75735931,14.2426407 C3.75735931,13.1380712 4.65278981,12.2426407 5.75735931,12.2426407 L9.75735931,12.2426407 C10.8619288,12.2426407 11.7573593,13.1380712 11.7573593,14.2426407 L11.7573593,15.2426407 Z"
                                                        fill="#000000" opacity="0.3"
                                                        transform="translate(7.757359, 16.242641) rotate(-45.000000) translate(-7.757359, -16.242641) "/>
                                                    <path
                                                        d="M12.2426407,8.75735931 L15.2426407,8.75735931 C15.7949254,8.75735931 16.2426407,8.30964406 16.2426407,7.75735931 C16.2426407,7.20507456 15.7949254,6.75735931 15.2426407,6.75735931 L12.2426407,6.75735931 L12.2426407,5.75735931 C12.2426407,4.65278981 13.1380712,3.75735931 14.2426407,3.75735931 L18.2426407,3.75735931 C19.3472102,3.75735931 20.2426407,4.65278981 20.2426407,5.75735931 L20.2426407,9.75735931 C20.2426407,10.8619288 19.3472102,11.7573593 18.2426407,11.7573593 L14.2426407,11.7573593 C13.1380712,11.7573593 12.2426407,10.8619288 12.2426407,9.75735931 L12.2426407,8.75735931 Z"
                                                        fill="#000000"
                                                        transform="translate(16.242641, 7.757359) rotate(-45.000000) translate(-16.242641, -7.757359) "/>
                                                    <path
                                                        d="M5.89339828,3.42893219 C6.44568303,3.42893219 6.89339828,3.87664744 6.89339828,4.42893219 L6.89339828,6.42893219 C6.89339828,6.98121694 6.44568303,7.42893219 5.89339828,7.42893219 C5.34111353,7.42893219 4.89339828,6.98121694 4.89339828,6.42893219 L4.89339828,4.42893219 C4.89339828,3.87664744 5.34111353,3.42893219 5.89339828,3.42893219 Z M11.4289322,5.13603897 C11.8194565,5.52656326 11.8194565,6.15972824 11.4289322,6.55025253 L10.0147186,7.96446609 C9.62419433,8.35499039 8.99102936,8.35499039 8.60050506,7.96446609 C8.20998077,7.5739418 8.20998077,6.94077682 8.60050506,6.55025253 L10.0147186,5.13603897 C10.4052429,4.74551468 11.0384079,4.74551468 11.4289322,5.13603897 Z M0.600505063,5.13603897 C0.991029355,4.74551468 1.62419433,4.74551468 2.01471863,5.13603897 L3.42893219,6.55025253 C3.81945648,6.94077682 3.81945648,7.5739418 3.42893219,7.96446609 C3.0384079,8.35499039 2.40524292,8.35499039 2.01471863,7.96446609 L0.600505063,6.55025253 C0.209980772,6.15972824 0.209980772,5.52656326 0.600505063,5.13603897 Z"
                                                        fill="#000000" opacity="0.3"
                                                        transform="translate(6.014719, 5.843146) rotate(-45.000000) translate(-6.014719, -5.843146) "/>
                                                    <path
                                                        d="M17.9142136,15.4497475 C18.4664983,15.4497475 18.9142136,15.8974627 18.9142136,16.4497475 L18.9142136,18.4497475 C18.9142136,19.0020322 18.4664983,19.4497475 17.9142136,19.4497475 C17.3619288,19.4497475 16.9142136,19.0020322 16.9142136,18.4497475 L16.9142136,16.4497475 C16.9142136,15.8974627 17.3619288,15.4497475 17.9142136,15.4497475 Z M23.4497475,17.1568542 C23.8402718,17.5473785 23.8402718,18.1805435 23.4497475,18.5710678 L22.0355339,19.9852814 C21.6450096,20.3758057 21.0118446,20.3758057 20.6213203,19.9852814 C20.2307961,19.5947571 20.2307961,18.9615921 20.6213203,18.5710678 L22.0355339,17.1568542 C22.4260582,16.76633 23.0592232,16.76633 23.4497475,17.1568542 Z M12.6213203,17.1568542 C13.0118446,16.76633 13.6450096,16.76633 14.0355339,17.1568542 L15.4497475,18.5710678 C15.8402718,18.9615921 15.8402718,19.5947571 15.4497475,19.9852814 C15.0592232,20.3758057 14.4260582,20.3758057 14.0355339,19.9852814 L12.6213203,18.5710678 C12.2307961,18.1805435 12.2307961,17.5473785 12.6213203,17.1568542 Z"
                                                        fill="#000000" opacity="0.3"
                                                        transform="translate(18.035534, 17.863961) scale(1, -1) rotate(45.000000) translate(-18.035534, -17.863961) "/>
                                                </g>
                                            </svg>
                                        </span>
                                            {`${applicant?.socialInfos?.identifier[0]?.type || ''}, ${applicant?.socialInfos?.identifier[0]?.value || ''}`}
                                        </a>
                                        }

                                        {applicant?.socialInfos?.phones[0] &&
                                        <a href="#"
                                           className="text-black-50 text-hover-primary font-weight-bold mr-lg-11 mr-11 mb-lg-0 mb-2">
                                        <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                                           <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                    <rect x="0" y="0" width="24" height="24"/>
                                                    <path
                                                        d="M9.8604543,6.01162174 C9.94073619,5.93133984 10.0459506,5.88077119 10.1587919,5.86823326 C10.4332453,5.83773844 10.6804547,6.03550595 10.7109496,6.30995936 L11.2341533,11.0187935 C11.2382309,11.0554911 11.2382309,11.0925274 11.2341533,11.129225 C11.2036585,11.4036784 10.9564491,11.6014459 10.6819957,11.5709511 L5.97316161,11.0477473 C5.86032028,11.0352094 5.75510588,10.9846407 5.67482399,10.9043588 C5.47956184,10.7090967 5.47956184,10.3925142 5.67482399,10.197252 L7.06053236,8.81154367 L5.55907018,7.31008149 C5.36380803,7.11481935 5.36380803,6.79823686 5.55907018,6.60297471 L6.26617696,5.89586793 C6.46143911,5.70060578 6.7780216,5.70060578 6.97328374,5.89586793 L8.47474592,7.39733011 L9.8604543,6.01162174 Z"
                                                        fill="#000000" fillRule="nonzero" opacity="0.3"/>
                                                    <path
                                                        d="M12.0799676,14.7839934 L14.2839934,12.5799676 C14.8927139,11.9712471 15.0436229,11.0413042 14.6586342,10.2713269 L14.5337539,10.0215663 C14.1487653,9.25158901 14.2996742,8.3216461 14.9083948,7.71292558 L17.6411989,4.98012149 C17.836461,4.78485934 18.1530435,4.78485934 18.3483056,4.98012149 C18.3863063,5.01812215 18.4179321,5.06200062 18.4419658,5.11006808 L19.5459415,7.31801948 C20.3904962,9.0071287 20.0594452,11.0471565 18.7240871,12.3825146 L12.7252616,18.3813401 C11.2717221,19.8348796 9.12170075,20.3424308 7.17157288,19.6923882 L4.75709327,18.8875616 C4.49512161,18.8002377 4.35354162,18.5170777 4.4408655,18.2551061 C4.46541191,18.1814669 4.50676633,18.114554 4.56165376,18.0596666 L7.21292558,15.4083948 C7.8216461,14.7996742 8.75158901,14.6487653 9.52156634,15.0337539 L9.77132688,15.1586342 C10.5413042,15.5436229 11.4712471,15.3927139 12.0799676,14.7839934 Z"
                                                        fill="#000000"/>
                                                </g>
                                           </svg>
                                        </span>
                                            {`${applicant?.socialInfos?.phones[0]?.areaCode || ''} ${applicant?.socialInfos?.phones[0]?.no || ''}`}
                                        </a>
                                        }
                                    </div>
                                    <div>
                                        {applicant?.socialInfos?.address &&
                                        <a href="#"
                                           className="text-black-50 text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2">
                                        <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                                           <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                    <rect x="0" y="0" width="24" height="24"/>
                                                    <path
                                                        d="M5,10.5 C5,6 8,3 12.5,3 C17,3 20,6.75 20,10.5 C20,12.8325623 17.8236613,16.03566 13.470984,20.1092932 C12.9154018,20.6292577 12.0585054,20.6508331 11.4774555,20.1594925 C7.15915182,16.5078313 5,13.2880005 5,10.5 Z M12.5,12 C13.8807119,12 15,10.8807119 15,9.5 C15,8.11928813 13.8807119,7 12.5,7 C11.1192881,7 10,8.11928813 10,9.5 C10,10.8807119 11.1192881,12 12.5,12 Z"
                                                        fill="#000000" fillRule="nonzero"/>
                                                </g>
                                           </svg>
                                        </span>
                                            {`${applicant?.socialInfos?.address?.no || ''}, ${applicant?.socialInfos?.address?.locality || ''}`}
                                            <img src={`workspace/env/files/zl/rtr/qrcodes/${item?.qrCode}`} alt=""/>
                                        </a>
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="my-lg-0 my-1">
                                {page === "archived" && <ArchivedBtn {...{item, handleStateChange}}/>}
                                {page === "reception" && <ReceptBtn {...{handleStateChange, id, item}}/>}
                                {page === "cart" && <CartBtn {...{handleStateChange, item}}/>}
                                {page === "specimens" && <SpecBtn {...{handleStateChange, item}}/>}
                                {page === "laboratory" && <LabBtn {...{handleStateChange, item}}/>}
                                {page === "notification" && <NotificationBtn {...{handleStateChange, item}}/>}
                                {page === "results" && <ResultBtn {...{handleStateChange, item}}/>}
                                {page === "unfinished" && <UnfinishedBtn {...{handleStateChange, item}}/>}
                            </div>
                        </div>

                        <div className="d-flex align-items-center flex-wrap justify-content-between">
                            <div className="flex-grow-1 font-weight-bold text-dark-50 py-2 py-lg-2 mr-5">

                            </div>
                        </div>
                    </div>

                </div>

                <div className="separator separator-solid my-7"/>
                <InfoBar {...{item}}/>

            </div>
        </div>

    );
});


export default React.memo(withRouter(Item))
