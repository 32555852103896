/**
 * Created by louidorpc on 1/20/21.
 */
import React from "react"
import {FormattedMessage} from "react-intl";
import {getIntlMsg} from "../../../constants/utils";

export class FileInputDocument extends React.Component {

    state = {
        filename: ""
    }

    getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    onFileChange = async (e) => {
        const {input} = this.props
        const targetFile = e.target.files[0]
        var reader = new FileReader();
        if (targetFile) {
            const val = await this.getBase64(targetFile)
            const fileName = targetFile.name;
            // let str =fileName.split("base64,")[1];
            const current = {
                'size': targetFile.size,
                'fileName': fileName.substring(0, fileName.lastIndexOf('.')),
                'fileType': fileName.substring(fileName.lastIndexOf('.') + 1),
                'uploadedFile': val.split("base64,")[1]
            }
            console.log("==================", val)
            this.setState({filename: current.fileName})

            input.onChange(current)
        } else {
            input.onChange(null)
        }
    }

    render() {
        const {filename} = this.state
        const {id, input} = this.props

        return (
            <>
                <label for={id} class="btn">
                    <div className="symbol symbol-30 symbol-circle symbol-xl-50">
                        <div className="symbol-label">
                            <span className="material-icons" style={{fontSize: '40px'}}>source</span>
                        </div>
                    </div>
                    <div> {filename ? filename : input.value}</div>

                </label>
                <input
                    type="file"
                    onChange={this.onFileChange}
                    id={id}
                    style={{visibility: "hidden"}}
                />
            </>
        )
    }
}