import React, {useContext} from "react";
import {GlobalContext} from "../../../../../index";
import Modal from "react-bootstrap/Modal";
import {FormattedMessage} from "react-intl";


const ModalComponent = props => {
    const {children, title} = props
    const {modalChildren, setModalChildren} = useContext(GlobalContext)
    const {hideModal, modalIsOpen} = useContext(GlobalContext);

    return (
        <Modal show={modalIsOpen.name === "sectors" || modalIsOpen.name === "galery" ? modalIsOpen.open : false}
               onHide={() => hideModal(modalIsOpen.name)} size={modalIsOpen.name === "galery" ? 'lg' : 'xl'}
               className="modal-100w"
               aria-labelledby="example-custom-modal-styling-title">
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    {modalIsOpen.name}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body id="example-custom-modal-styling-title">
                {modalChildren}
            </Modal.Body>
        </Modal>
    )
}

export default ModalComponent