import * as React from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {useParams, withRouter} from "react-router";
import {useDispatch, useSelector} from "react-redux";
// import Item from "./Item";
// import SearchBar from "./SearchBar";
import Spinner from "../../../../common/utils/Spinner";
import TablePager from "../../../common/pagination/TablePager";
import useEntityManager from "../../../../common/hooks/useEntityManager";
import moment from "moment";
import {GlobalContext} from '../../../../index'
import {useContext, useEffect, useRef} from "react";
import Item from "./Item";
import {useReactToPrint} from "react-to-print";
import CartReport from '../../../modules/request/reports/cart'
import actions from "../../../../../thunks/common/coreThunks";


const TestApplicantList = injectIntl((props) => {

    const dispatch = useDispatch();
    const {intl, formView, setFormView} = props;
    const {module, id} = useParams();

    const {userSession: {name, email}} = useContext(GlobalContext)


    const criteria = {options: ['requests']};
    const options = {options: ['cart', 'summary']};

    const params = {options: [module]}
    const {} = useEntityManager(params, module)

    useEffect(() => {
        dispatch(actions.loadItems(options))
    }, []);


    const {loading, clients} = useSelector(state => ({
        loading: state?.requestReducer?.loadingCartSummary,
        clients: state?.requestReducer?.cartSummary,
    }));
    const cartReportRef = useRef();

    let sumAg = 0;
    let sumPcr = 0;
    let sumGds = 0;
    let sumUsd = 0;

    const handlePrintCartReport = useReactToPrint({
        content: () => cartReportRef.current,
    });

    return (
        <React.Fragment>
            <div className="d-flex flex-column-fluid pt-10">

                <div className="container">

                    <div className="card card-custom overflow-hidden">
                        <div className="card-body p-0">

                            <div className="row justify-content-center py-8 px-8 py-md-10 px-md-0">
                                <div className="col-md-9">
                                    <div className="d-flex justify-content-between float-right">
                                        <button type="button" className="btn btn-primary font-weight-bold float-right"
                                                onClick={handlePrintCartReport}>Imprimer le rapport
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div className="row justify-content-center py-8 px-8 py-md-10 px-md-0">
                                <div className="col-md-9">
                                    <div
                                        className="d-flex justify-content-between pb-2 pb-md-2 flex-column flex-md-row">
                                        <h1 className="display-4 font-weight-boldest mb-10">RAPPORT DE CAISSE</h1>
                                        <div className="d-flex flex-column align-items-md-end px-0">
                                            <a href="#" className="mb-2">
                                                <img src="/assets/media/logos/zl.png"
                                                     className="h-100px rounded" alt=""/>
                                            </a>
                                            <span className="d-flex flex-column align-items-md-end opacity-70">
															<span><b>HOPITAL UNIVERSITAIRE DE MIREBALAIS (HUM)</b></span>
															<span>Laboratoire Régional de Référence Stephen Robert et Pilar Crespi Robert</span>
															<span>Laboratoire de Microbiologie et de Biologie Moléculaire (BSL-2, BSL3)</span>
														</span>
                                        </div>
                                    </div>
                                    <div className="border-bottom w-100"></div>
                                    <div className="d-flex justify-content-between pt-3">
                                        <div className="d-flex flex-column flex-root">
                                            <span className="font-weight-bolder mb-2">Date</span>
                                            <span
                                                className="opacity-70">{moment(new Date()).format('DD/MM/YYYY')}</span>
                                        </div>
                                        <div className="d-flex flex-column flex-root">

                                        </div>
                                        <div className="d-flex flex-column flex-root">
                                            <span className="font-weight-bolder mb-2">Caissier (ère).</span>
                                            <span className="opacity-70">{name && name}
                                                <br></br>{email && email}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row justify-content-center py-8 px-8 py-md-4 px-md-0">
                                <div className="col-md-9">
                                    <div className="table-responsive">
                                        <table className="table">
                                            <thead>
                                            <tr>
                                                <th className="pl-0 font-weight-bold text-muted text-uppercase">Client</th>
                                                <th className="text-right font-weight-bold text-muted text-uppercase">Test
                                                    Ag
                                                </th>
                                                <th className="text-right pr-0 font-weight-bold text-muted text-uppercase">Test
                                                    PCR
                                                </th>
                                                <th className="text-right pr-0 font-weight-bold text-muted text-uppercase">Montant
                                                    GDS
                                                </th>
                                                <th className="text-right pr-0 font-weight-bold text-muted text-uppercase">Montant
                                                    USD
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {clients.length > 0 ? clients.map(v => <Item item={v}/>) : null}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="row justify-content-center bg-gray-100 py-8 px-8 py-md-10 px-md-0">
                                <div className="col-md-9">
                                    <div className="table-responsive">
                                        <table className="table">
                                            <thead>
                                            <tr>
                                                <th className="font-weight-bold text-muted text-uppercase"></th>
                                                <th className="font-weight-bold text-muted text-uppercase">Test Ag</th>
                                                <th className="font-weight-bold text-muted text-uppercase">Test PCR</th>
                                                <th className="font-weight-bold text-muted text-uppercase">Montant GDS
                                                </th>
                                                <th className="font-weight-bold text-muted text-uppercase">Montant USD
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr className="font-weight-bolder">
                                                <td>Total</td>
                                                <td className={'font-size-h3'}>{clients && clients.map(value => {

                                                    if (value.agCounter === 1) {
                                                        sumAg = parseInt(sumAg) + parseInt(value.agCounter);

                                                    }
                                                })}{sumAg}</td>
                                                <td className={'font-size-h3'}>{clients && clients.map(value => {
                                                    if (value.pcrCounter === 1) {
                                                        sumPcr = sumPcr + value.pcrCounter;
                                                    }
                                                })}{sumPcr}</td>
                                                <td className="font-size-h3 font-weight-boldest">{clients && clients.map(value => {
                                                    let s = 0;
                                                    if (value.gdsAmount !== null) {
                                                        sumGds = sumGds + value.gdsAmount;
                                                    }
                                                })} {sumGds}</td>
                                                <td className="font-size-h3 font-weight-boldest">{clients && clients.map(value => {
                                                    let s = 0;
                                                    if (value.usdAmount !== null) {
                                                        sumUsd = sumUsd + value.usdAmount;
                                                    }
                                                })}{sumUsd}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <div className="row justify-content-center py-8 px-8 py-md-10 px-md-0">
                                <div className="col-md-9">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{display: "none", overflow: "hidden", height: 0}}>
                <CartReport ref={cartReportRef} {...{clients, email, name}}/>
            </div>
        </React.Fragment>
    );
})

export default React.memo(withRouter(TestApplicantList))

