import * as validator from "../../../../../../constants/utils/validator";


export const validate = values => {
    const errors = {};
    errors.memberFile = values.memberFile === null;
    errors.category = values.category === null;
    // errors.frContent = values.frContent === " ";
    errors.publishedOn = validator.validateDate('publishedOn', values.publishedOn, '1900-01-01', new Date(), true);
    errors.firstName = validator.validateString('firstName', values.firstName, 3, 150, true);
    errors.lastName = validator.validateString('lastName', values.lastName, 3, 150, true);
    errors.fullname = validator.validateString('fullname', values.fullname, 3, 150, true);
    errors.occupation = validator.validateString('occupation', values.occupation, 3, 200, true);
    errors.position = validator.validateString('position', values.position, 3, 150, true);
    errors.frContent = validator.validateString('frContent', values.frContent, 10, 1000, true);

    return errors
};


