export class RecruitmentModel {

    constructor(params) {
        if (params) {

            const {
                frdescription,
                htdescription,
                endescription,
                enname,
                frname,
                htname,
                dateEnded,
                dateStarted,
                category,
                documents,
                idName,
                idDesc,
                frcategory,
                encategory,
                htcategory,
                frabout,
                enabout,
                htabout,
                idAbout,
                place,
                requiredDocs
            } = params;

            let entity = null;

            if (frcategory && frcategory?.__isNew__) {
                entity = {
                    entityType: "RECRUITMENT",
                    name: {
                        frContent: frcategory?.label,
                        enContent: encategory?.label,
                        htContent: htcategory?.label,
                    },
                    description: {
                        frContent: frcategory?.label,
                        enContent: encategory?.label,
                        htContent: htcategory?.label,
                    },
                }
            } else {
                entity = {
                    id: frcategory?.value || null,
                    name: {
                        frContent: frcategory?.label,
                        enContent: encategory?.label,
                        htContent: htcategory?.label,
                    }, description: {
                        frContent: frcategory?.label,
                        enContent: encategory?.label,
                        htContent: htcategory?.label,
                    }
                }
            }

            this.place = place ? place : null;
            this.description = {
                enContent: endescription,
                frContent: frdescription,
                htContent: htdescription,
                id: idDesc ? idDesc : null
            }

            this.about = {
                enContent: enabout,
                frContent: frabout,
                htContent: htabout,
                id: idAbout ? idAbout : null
            }

            this.requiredDocs = requiredDocs[0] !== undefined ? arrayMapperDocs(requiredDocs) : []

            this.name = {
                enContent: enname,
                frContent: frname,
                htContent: htname,
                id: idName ? idName : null
            }

            this.dateEnded = dateEnded;
            this.dateStarted = dateStarted;
            this.category = entity;
            this.documents = documents[0] !== undefined ? documents.map(v => {
                if (v?.file?.fileName !== null) {
                    return ({
                        id: v?.id,
                        file: v?.file?.fileName ? v?.file : null,
                    })
                } else {
                    return ({
                        id: v?.id,
                    })
                }
            }) : [];
            ;
        }
    }
}


export class RecruitmentMapper {

    constructor(params) {
        if (params) {

            const {
                name,
                description,
                dateStarted,
                dateEnded,
                category,
                documents,
                about,
                place,
                requiredDocs
            } = params;

            this.endescription = description?.enContent ? description?.enContent : null
            this.frdescription = description?.frContent ? description?.frContent : null
            this.htdescription = description?.htContent ? description?.htContent : null

            this.enabout = about?.enContent ? about?.enContent : null
            this.frabout = about?.frContent ? about?.frContent : null
            this.enabout = about?.htContent ? about?.htContent : null
            this.place = place ? place : null;

            this.enname = name?.enContent
            this.frname = name?.frContent
            this.htname = name?.htContent
            this.dateStarted = dateStarted;
            this.dateEnded = dateEnded;
            this.frcategory = {value: category?.id, label: category?.name?.frContent};
            this.encategory = {value: category?.id, label: category?.name?.enContent};
            this.htcategory = {value: category?.id, label: category?.name?.htContent};
            this.documents = documents ? documents.map(v => ({
                file: v.documentName,
                id: v.id
            })) : [];

            this.requiredDocs = requiredDocs ? requiredDocs.map(v => ({
                name: v,
            })) : [];
        }
    }
}


const arrayMapperDocs = (arrays) => {
    let str = []
    if (arrays) {
        for (const [key, value] of Object.entries(arrays)) {
            str.push(value?.name);
        }
    }
    return str;
}


