import React, {useContext, useEffect, useState} from "react"
import {Field} from "redux-form";
import {FormattedMessage, injectIntl} from 'react-intl';
import {
    renderCheckbox,
    renderField,
    renderFreeField,
    renderRadio,
    renderSelect
} from "../../../../../common/utils/FormComponent";
import {withRouter} from "react-router";
import {getIntlMsg} from "../../../../../../constants/utils";
import {FileInput} from "../../../../../common/utils/FileInput";
import {useDispatch, useSelector} from "react-redux";
import actions from "../../../../../../thunks/settings/categoryThunks";
import {GlobalContext} from "../../../../../index";
import TextEditorWYSIWYG from "../../../../../common/utils/RichTextEditorWYSIWYG";
import params from "../../../../../../constants/utils/params";
import swal from "sweetalert";
import {canDisplay} from "../../../../../../constants/utils/security";


let renderSlides = injectIntl(props => {
    const {intl, fields, meta: {error}, isAdd, touched, reset} = props

    const dispatch = useDispatch();
    const options = {options: ['categories']};

    const {lang, setLang, hideModal} = useContext(GlobalContext);

    const {item, loading, status} = useSelector(state => ({
        loading: state.componentReducer.loadingItem,
        item: state.componentReducer.sliderItem,
        status: state.componentReducer.status,
    }));


    const {languages} = params;

    const [dirty, setDirty] = useState(false)

    const handleChangeLanguage = val => {
        setLang(val)
        setDirty(false)
    }

    const handleChangeDirty = val => {
        setDirty(true)
    }

    const resetSlider = () => {
        hideModal('slides')
        dispatch(actions.resetItem({options: ['sliders']}))
    }


    const DeleteSlide = et => {

        swal({
            title: "Êtes-vous sûr?",
            text: "de supprimer ce slide",
            icon: "warning",
            buttons: ["Non", "Oui"],
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    const param = 'slides/' + et;
                    const params = {intl, item: {id: item?.id}, options: ['sliders'], param: param}
                    dispatch(actions.removeItem(params))
                } else {
                }
            });
    }


    const handlePublishUnPublish = (id, status) => {

        swal({
            title: "voulez-vous vraiment",
            text: status === "publish" ? "le Publier" : "ne le pas Publier",
            icon: "warning",
            buttons: ["Non", "Oui"],
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    dispatch(actions.saveOrUpdateItem({
                        options: ['common', 'slide'],
                        intl,
                        action: '',
                        item: {id: id},
                    }))
                } else {
                }
            });
    }

    const {userSession: {roles}} = useContext(GlobalContext);
    if (!fields.length) fields.push();
    if (status === "sliderSucceeded") resetSlider()
    return (
        <div className="row">

            <div className="col-sm-12">
                <div className="row pb-5">
                    {lang === 'fr' ?
                        <div
                            className="label label-light-primary label-pill label-inline col-md-12 p-6"><span
                            className="">Francais</span></div> :
                        lang === 'en' ?
                            <div
                                className="label label-light-info label-pill label-inline col-md-12 p-6"><span
                                className="">Anglais</span></div> :
                            <div className="label label-light-danger label-pill label-inline col-md-12 p-6">
                                <span className="">Kreyol</span></div>}
                </div>
            </div>

            <div className="col-sm-12">
                <div className="row pb-5">
                    <div className="col-sm-4">
                        {languages && languages.map((language, key) =>
                                <span className="mr-4" key={key}>
                            <label htmlFor={language.id} style={{cursor: "pointer"}}
                                   onClick={() => handleChangeLanguage(language.id)}>
                                <b className={'mr-1'}><FormattedMessage id={`app.label.${language.label}`}/></b>
                                <Field type="radio" component="input" name={'lang'} value={language.id} id={language.id}
                                       onClick={() => handleChangeLanguage(language.id)}/>
                            </label>
                        </span>
                        )}

                    </div>
                </div>
            </div>

            <div className="col-md-12 offset-10 pb-10">
                    <span className="btn btn-sm btn-outline-primary" onClick={() => fields.push()}>
                        <FormattedMessage id="app.label.add_slide"/>
                    </span>
            </div>
            <div className="col-sm-12">
                {fields.map((lg, index) => (
                    <div className={'row'} key={index}>

                        <div className="col-sm-1">
                            <h1 className="mt-5 text-center">{index + 1}</h1>
                        </div>
                        <div className="col-sm-2">
                            <Field component={FileInput} name={`${lg}slideFile`} id={`${lg}_logo`} type="file"/>
                        </div>

                        <div className="col-sm-3">
                            <Field
                                className="form-control form-control-sm"
                                id={`${lg}${lang}Name`}
                                name={`${lg}${lang}Name`}
                                component={renderField}
                                type="text"
                                label={`${lang}name`}
                            />
                            {error && <li className="error">{error}</li>}
                        </div>
                        <div className="col-sm-5">
                            <label><FormattedMessage id={`app.label.${lang}description`}
                                                     defaultMessage={''}/></label>
                            <div style={{border: '1px solid #000', width: '100%'}}>
                                <Field
                                    onChange={() => handleChangeDirty()}
                                    id={`${lg}${lang}Desc`}
                                    name={`${lg}${lang}Desc`}
                                    component={TextEditorWYSIWYG}
                                    meta={{dirty: dirty}}
                                    label={`${lang}description`}
                                />
                            </div>

                        </div>

                        <div className="col-sm-1">
                            <button type="button" title={getIntlMsg(intl, 'app.label.remove_trip')}
                                    className="btn btn-icon btn-light-primary btn-circle mr-2 mt-5"
                                    onClick={() => fields.remove(index)}>
                                <span className="material-icons">close</span>
                            </button>
                            <button type="button" title={getIntlMsg(intl, 'app.label.remove_trip')}
                                    className="btn btn-icon btn-light-primary btn-circle mr-2 mt-5"
                                    onClick={() => DeleteSlide(item?.slides[index]?.id)}>
                                <i className="flaticon2-trash"/>
                            </button>
                            {item?.slides[index]?.status && canDisplay(roles, 'publish') ?
                                <button type="button" title={getIntlMsg(intl, 'app.label.remove_trip')}
                                        className="btn btn-icon btn-light-primary btn-circle mr-2 mt-5"
                                        onClick={() => handlePublishUnPublish(item?.slides[index]?.id, 'unpublish')}>
                                    <span className="material-icons">unpublished</span>
                                </button> : canDisplay(roles, 'publish') &&
                                <button type="button" title={getIntlMsg(intl, 'app.label.remove_trip')}
                                        className="btn btn-icon btn-light-primary btn-circle mr-2 mt-5"
                                        onClick={() => handlePublishUnPublish(item?.slides[index]?.id, 'publish')}>
                                    <span className="material-icons">publish</span>
                                </button>}
                        </div>
                    </div>
                ))}
                {error && <li className="error">{error}</li>}
            </div>
        </div>
    );
})
export default React.memo(withRouter(renderSlides))
