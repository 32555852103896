import logo from "../../components/pages/modules/components/institution/generaly/logo";

export class Category {

    constructor(params) {
        if (params) {

            const {
                frdescription,
                endescription,
                htdescription,
                frname,
                enname,
                htname,
                entityName
            } = params;

            this.description = {
                enContent: endescription,
                frContent: frdescription,
                htContent: htdescription
            }
            this.entityType = entityName;

            this.name = {
                enContent: enname,
                frContent: frname,
                htContent: htname
            }
        }
    }
}
