export const hasEntryAccess = (roles, currentEntry) => {

    let status = false;
    let common = ['requests-dashboard']

    const authorEntries = [
        'reports',
        'reports_management',
        'tests_performed',
        'admin',
        'news',
        'sectors',
        'testimonials',
        'members',
        'health-centers',
        'admin_management',
        'component_management',
        'components',
        'component',
        'sliders',
        'institution',
        'statistics',
        'services',
        'dashboard',
        'dashboards',
        'global_dashboard',
        'update',
        'create',
        'new_request',
        'staffing',
        'research',
        'donation',
    ]

    const staffingEntries=[
        'admin_management',
        'staffing',
        'update',
        'delete',
        'create',
        'publish',
        'reference_tables',
        'admin',
    ]


    const operationChiefEntries = [
        'reports',
        'applicants',
        'admin',
        'news',
        'admin_management',
        'sectors',
        'testimonials',
        'members',
        'health-centers',
        'admin_management',
        'component_management',
        'components',
        'component',
        'sliders',
        'institution',
        'statistics',
        'services',
        'dashboard',
        'global_dashboard',
        'update',
        'delete',
        'create',
        'publish',
        'new_request',
        'messages',
        'staffing',
        'research',
        'donation',
        "donate"
    ];

    const admEntries = [
        'zl',
        'generals',
        'edit',
        'global_dashboard',
        'security-dashboard',
        'requests-dashboard',
        'observations-dashboard',
        'admin_management',
    ];
    const superAdminEntries = [
        ...admEntries,
        ...operationChiefEntries,
        "settings",
        "donate"
    ];

    roles.forEach((role) => {
            switch (role) {


                case 'ADMIN': {
                    operationChiefEntries.forEach((entry) => {
                        if (entry === currentEntry)
                            status = true;
                    });
                    break;
                }


                case 'SUPER_ADMINISTRATOR': {
                    superAdminEntries.forEach((entry) => {
                        if (entry === currentEntry)
                            status = true;
                    });
                    break;
                }

                case 'AUTHOR': {
                    authorEntries.forEach((entry) => {
                        if (entry === currentEntry)
                            status = true;
                    });
                    break;
                }
                case 'RH': {
                    staffingEntries.forEach((entry) => {
                        if (entry === currentEntry)
                            status = true;
                    });
                    break;
                }

                default:
                    status = false;
            }
        }
    );

    return status;
};


export const canDisplay = (roles, label) => {

    let status = false;

    const authorEntries = [
        'reports',
        'reports_management',
        'tests_performed',
        'admin',
        'news',
        'sectors',
        'testimonials',
        'members',
        'health-centers',
        'admin_management',
        'component_management',
        'components',
        'component',
        'sliders',
        'institution',
        'statistics',
        'services',
        'dashboard',
        'dashboards',
        'global_dashboard',
        'update',
        'create',
        'new_request',
        'staffing',
        'research',
        'donation',
    ]

    const operationChiefEntries = [
        'reports',
        'reports_management',
        'tests_performed',
        'admin',
        'news',
        'sectors',
        'testimonials',
        'members',
        'health-centers',
        'admin_management',
        'component_management',
        'components',
        'component',
        'sliders',
        'institution',
        'statistics',
        'services',
        'dashboard',
        'dashboards',
        'global_dashboard',
        'update',
        'delete',
        'create',
        'publish',
        'new_request',
        'messages',
        'staffing',
        'research',
        'donation',
        "donate"
    ]

    const admEntries = [
        'reference_tables',
        'remove',
        'offline',
        'reports',
        'request_management',
        'observation_management',
        'global_dashboard',
        'security_dashboard',
        'archived_request',
        'new_cart',
        'admin',
        'messages'
    ];
    const superAdmEntries = [
        ...admEntries,
        ...operationChiefEntries,
        'settings',
        'donate',
    ];
    const staffingEntries=[
        'admin_management',
        'staffing',
        'update',
        'delete',
        'create',
        'publish',
        'reference_tables',
        'admin',
    ]

    roles.forEach((role) => {
            switch (role) {

                case 'ADMIN': {
                    operationChiefEntries.forEach((entry) => {
                        if (entry === label)
                            status = true;
                    });
                    break;
                }


                case 'SUPER_ADMINISTRATOR': {
                    superAdmEntries.forEach((entry) => {
                        if (entry === label)
                            status = true;
                    });
                    break;
                }

                case 'AUTHOR': {
                    authorEntries.forEach((entry) => {
                        if (entry === label)
                            status = true;
                    });
                    break;
                }

                case 'RH': {
                    staffingEntries.forEach((entry) => {
                        if (entry === label)
                            status = true;
                    });
                    break;
                }
                default:
                    status = false;
            }
        }
    );

    return status;
};


export const loadDefaultHomePage = (roles, institution) => {
    switch (roles[0]) {

        case 'ARCHIVIST':
        case 'DATA_OFFICER':
        case 'LAB_TECHNICIAN':
        case 'RESULT_PROVIDER':
        case 'LAB_TECHNICIAN_SUPERVISOR':
        case 'RECEPTIONIST': {
            return `/${institution}/leadership-dashboard`
        }

        case 'CASHIER':
        case 'FINANCIAL_OFFICER': {
            return `/${institution}/financial-dashboard`
        }

        case 'LEADERSHIP_MEMBER': {
            return `/${institution}/leadership-dashboard`
        }


        case 'DATA_MANAGER': {
            return `/${institution}/sharing`
        }


        case 'DATA_CLEANER': {
            return `/${institution}/unfinished`
        }

        case 'INDICATOR_UPLOADER': {
            return `/${institution}/indicators`
        }

        case 'ADMIN': {
            return `/${institution}/dashboard`
        }
        case 'RH': {
            return `/${institution}/admin/staffing`
        }
        case 'SUPER_ADMINISTRATOR':
            return `/${institution}/dashboard`;
        default:
            return null
    }
};

