import React, {useContext} from "react";
import {GlobalContext} from "../../../../../index";
import Modal from "react-bootstrap/Modal";
import {FormattedMessage} from "react-intl";


const ModalComponent = props => {
    const {children, title, item} = props
    const {modalChildren, setModalChildren} = useContext(GlobalContext)
    const {hideModal, modalIsOpen} = useContext(GlobalContext);

    return (
        <Modal
            show={modalIsOpen.name === "staffing" || modalIsOpen.name === "logo" || modalIsOpen.name === "applicant" ? modalIsOpen.open : false}
            onHide={() => hideModal(modalIsOpen.name)} size={modalIsOpen.name === "logo" ? 'lg' : "xl"}
            className="modal-100w"
            aria-labelledby="example-custom-modal-styling-title">
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    {modalIsOpen.name === "logo" && "Attachements"}
                    {modalIsOpen.name === "staffing" && "Recrutement"}
                    {modalIsOpen.name === "applicant" &&
                    <span>Postulants pour le poste : <span
                        style={{color: '#ff6900'}}>{item?.name}</span></span>}


                </Modal.Title>
            </Modal.Header>
            <Modal.Body id="example-custom-modal-styling-title">
                {modalChildren}
            </Modal.Body>
        </Modal>
    )
}

export default ModalComponent