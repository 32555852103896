const actionTypes = {


    /**
     * @Author Louidor Jean-Paul
     * LOAD COMPANY ACTION TYPES
     * -------------------------------------------
     * */
    LOAD_CATEGORIES_ENTITY_TYPE_MEMBRE: "LOAD_CATEGORIES_ENTITY_TYPE_MEMBRE",
    LOAD_CATEGORIES_ENTITY_TYPE_MEMBER_SUCCEEDED: "LOAD_CATEGORIES_ENTITY_TYPE_MEMBER_SUCCEEDED",
    LOAD_CATEGORIES_ENTITY_TYPE_MEMBRE_FAILED: "LOAD_CATEGORIES_ENTITY_TYPE_MEMBRE_FAILED",

    LOAD_CATEGORIES_ENTITY_TYPE_NEWS: "LOAD_CATEGORIES_ENTITY_TYPE_NEWS",
    LOAD_CATEGORIES_ENTITY_TYPE_NEWS_SUCCEEDED: "LOAD_CATEGORIES_ENTITY_TYPE_NEWS_SUCCEEDED",
    LOAD_CATEGORIES_ENTITY_TYPE_NEWS_FAILED: "LOAD_CATEGORIES_ENTITY_TYPE_NEWS_FAILED",


    LOAD_MEMBERS: "LOAD_MEMBERS",
    LOAD_MEMBERS_SUCCEEDED: "LOAD_MEMBERS_SUCCEEDED",
    LOAD_MEMBERS_FAILED: "LOAD_MEMBERS_FAILED",

    LOAD_NEWS: "LOAD_NEWS",
    LOAD_NEWS_SUCCEEDED: "LOAD_NEWS_SUCCEEDED",
    LOAD_NEWS_FAILED: "LOAD_NEWS_FAILED",

    LOAD_SECTORS: "LOAD_SECTORS",
    LOAD_SECTORS_SUCCEEDED: "LOAD_SECTORS_SUCCEEDED",
    LOAD_SECTORS_FAILED: "LOAD_SECTORS_FAILED",

    LOAD_TESTIMONIALS: "LOAD_TESTIMONIALS",
    LOAD_TESTIMONIALS_SUCCEEDED: "LOAD_TESTIMONIALS_SUCCEEDED",
    LOAD_TESTIMONIALS_FAILED: "LOAD_TESTIMONIALS_FAILED",

    LOAD_SERVICES: "LOAD_SERVICES",
    LOAD_SERVICES_SUCCEEDED: "LOAD_SERVICES_SUCCEEDED",
    LOAD_SERVICES_FAILED: "LOAD_SERVICES_FAILED",

    LOAD_HEALTH_CENTERS: "LOAD_HEALTH_CENTERS",
    LOAD_HEALTH_CENTERS_SUCCEEDED: "LOAD_HEALTH_CENTERS_SUCCEEDED",
    LOAD_HEALTH_CENTERS_FAILED: "LOAD_HEALTH_CENTERS_FAILED",


    LOAD_MEMBER: "LOAD_MEMBER",
    LOAD_MEMBER_SUCCEEDED: "LOAD_MEMBER_SUCCEEDED",
    LOAD_MEMBER_FAILED: "LOAD_MEMBER_FAILED",
    UPDATE_MEMBER_SUCCEEDED: "UPDATE_MEMBER_SUCCEEDED",
    SAVE_MEMBER_SUCCEEDED: "SAVE_MEMBER_SUCCEEDED",
    RESET_MEMBER: "RESET_MEMBER",
    DELETE_MEMBER_SUCCEEDED: "DELETE_MEMBER_SUCCEEDED",
    UPDATE_COMMON_MEMBER_SUCCEEDED: "UPDATE_COMMON_MEMBER_SUCCEEDED",


    LOAD_NEW: "LOAD_NEW",
    LOAD_NEW_SUCCEEDED: "LOAD_NEW_SUCCEEDED",
    LOAD_NEW_FAILED: "LOAD_NEW_FAILED",

    UPDATE_NEW_SUCCEEDED: "UPDATE_NEW_SUCCEEDED",
    SAVE_NEW_SUCCEEDED: "SAVE_NEW_SUCCEEDED",
    RESET_NEW: "RESET_NEW",
    DELETE_NEW_SUCCEEDED: "DELETE_NEW_SUCCEEDED",
    UPDATE_COMMON_NEW_SUCCEEDED: "UPDATE_COMMON_NEW_SUCCEEDED",


    LOAD_SECTOR: "LOAD_SECTOR",
    LOAD_SECTOR_SUCCEEDED: "LOAD_SECTOR_SUCCEEDED",
    LOAD_SECTOR_FAILED: "LOAD_SECTOR_FAILED",

    UPDATE_SECTOR_SUCCEEDED: "UPDATE_SECTOR_SUCCEEDED",
    SAVE_SECTOR_SUCCEEDED: "SAVE_SECTOR_SUCCEEDED",
    RESET_SECTOR: "RESET_SECTOR",
    DELETE_SECTOR_SUCCEEDED: "DELETE_SECTOR_SUCCEEDED",
    UPDATE_COMMON_SECTOR_SUCCEEDED: "UPDATE_COMMON_SECTOR_SUCCEEDED",


    LOAD_TESTIMONIAL: "LOAD_TESTIMONIAL",
    LOAD_TESTIMONIAL_SUCCEEDED: "LOAD_TESTIMONIAL_SUCCEEDED",
    LOAD_TESTIMONIAL_FAILED: "LOAD_TESTIMONIAL_FAILED",

    UPDATE_TESTIMONIAL_SUCCEEDED: "UPDATE_TESTIMONIAL_SUCCEEDED",
    SAVE_TESTIMONIAL_SUCCEEDED: "SAVE_TESTIMONIAL_SUCCEEDED",
    RESET_TESTIMONIAL: "RESET_TESTIMONIAL",
    DELETE_TESTIMONIAL_SUCCEEDED: "DELETE_TESTIMONIAL_SUCCEEDED",
    UPDATE_COMMON_TESTIMONIAL_SUCCEEDED: "UPDATE_COMMON_TESTIMONIAL_SUCCEEDED",


    LOAD_SERVICE: "LOAD_SERVICE",
    LOAD_SERVICE_SUCCEEDED: "LOAD_SERVICE_SUCCEEDED",
    LOAD_SERVICE_FAILED: "LOAD_SERVICE_FAILED",

    UPDATE_SERVICE_SUCCEEDED: "UPDATE_SERVICE_SUCCEEDED",
    SAVE_SERVICE_SUCCEEDED: "SAVE_SERVICE_SUCCEEDED",
    RESET_SERVICE: "RESET_SERVICE",
    DELETE_SERVICE_SUCCEEDED: "DELETE_SERVICE_SUCCEEDED",
    UPDATE_COMMON_HC_SERVICE_SUCCEEDED: "UPDATE_COMMON_HC_SERVICE_SUCCEEDED",


    LOAD_HEALTH_CENTER: "LOAD_HEALTH_CENTER",
    LOAD_HEALTH_CENTER_SUCCEEDED: "LOAD_HEALTH_CENTER_SUCCEEDED",
    LOAD_HEALTH_CENTER_FAILED: "LOAD_HEALTH_CENTER_FAILED",

    UPDATE_HEALTH_CENTER_SUCCEEDED: "UPDATE_HEALTH_CENTER_SUCCEEDED",
    SAVE_HEALTH_CENTER_SUCCEEDED: "SAVE_HEALTH_CENTER_SUCCEEDED",
    RESET_HEALTH_CENTER: "RESET_HEALTH_CENTER",
    DELETE_HEALTH_CENTER_SUCCEEDED: "DELETE_HEALTH_CENTER_SUCCEEDED",
    UPDATE_COMMON_HEALTH_CENTER_SUCCEEDED: "UPDATE_COMMON_HEALTH_CENTER_SUCCEEDED",


    SAVE_RECRUITMENT: "SAVE_RECRUITMENT",
    SAVE_RECRUITMENT_SUCCEEDED: "SAVE_RECRUITMENT_SUCCEEDED",
    SAVE_RECRUITMENT_FAILED: "SAVE_RECRUITMENT_FAILED",

    UPDATE_RECRUITMENT_SUCCEEDED: "UPDATE_RECRUITMENT_SUCCEEDED",
    UPDATE_COMMON_RECRUITMENT_SUCCEEDED: "UPDATE_COMMON_RECRUITMENT_SUCCEEDED",

    LOAD_RECRUITMENT_SUCCEEDED: "LOAD_RECRUITMENT_SUCCEEDED",
    LOAD_RECRUITMENTS_SUCCEEDED: "LOAD_RECRUITMENTS_SUCCEEDED",
    LOAD_RECRUITMENTS: "LOAD_RECRUITMENTS",
    RESET_RECRUITMENT: "RESET_RECRUITMENT",

    LOAD_RECRUITMENTS_FAILED: "LOAD_RECRUITMENTS_FAILED",

    SWITCH_LOCALE: "SWITCH_LOCALE",
    LOAD_MESSAGES_SUCCEEDED: "LOAD_MESSAGES_SUCCEEDED",


    LOAD_RESEARCH_SUCCEEDED: "LOAD_RESEARCH_SUCCEEDED",
    SAVE_RESEARCH_SUCCEEDED: "SAVE_RESEARCH_SUCCEEDED",
    UPDATE_RESEARCH_SUCCEEDED: "UPDATE_RESEARCH_SUCCEEDED",

    LOAD_APPLICATION_RECRUITMENT_SUCCEEDED: "LOAD_APPLICATION_RECRUITMENT_SUCCEEDED",


    LOAD_DONATIONS: "LOAD_DONATIONS",
    LOAD_DONATIONS_SUCCEEDED: "LOAD_DONATIONS_SUCCEEDED",
    LOAD_DONATIONS_FAILED: "LOAD_DONATIONS_FAILED",
    LOAD_DONATION_SUCCEEDED: "LOAD_DONATION_SUCCEEDED",

    UPDATE_DONATION_SUCCEEDED: "UPDATE_DONATION_SUCCEEDED",
    SAVE_DONATION_SUCCEEDED: "SAVE_DONATION_SUCCEEDED",
    RESET_DONATION: "RESET_DONATION",
    DELETE_DONATION_SUCCEEDED: "DELETE_DONATION_SUCCEEDED",
    UPDATE_DONATION_PUBLISH_SUCCEEDED: "UPDATE_DONATION_PUBLISH_SUCCEEDED",

    LOAD_GRANTS_SUCCEEDED: "LOAD_GRANTS_SUCCEEDED",



};


export default actionTypes;
