import React, {useContext, useEffect} from "react"
import {Field} from "redux-form";
import {FormattedMessage} from 'react-intl';

import actions from "../../../../../../thunks/settings/categoryThunks";
import {renderCheckbox, renderField, renderSelect} from "../../../../../common/utils/FormComponent";
import {withRouter} from "react-router";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import params from "../../../../../../constants/utils/params";
import {GlobalContext} from "../../../../../index";

let FormItems = props => {

    const {item, valid, validate, loading, pristine, submitting,touched, error} = props
    const dispatch = useDispatch();
    const {id} = useParams();
    const options = {options: ['categories']};

    useEffect(() => {
        dispatch(actions.loadItems(options));
    }, [dispatch]);

    const categories = useSelector(state => state.categoryReducer.items);
    const formattedCats = [];
    categories.forEach(category => {
        formattedCats.push({code: category?.id, name: category?.entityType})
    })

    const {languages} = params;
    const {lang, setLang} = useContext(GlobalContext);


    return (
        <div className="bg-warning-o-20 p-5 mb-5">

            <div className="row">
                <div className="col-sm-12">
                    <h5 className="text-black-50 mb-5">
                        <FormattedMessage id="app.label.category_form"/>
                    </h5>
                    <hr/>
                </div>
            </div>

            <div className="row pb-5">
                <div className="col-sm-3">
                    {languages && languages.map((language, key) =>
                        <span className="mr-4" key={key}>
                            <label htmlFor={language.id} style={{cursor: "pointer"}}
                                   onClick={() => setLang(language.id)}>
                                <b className={'mr-1'}><FormattedMessage id={`app.label.${language.label}`}/></b>
                                {/*<input type="radio" name="language" value={language.id} id={language.id}*/}
                                {/*	className="ml-2"/>*/}
                                <Field type="radio" component="input" name={'lang'} value={language.id} id={language.id} onClick={() => setLang(language.id)}/>
                            </label>
                        </span>
                    )}

                </div>
            </div>

            <div className="row mt-3">

                <div className="col-sm-3">
                    <Field style="pl-4 pr-4" id={`${lang}name`} name={`${lang}name`} component={renderField}
                           type="text" label={`${lang}name`} validate={validate.error}/>
                </div>

                <div className="col-sm-4">
                    <Field style="pl-4 pr-4" id={`${lang}description`} name={`${lang}description`}
                           component={renderField} type="text" label={`${lang}description`} placeholder="description"
                           validate={validate.error}/>
                </div>

                <div className="col-sm-2">
                    {/*<Field id="entity_name" name="entityName"*/}
                    {/*       component={renderSelect} label="entity_name" validate={validate.error}*/}
                    {/*       items={formattedCats}/>*/}
                    <label htmlFor="test_type" className="font-weight-bold">
                        <FormattedMessage id="app.label.entity_name"/> :
                    </label>

                    <Field name="entityName" component="select" className="form-control">
                        {formattedCats && formattedCats.length>0 ? formattedCats.map((item, i) =>
                            <option value={item?.name} key={i}>
                                {item && item?.name.toUpperCase()}
                            </option>
                        ):null}
                    </Field>
                    {touched && error &&
                    <span className="form-text text-primary">
                                <FormattedMessage id={error}/>
                        </span>}
                </div>

                <div className="col-sm-1 mt-12">

                    <div className="form-group">
                        <div className="checkbox-inline">

                            <label className="checkbox checkbox-square">
                                <Field type="checkbox" component="input" name="status"/>
                                <span/>
                                <FormattedMessage id={`app.label.status`}/>
                            </label>

                        </div>
                    </div>


                </div>
                {valid && <div className="col-sm-2 mt-8">
                    <button type="submit"
                            className={`btn  btn-outline-warning btn-sm ${loading && 'spinner spinner-white spinner-right mr-3'}`}>
                        < FormattedMessage id={(loading && item && item.id) ? "app.label.updating"
                            : (loading && item && !item.id) ? "app.label.saving"
                                : (!loading && item && item.id) ? "app.label.update" : "app.label.save"}
                                           disabled={pristine || submitting}
                                           defaultMessage={`Save`}/>
                    </button>
                </div>}

            </div>

        </div>

    );
}
export default React.memo(withRouter(FormItems))
