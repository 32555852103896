const params = {

    "mainMenu": [
        {
            "name": "administration",
            "items": [
                {
                    "label": "documents",
                    "url": "/administration/documents",
                },
            ]
        },
        {
            "name": "settings",
            "items": [
                {
                    "label": "reference_tables",
                    "url": "/settings/reference-tables/categories",
                }
            ]
        },
    ],

    "referenceMenu": [
        {
            "id": "administration",
            "label": "administration",
            "view": "show",
            "items": [
                {"name": "categories", "counter": 1, "url": "/settings/reference-tables/categories"},
            ]
        },
    ],

    "dashboardMenu": [
        {
            "id": "document",
            "label": "documents",
            "icon": "czi-document",
            "url": "/dashboard/documents"
        },
    ]

    , "settingsMenu": [
        {
            "id": "user_profile",
            "label": "profile",
            "icon": "czi-user",
            "url": "/settings/generals/profile"
        },
        {
            "id": "general",
            "label": "general_menu",
            "icon": "czi-settings",
            "url": "/settings/generals/apps"
        },
        {
            "id": "notification",
            "label": "notifications",
            "icon": "czi-bell",
            "url": "/settings/generals/notifications"
        },
        {
            "id": "email",
            "label": "emails",
            "icon": "czi-mail",
            "url": "/settings/generals/emails"
        },
    ],

    "languages":[
        {"id": 'fr', "label": "fr"},
        {"id": 'en', "label": "en"},
        {"id": 'ht', "label": "ht"},
    ]

}

export default params;
