import React, {useContext, useEffect} from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {useParams, withRouter} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import useDocumentTitle from "../../../../common/hooks/useDocumentTitle";
import {getIntlMsg} from "../../../../../constants/utils";
import {GlobalContext} from "../../../../index";
import actions from "../../../../../thunks/common/coreThunks";
import SubHeader from "../../../common/subheader";
import {getPatientValues} from "../../../../../constants/options/subheaderValues";
import ResearchForm from './form/researchForm'


const Research = injectIntl((props) => {

    const {intl, setView, view} = props;
    const dispatch = useDispatch();
    const {page, secondLevelPage} = useParams();
    useDocumentTitle(getIntlMsg(intl, `app.label.institution`));

    const options = {intl, options: ['requests'], item: {id: secondLevelPage}}
    const {userSession} = useContext(GlobalContext);


    useEffect(() => {
        dispatch(actions.loadItem(options))
    }, [secondLevelPage]);


    const {item, loading, savingOrUpdating, status} = useSelector(state => ({
        loading: state.requestReducer.loadingItem,
        item: state.requestReducer.item,
        savingOrUpdating: state.requestReducer.savingOrUpdating,
    }));


    return (
        <React.Fragment>
            <SubHeader {...{btnOptions: getPatientValues}} noButton/>
            <div className="flex-column-fluid">
                <div className="container">
                    <div className="card card-custom">
                        <div className="card-header card-header-tabs-line">
                            <h3 className="card-title"> Departement recherche</h3>
                        </div>
                        <div className="card-body">
                            <ResearchForm/>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
});


export default React.memo(withRouter(Research))