import * as React from "react";
import useDocumentTitle from "../../../../../common/hooks/useDocumentTitle";
import {getIntlMsg, nameAcronym} from "../../../../../../constants/utils";
import {FormattedMessage, injectIntl} from "react-intl";
import {useParams, withRouter} from "react-router";
import {connect, useDispatch, useSelector} from "react-redux";
import useEntityManager from "../../../../../common/hooks/useEntityManager";
import actions from "../../../../../../thunks/common/coreThunks";
import {Field, reduxForm, FieldArray} from "redux-form";
import {validate} from "../validator";
import {renderField, renderFreeField} from "../../../../../common/utils/FormComponent";
import {RequestMapper} from "../../../../../../constants/models/request";

import Skeleton, {SkeletonTheme} from "react-loading-skeleton";
import Items from './Items'
import FormFooter from "../../../../common/form/FormFooter";
import {
    Company,
    CompanyMapper,
    About,
    AboutMapper,
    VisionMapper,
    Vision
} from '../../../../../../constants/models/company'
import {useEffect} from "react";


const AboutForm = injectIntl((props) => {

    const {
        intl, applicant, payment, specimenCenter, state, symptoms, destination, departure, id,
        handleSubmit, pristine, valid, submitting, reset, initialValues, history, touched
    } = props;
    const {page} = useParams();

    const dispatch = useDispatch();


    const params = {options: [page]};
    const {item, loading} = useSelector(state => ({
        loading: state.companyReducer.loadingItem,
        item: state.companyReducer.item,
    }));


    const onFormSubmit = element => {
        const entity = new Vision(element)
        entity.id = item?.id ? item?.id : null

        dispatch(actions.saveOrUpdateItem({
            options: ['company', 'vision'], intl, action: item && item.id ? '' : 'save', item: entity,
        }))
    };


    return (
        <div className="tab-pane fade" id="vision" role="tabpanel" aria-labelledby="vision">
            <div className="card card-custom gutter-b">
                <div className="card-body">
                    <div className="">
                        <form onSubmit={handleSubmit(onFormSubmit)}>
                            {/*<SkeletonTheme color={'#8f9599'} highlightColor="#444">*/}
                            {/*    <p>*/}
                            {/*        <Skeleton count={3} />*/}
                            {/*    </p>*/}
                            {/*</SkeletonTheme>*/}

                            <div className={'row'}>
                                <div className={'col-sm-12'}>
                                    <Items/>
                                </div>

                            </div>

                            <FormFooter {...{
                                item,
                                loading,
                                pristine,
                                submitting,
                                reset,
                                valid,
                                dispatch,
                                history,
                                module
                            }}/>

                        </form>


                    </div>

                </div>
            </div>
        </div>

    );
});

const mapStateToProps = ({companyReducer: {item}}) => {
    console.log("-=-----====================", item)
    const defaultState = {
        langVision: 'fr'
    }
    const initVal = item ? new VisionMapper(item?.vision) : null;
    return {
        initialValues: item !== null ? {...initVal, langVision: 'fr'} : {...defaultState}
    }
};

export default React.memo(withRouter(connect(mapStateToProps,)(reduxForm({
    form: "visionForm",
    enableReinitialize: true,
    validate,
})(AboutForm))));


