import * as React from "react";
import useDocumentTitle from "../../../../../common/hooks/useDocumentTitle";
import {getIntlMsg, nameAcronym} from "../../../../../../constants/utils";
import {FormattedMessage, injectIntl} from "react-intl";
import {useParams, withRouter} from "react-router";
import {connect, useDispatch, useSelector} from "react-redux";
import useEntityManager from "../../../../../common/hooks/useEntityManager";
import actions from "../../../../../../thunks/common/coreThunks";
import {Field, reduxForm, FieldArray} from "redux-form";
import {validate} from "./validator";
import RenderSlider from "./item";
import FormFooter from "../../../../common/form/FormFooter";
import {SliderMapper, SliderModel} from "../../../../../../constants/models/SliderModel";
import {StatisticMapper, StatisticModel} from "../../../../../../constants/models/StatisticModel";

const SliderForm = injectIntl((props) => {

    const {
        intl,
        item
    } = props;


    return (
        <React.Fragment>

            <RenderSlider item={item}/>

        </React.Fragment>

    );
});

const mapStateToProps = ({componentReducer: {statisticItem}}) => {
    const defaultState = {
        lang: 'fr',
        frdescription: " ",
        endescription: " ",
        htdescription: " ",
    }
    const initVal = statisticItem ? new StatisticMapper(statisticItem) : null;
    return {
        initialValues: statisticItem !== null ? {...initVal, lang: 'fr'} : {...defaultState}
    }
};

export default React.memo(withRouter(connect(mapStateToProps,)(reduxForm({
    form: "statisticForm",
    enableReinitialize: true,
    validate,
})(SliderForm))));


