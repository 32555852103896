import React, {useContext} from "react";
import {GlobalContext} from "../../../../../index";
import {Modal} from "react-bootstrap";
import {FormattedMessage} from "react-intl";


const ModalComponent = props => {
    const {children, title} = props
    const {modalChildren, setModalChildren} = useContext(GlobalContext)
    const {hideModal, modalIsOpen} = useContext(GlobalContext);

    return (
        <Modal show={modalIsOpen.name === "logo" ? modalIsOpen.open : false}
               onHide={() => hideModal('logo')} size="lg" aria-labelledby="contained-modal-title-vcenter"
               centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {modalIsOpen?.name}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {modalChildren}
            </Modal.Body>
        </Modal>
    )
}

export default ModalComponent