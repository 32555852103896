import React, {useContext, useEffect, useState} from "react"
import {Field} from "redux-form";
import {FormattedMessage, injectIntl} from 'react-intl';
import {
    renderCheckbox,
    renderField,
    renderFreeField,
    renderRadio,
    renderSelect
} from "../../../../../common/utils/FormComponent";
import {withRouter} from "react-router";
import {getIntlMsg} from "../../../../../../constants/utils";
import {FileInput} from "../../../../../common/utils/FileInput";
import {useDispatch, useSelector} from "react-redux";
import actions from "../../../../../../thunks/settings/categoryThunks";
import {GlobalContext} from "../../../../../index";
import params from "../../../../../../constants/utils/params";
import TextEditorWYSIWYG from "../../../../../common/utils/RichTextEditorWYSIWYG";
import {validate} from "./validator";
import Skeleton from "react-loading-skeleton";
import moment from "moment";
import {canDisplay} from "../../../../../../constants/utils/security";

let renderSlides = injectIntl(props => {
    const {intl, fields, isAdd, touched, reset, status, item} = props


    const dispatch = useDispatch();
    const options = {options: ['categories']};

    const {languages} = params;


    const {lang, setLang, setModalChildren, showModal, hideModal} = useContext(GlobalContext);

    const categories = useSelector(state => state.categoryReducer.items);
    const formattedCats = [];
    categories.forEach(category => {
        formattedCats.push({code: category?.id, name: category?.entityType})
    })

    const [dirty, setDirty] = useState(false)


    const handleChangeLanguage = val => {
        setLang(val)
        setDirty(false)
    }

    const handleChangeDirty = val => {
        setDirty(true)
    }


    const resetStatistic = () => {
        hideModal('statistic')
        reset('statisticForm')
        dispatch(actions.resetItem({options: ['statistics']}))
    }
    if (status === "statSucceeded") resetStatistic()
    return (
        <div className={'row'}>
            <div className="col-md-12">
                <div className="card card-custom gutter-b card-stretch">
                    <div className="card-body">
                        <div className="d-flex">
                            <div className="flex-shrink-0">
                                <div
                                    className="symbol symbol-50 symbol-lg-120 symbol-circle symbol-light-primary">
                                    <div className="symbol symbol-circle symbol-lg-50">
                                        <img src={"/assets/media/avatars/male.png"}
                                        />
                                    </div>

                                </div>
                            </div>
                            <div className="flex-grow-1">
                                <div className="d-flex align-items-center justify-content-between flex-wrap mt-2">
                                    <div className="mr-3">
                                        {item?.fullName ? <a href="#"
                                                             className="d-flex align-items-center text-dark text-hover-primary font-size-h5 font-weight-bold pt-5">
                                                {item?.fullName} </a> :
                                            <Skeleton count={1} circle={true} width={100} delay={1}/>}
                                    </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-between flex-wrap mt-2">
                                    <div className="mr-3">
                                        <div className="d-flex flex-wrap my-2">
                                            {item?.email ? <span>
                                                  <span className="material-icons"
                                                        style={{fontSize: '14px'}}>mail</span>
                                                  <span className=""> {item?.email}</span>
                                          </span> :
                                                <Skeleton count={1} circle={true} width={100} delay={1}/>}

                                            {item?.phone ? <span className='ml-5'>
                                                  <span className="material-icons"
                                                        style={{fontSize: '14px'}}>phone</span>
                                                  <span className="pt-0"> {item?.phone}</span>
                                          </span> :
                                                <Skeleton count={1} circle={true} width={100} delay={1}/>}

                                            {item?.created ? <span className={'ml-5'}>
                                                  <span className="material-icons"
                                                        style={{fontSize: '14px'}}>schedule</span>
                                                  <span className=""> {item?.created}</span>
                                          </span> :
                                                <Skeleton count={1} circle={true} width={100} delay={1}/>}
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="separator separator-solid my-4"></div>

                        <div className="col-md-12">

                            {item?.message ? <span
                                className="font-weight-bold p-0"> <label>Message : </label><span
                                className="text-muted">
                      <div className={'overflow-ellipsis'}>
                         <p
                             dangerouslySetInnerHTML={{
                                 __html: item?.message
                             }} className={'overflow-ellipsis'}>
                          </p>
                </div>
                    </span>
                </span> : <Skeleton count={1} circle={true} width={100} delay={1}/>}
                        </div>


                    </div>
                </div>
            </div>
        </div>
    );
})
export default React.memo(withRouter(renderSlides))
