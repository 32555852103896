import React, {useEffect, useState} from "react";
import "react-toastify/dist/ReactToastify.css";
import {IntlProvider} from "react-intl";
import {ConnectedRouter} from "connected-react-router";
import {Switch} from "react-router-dom";
import messages from "../constants/messages";
import routes from '../constants/routes'
import PrivateRoute from "./common/routes/PrivateRoute";
import GlobalHomePage from "./pages/modules/obsheet/dashboard";
import HeaderMobile from "./layout/mobile";
import Aside from "./layout/aside";
import QuickModuleAction from "./layout/quickactions/module";
import QuickUserAction from "./layout/quickactions/user";
import QuickNotificationAction from "./layout/quickactions/notification";
import ChatBtn from "./layout/quickactions/chat/ChatBtn";
import QuickChatAction from "./layout/quickactions/chat";
import {useSelector} from "react-redux";
import SpecimenModal from "./pages/modules/request/forms/specimen";
import CreateResultForm from "./pages/modules/request/forms/result/create";
import UpdateResultModal from "./pages/modules/request/forms/result/update";
import TestIndicatorUploaderModal from "./pages/settings/refeTabs/indicator/modal/test";
import AccessComponent from '../components/layout/access'
import {useParams} from "react-router";


export const GlobalContext = React.createContext({});
const Layout = ({history}) => {


    console.log("===========MODULE================",history.location.pathname?.split('/')[2])


    const keycloak = useSelector(state => state.keycloak);
    const roles = keycloak?.tokenParsed?.resource_access?.website?.roles || [];
    const {name, email, gender, specimencenter, given_name, family_name} = keycloak?.tokenParsed;
    console.log("=+++++++===========",roles)

    const [modalIsOpen, setIsOpen] = React.useState({});

    const userSession = {keycloak, roles, name, email, gender, specimencenter, given_name, family_name}

    const showModal = (name) => {
        // alert(name)
        setIsOpen({open: true, name: name})
    }

    const hideModal = (name) => {
        setIsOpen({open: false, name: name})
    }


    const [modalChildren, setModalChildren] = useState(<h1>hello</h1>)


    const institution = {acronym: "zl", description: "Zanmi Lasante /PIH"}
    const gblParams = {institution, userSession};
    const [lang, setLang] = useState('fr');

    if (roles.length > 0) {
        return institution ? (
            <GlobalContext.Provider value={{
                ...gblParams,
                history,
                setLang,
                lang,
                modalIsOpen,
                showModal,
                hideModal,
                modalChildren,
                setModalChildren
            }}>
                <IntlProvider locale={'fr'} messages={messages['fr']}>
                    <ConnectedRouter history={history}>
                        <HeaderMobile/>
                        <div className="d-flex flex-column flex-root">
                            <div className="d-flex flex-row flex-column-fluid page">
                                <Aside history={history}/>
                                <Switch>
                                    <PrivateRoute exact path={`/`} component={GlobalHomePage}/>
                                    {routes.map((route, index) =>
                                        <PrivateRoute key={index} path={route.path} exact={route.exact}
                                                      component={route.main} roles={[['RealmAdmin']]}/>
                                    )}
                                </Switch>
                            </div>
                        </div>
                        <QuickModuleAction/>
                        <QuickUserAction/>
                        <QuickNotificationAction/>
                        {/*<QuickChatAction/>*/}
                        {/*<ChatBtn/>*/}

                        <SpecimenModal/>
                        <CreateResultForm/>
                        <UpdateResultModal/>
                        <TestIndicatorUploaderModal/>
                    </ConnectedRouter>

                </IntlProvider>
            </GlobalContext.Provider>
        ) : <div/>
    } else {
        return <AccessComponent/>
    }
}


export default React.memo(Layout);