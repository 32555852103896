import React from "react"
import {withRouter} from "react-router";
import {FormattedMessage, injectIntl} from "react-intl";
import ListOptions from "../../../../../../common/utils/ListOptions";

let Item = injectIntl(props => {

    const {id, title, description, entityName, status, entityType, setFormView,items} = props

    return (
        <tr data-row="0" className="datatable-row" style={{left: "0px"}}>

            <td className="datatable-cell">
                <span style={{width: "400px"}}><b>FR: </b>{title?.frContent || ''}</span>
                <span style={{width: "400px"}}><b>EN: </b>{title?.enContent || ''}</span>
                <span style={{width: "400px"}}><b>HT: </b>{title?.htContent || ''}</span>
            </td>

            <td className="datatable-cell">
                <span style={{width: "650px"}}><b>FR: </b>
                    <div dangerouslySetInnerHTML={{
                        __html: description?.frContent + "..."
                    }} style={{
                        textAlign: 'justify',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap'
                    }} className="mr-4"></div>
                </span>
                <span style={{width: "650px"}}><b>EN: </b>
                      <p dangerouslySetInnerHTML={{
                          __html: description?.enContent
                      }} style={{
                          textAlign: 'justify',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap'
                      }} className="mr-4"></p>
                </span>
                <span style={{width: "650px"}}><b>HT: </b>
                      <p dangerouslySetInnerHTML={{
                          __html: description?.htContent
                      }} style={{
                          textAlign: 'justify',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap'
                      }} className="mr-4"></p>
                </span>
            </td>

            <ListOptions {...{id, viewAction: setFormView, entities: 'helpfaq',item:items}}/>
        </tr>

    )
})
export default React.memo(withRouter(Item))
