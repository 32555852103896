import * as React from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {useParams, withRouter} from "react-router";
import {connect, useDispatch, useSelector} from "react-redux";
import useEntityManager from "../../../../common/hooks/useEntityManager";
import actions from "../../../../../thunks/common/coreThunks";
import {Company, CompanyMapper, Sliders} from '../../../../../constants/models/company'
import {useContext, useEffect} from "react";
import SubHeader from "../../../common/subheader";
import {getPatientValues} from "../../../../../constants/options/subheaderValues";
import ModalComponent from "./form/modalComponent";
import StatisticListComponent from './listing'
import FormComponent from "./form/formComponent";
import {GlobalContext} from "../../../../index";

const StatisticList = injectIntl((props) => {

    const {
        intl, setView, view
    } = props;

    const {page} = useParams();
    const fullNameStyle = {fontWeight: "bold"}
    const emailStyle = {display: "inline-block"}


    const dispatch = useDispatch();
    const {lang, setLang, setModalChildren, showModal} = useContext(GlobalContext);

    const options = {options: ['statistics']};

    useEffect(() => {
        dispatch(actions.loadItems(options));
    }, [dispatch]);


    const params = {options: [page]};
    const {handleLoadItems} = useEntityManager(params, page)


    const handleSetModal = () => {
        showModal('statistic')
        setModalChildren(<FormComponent/>);
        dispatch(actions.resetItem({options: ['statistics']}))
    }

    const EditStatistic = id => {
        const params = {intl, item: {id: id}, options: ['statistics']}
        dispatch(actions.loadItem(params))
        showModal('statistic')
        setModalChildren(<FormComponent/>);
    }


    return (
        <React.Fragment>
            <SubHeader {...{changeView: setView, view, btnOptions: getPatientValues}} noButton/>

            <div className="flex-column-fluid pt-5">
                <div className="container">
                    <div className="card card-custom">
                        <div className="card-body">

                            <div className={'row'}>
                                <div className={'col-sm-12'}>
                                    <button className="btn btn-outline-primary font-weight-bold ml-3  float-right"
                                            type="button" onClick={() => handleSetModal()}>
                                        Ajout de statistique
                                    </button>
                                </div>
                                <div className={'col-sm-12'}>
                                    <StatisticListComponent EditStatistic={EditStatistic}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalComponent/>
        </React.Fragment>

    );
});

export default StatisticList
