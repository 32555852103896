import * as React from "react";
import {useParams, withRouter} from "react-router";
import {FormattedMessage, injectIntl} from "react-intl";
import {useContext} from "react";
import {getRequestValues} from "../../../../../constants/options/subheaderValues";
import AsyncInputSearch from "../../../../common/utils/AsyncInputSearch";
import {getIntlMsg} from "../../../../../constants/utils";
import {canDisplay} from "../../../../../constants/utils/security";
import {GlobalContext} from "../../../../index";


const AdminSubHeader = injectIntl(props => {

    const {intl, items, handleSetModal} = props
    const {btnTitle, icon, paths, flatUrl, hasChildren} = getRequestValues;
    const {userSession: {roles}, institution: {acronym}} = useContext(GlobalContext);
    const {module, page} = useParams();

    const options = {options: [page]};

    console.log("===================((", btnTitle)

    const currentView = page === 'notification' ? 'notification-form' : page === 'reception' ? 'registration' : '';

    return (
        <div className="subheader py-6 py-lg-8 subheader-transparent" id="kt_subheader">
            <div
                className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">

                <div className="d-flex align-items-center flex-wrap mr-2">
                    <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">
                        <FormattedMessage id={`app.label.${page}_list`}/>
                    </h5>
                    <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-300"/>
                    <div className="d-flex align-items-center" id="kt_subheader_search">
                        <span className="text-dark-50 font-weight-bold" id="kt_subheader_total">
                            {items.length || 0}{" "}
                            <FormattedMessage id={`app.label.total`}/>
                        </span>
                        <form className="ml-5">
                            <div className="input-group input-group-sm bg-white border-0 rounded min-w-175px">
                                {<AsyncInputSearch {...{options}}/>}
                            </div>
                        </form>
                    </div>
                </div>

                {page === 'members' ?
                    canDisplay(roles, btnTitle) &&
                    <div className="d-flex align-items-center flex-wrap">
                        <div title={getIntlMsg(intl, `app.label.${btnTitle}_options`)}
                             data-placement="top">
                            <button
                                className="btn btn-fixed-height btn-primary font-weight-bolder font-size-sm px-5 my-1"
                                onClick={() => handleSetModal()}>
                                <span className="material-icons mr-2">{icon}</span>
                                Ajouter un nouveau membre
                            </button>
                        </div>
                    </div> : page === 'news' ? canDisplay(roles, btnTitle) &&
                        <div className="d-flex align-items-center flex-wrap">
                            <div title={getIntlMsg(intl, `app.label.${btnTitle}_options`)}
                                 data-placement="top">
                                <button
                                    className="btn btn-fixed-height btn-primary font-weight-bolder font-size-sm px-5 my-1"
                                    onClick={() => handleSetModal()}>
                                    <span className="material-icons mr-2">{icon}</span>
                                    Ajouter un nouveau Article
                                </button>
                            </div>
                        </div> : page === 'sectors' ? canDisplay(roles, btnTitle) &&
                        <div className="d-flex align-items-center flex-wrap">
                            <div title={getIntlMsg(intl, `app.label.${btnTitle}_options`)}
                                 data-placement="top">
                                <button
                                    className="btn btn-fixed-height btn-primary font-weight-bolder font-size-sm px-5 my-1"
                                    onClick={() => handleSetModal()}>
                                    <span className="material-icons mr-2">{icon}</span>
                                    Ajouter un nouveau Secteur
                                </button>
                            </div>
                        </div> : page === 'testimonials' ? canDisplay(roles, btnTitle) &&
                        <div className="d-flex align-items-center flex-wrap">
                            <div title={getIntlMsg(intl, `app.label.${btnTitle}_options`)}
                                 data-placement="top">
                                <button
                                    className="btn btn-fixed-height btn-primary font-weight-bolder font-size-sm px-5 my-1"
                                    onClick={() => handleSetModal()}>
                                    <span className="material-icons mr-2">{icon}</span>
                                    Ajouter un nouveau Témoignage
                                </button>
                            </div>
                        </div> : page === 'health-centers' ? canDisplay(roles, btnTitle) &&
                        <div className="d-flex align-items-center flex-wrap">
                            <div title={getIntlMsg(intl, `app.label.${btnTitle}_options`)}
                                 data-placement="top">
                                <button
                                    className="btn btn-fixed-height btn-primary font-weight-bolder font-size-sm px-5 my-1"
                                    onClick={() => handleSetModal()}>
                                    <span className="material-icons mr-2">{icon}</span>
                                    Ajouter un nouveau centre de santé
                                </button>
                            </div>
                        </div> : page === 'services' ? canDisplay(roles, btnTitle) &&
                        <div className="d-flex align-items-center flex-wrap">
                            <div title={getIntlMsg(intl, `app.label.${btnTitle}_options`)}
                                 data-placement="top">
                                <button
                                    className="btn btn-fixed-height btn-primary font-weight-bolder font-size-sm px-5 my-1"
                                    onClick={() => handleSetModal()}>
                                    <span className="material-icons mr-2">{icon}</span>
                                    Ajouter un nouveau service
                                </button>
                            </div>
                        </div> : page === 'donation' ? canDisplay(roles, btnTitle) &&
                        <div className="d-flex align-items-center flex-wrap">
                            <div title={getIntlMsg(intl, `app.label.${btnTitle}_options`)}
                                 data-placement="top">
                                <button
                                    className="btn btn-fixed-height btn-primary font-weight-bolder font-size-sm px-5 my-1"
                                    onClick={() => handleSetModal()}>
                                    <span className="material-icons mr-2">{icon}</span>
                                    Ajouter un impact
                                </button>
                            </div>
                        </div> : null
                }
            </div>
        </div>
    )
})

export default React.memo(withRouter(AdminSubHeader))

