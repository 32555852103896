import * as React from "react";
import useDocumentTitle from "../../../../common/hooks/useDocumentTitle";
import {getIntlMsg} from "../../../../../constants/utils";
import {injectIntl} from "react-intl";
import {Line} from 'react-chartjs-2';
import SubHeader from "../../../common/subheader";
import {getPatientValues} from "../../../../../constants/options/subheaderValues";
import {useParams} from "react-router";
import {useContext, useEffect} from "react";
import actions from "../../../../../thunks/settings/categoryThunks";
import {useDispatch, useSelector} from "react-redux";
import {GlobalContext} from "../../../../index";
import {Link} from "react-router-dom";

const state = {
    labels: ['10-01-2021', '11-01-2021', '12-01-2021', '13-01-2021', '18-01-2021', '20-01-2021', '22-01-2021', '24-01-2021', '26-01-2021', '30-01-2021', '01-02-2021', '02-02-2021', '02-02-2021', '04-02-2021', '05-02-2021'],
    datasets: [
        {
            label: 'nombre de test',
            fill: true,
            lineTension: 0.5,
            backgroundColor: 'rgb(231,120,46)',
            borderColor: 'rgba(0,0,0,1)',
            borderWidth: 2,
            data: [5, 6, 8, 20, 10, 20, 40, 20, 12, 3, 4, 30, 30, 14, 10]
        }
    ]
}


const ObsHomePage = injectIntl(props => {
    const {intl, stat} = props
    const {setView, view} = props;
    const {page, secondLevelPage} = useParams();
    useDocumentTitle(getIntlMsg(intl, `app.label.dashboard`));
    const dispatch = useDispatch();

    const options = {options: ['common', 'count-entries']};

    useEffect(() => {
        dispatch(actions.loadItems(options));
    }, [dispatch]);

    const {counts} = useSelector(state => ({
        counts: state.dbReducer?.db?.counts,
    }));
    const {institution: {acronym}} = useContext(GlobalContext);

    return (
        <React.Fragment>
            <SubHeader {...{btnOptions: getPatientValues}} noButton/>
            <div className="d-flex flex-column-fluid pt-2">
                <div className="container">
                    <div className="card">
                        <div className="card-body">
                            <div className="row pb-4">
                                <div className="col-lg-3 col-md-3 m-b-30">
                                    <div className="card m-b-30" style={{
                                        background: `rgba(68, 138, 255, 0.76)`,
                                        color: '#FFF'
                                    }}>
                                        <Link to={`/${acronym}/admin/health-centers`}>
                                            <div className="card-body">
                                                <div className="row p-t-20 p-b-20">
                                                    <div className="my-auto col-md-7">

                                                        <h1 className="m-0 text-white"> {counts && counts?.HealthCenter || 0}</h1>
                                                        <p className="m-0 text-white fw-600">Centre de santé</p>
                                                    </div>
                                                    <div
                                                        className="my-auto text-md-right col-md-5">
                                                        <a href="#"
                                                           className="btn btn-rounded-circle btn-lg btn-white text-danger">
                                            <span className="material-icons MuiIcon-root MuiIcon-fontSizeSmall"
                                                  aria-hidden="true" title="Chercher">local_hospital</span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>

                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 m-b-30">
                                    <div className="card m-b-30"
                                         style={{
                                             background: `rgba(68, 138, 255, 0.46)`,
                                             color: '#FFF'
                                         }}>
                                        <Link to={`/${acronym}/admin/news`}>
                                            <div className="card-body">
                                                <div className="row p-t-20 p-b-20">
                                                    <div className="my-auto col-md-7">

                                                        <h1 className="m-0 text-white"> {counts && counts?.News || 0}</h1>
                                                        <p className="m-0 text-white fw-600">Articles</p>
                                                    </div>
                                                    <div
                                                        className="my-auto text-md-right col-md-5">
                                                        <a href="#"
                                                           className="btn btn-rounded-circle btn-lg btn-white text-danger">
                                            <span className="material-icons MuiIcon-root MuiIcon-fontSizeSmall"
                                                  aria-hidden="true" title="Chercher">article</span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>

                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 m-b-30">
                                    <div className="card m-b-30"
                                         style={{
                                             background: `rgba(224, 64, 251, 0.51)`,
                                             color: '#FFF'
                                         }}>
                                        <div className="card-body">
                                            <Link to={`/${acronym}/admin/services`}>
                                                <div className="row p-t-20 p-b-20">
                                                    <div className="my-auto col-md-7">

                                                        <h1 className="m-0 text-white">{counts && counts?.Services || 0}</h1>
                                                        <p className="m-0 text-white fw-600">Service</p>
                                                    </div>
                                                    <div
                                                        className="my-auto text-md-right col-md-5">
                                                        <a href="#"
                                                           className="btn btn-rounded-circle btn-lg btn-white text-danger">
                                            <span className="material-icons MuiIcon-root MuiIcon-fontSizeSmall"
                                                  aria-hidden="true" title="Chercher">bookmarks</span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 m-b-30">
                                    <div className="card m-b-30"
                                         style={{
                                             background: `rgba(124, 77, 255, 0.68)`,
                                             color: '#FFF'
                                         }}>
                                        <div className="card-body">
                                            <Link to={`/${acronym}/admin/members`}>
                                                <div className="row p-t-20 p-b-20">
                                                    <div className="my-auto col-md-7">

                                                        <h1 className="m-0 text-white">{counts && counts?.Member || 0}</h1>
                                                        <p className="m-0 text-white fw-600">Membre</p>
                                                    </div>
                                                    <div
                                                        className="my-auto text-md-right col-md-5">
                                                        <a href="#"
                                                           className="btn btn-rounded-circle btn-lg btn-white text-danger">
                                            <span className="material-icons MuiIcon-root MuiIcon-fontSizeSmall"
                                                  aria-hidden="true" title="Chercher">people</span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-3 col-md-3 m-b-30">
                                    <div className="card m-b-30"
                                         style={{
                                             background: `rgba(64, 196, 255, 0.6)`,
                                             color: '#FFF'
                                         }}>
                                        <div className="card-body">
                                            <Link to={`/${acronym}/admin/testimonials`}>
                                                <div className="row p-t-20 p-b-20">
                                                    <div className="my-auto col-md-7">
                                                        <h1 className="m-0 text-white"> {counts && counts?.Testimonials || 0}</h1>
                                                        <p className="m-0 text-white fw-600"> Témoignage</p>
                                                    </div>
                                                    <div
                                                        className="my-auto text-md-right col-md-5">
                                                        <a href="#"
                                                           className="btn btn-rounded-circle btn-lg btn-white text-danger">
                                            <span className="material-icons MuiIcon-root MuiIcon-fontSizeSmall"
                                                  aria-hidden="true" title="Chercher">record_voice_over</span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 m-b-30">
                                    <div className="card m-b-30"
                                         style={{
                                             background: `rgba(64, 196, 255, 0.84)`,
                                             color: '#FFF'
                                         }}>
                                        <div className="card-body">
                                            <Link to={`/${acronym}/admin/sectors`}>
                                                <div className="row p-t-20 p-b-20">
                                                    <div className="my-auto col-md-7">
                                                        <h1 className="m-0 text-white"> {counts && counts?.Sectors || 0}</h1>
                                                        <p className="m-0 text-white fw-600"> Secteur</p>
                                                    </div>
                                                    <div
                                                        className="my-auto text-md-right col-md-5">
                                                        <a href="#"
                                                           className="btn btn-rounded-circle btn-lg btn-white text-danger">
                                            <span className="material-icons MuiIcon-root MuiIcon-fontSizeSmall"
                                                  aria-hidden="true" title="Chercher">format_list_numbered</span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </React.Fragment>
    );
});

export default React.memo(ObsHomePage)

