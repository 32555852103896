const categoryActionTypes = {

    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * RESET CATEGORY ACTION TYPES
     * -------------------------------------------
     * */
    RESET_CATEGORY: "RESET_CATEGORY",

    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * LOAD CATEGORIES ACTION TYPES
     * -------------------------------------------
     * */
    LOAD_CATEGORIES: "LOAD_CATEGORIES",
    LOAD_CATEGORIES_SUCCEEDED: "LOAD_CATEGORIES_SUCCEEDED",
    LOAD_CATEGORIES_FAILED: "LOAD_CATEGORIES_FAILED",

    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * LOAD _BY_ENTITY-NAME_CUSTOMER ACTION TYPES
     * -------------------------------------------
     * */
    LOAD_BY_NAME_CUSTOMER: "LOAD_BY_NAME_CUSTOMER",
    LOAD_BY_NAME_CUSTOMER_SUCCEEDED: "LOAD_BY_NAME_CUSTOMER_SUCCEEDED",
    LOAD_BY_NAME_CUSTOMER_FAILED: "LOAD_BY_NAME_CUSTOMER_FAILED",


    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * LOAD CATEGORIES_NAMES ACTION TYPES
     * -------------------------------------------
     * */
    LOAD_CATEGORIES_NAMES: "LOAD_CATEGORIES_NAMES",
    LOAD_CATEGORIES_NAMES_SUCCEEDED: "LOAD_CATEGORIES_NAMES_SUCCEEDED",
    LOAD_CATEGORIES_NAMES_FAILED: "LOAD_CATEGORIES_NAMES_FAILED",


    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * LOAD CATEGORIES_NAMES ACTION TYPES
     * -------------------------------------------
     * */
    LOAD_CATEGORIES_BY_NAME_CUSTOMER: "LOAD_CATEGORIES_BY_NAME_CUSTOMER",
    LOAD_CATEGORIES_BY_NAME_CUSTOMER_SUCCEEDED: "LOAD_CATEGORIES_BY_NAME_CUSTOMER_SUCCEEDED",
    LOAD_CATEGORIES_BY_NAME_CUSTOMER_FAILED: "LOAD_CATEGORIES_BY_NAME_CUSTOMER_FAILED",


    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * LOAD CATEGORIES_NAMES ACTION TYPES
     * -------------------------------------------
     * */
    LOAD_CATEGORIES_BY_NAME_SHEET: "LOAD_CATEGORIES_BY_NAME_SHEET",
    LOAD_CATEGORIES_BY_NAME_SHEET_SUCCEEDED: "LOAD_CATEGORIES_BY_NAME_SHEET_SUCCEEDED",
    LOAD_CATEGORIES_BY_NAME_SHEET_FAILED: "LOAD_CATEGORIES_BY_NAME_SHEET_FAILED",


    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * LOAD CATEGORIES_NAMES ACTION TYPES
     * -------------------------------------------
     * */
    LOAD_CATEGORIES_BY_NAME_SPECIMEN: "LOAD_CATEGORIES_BY_NAME_SPECIMEN",
    LOAD_CATEGORIES_BY_NAME_SPECIMEN_SUCCEEDED: "LOAD_CATEGORIES_BY_NAME_SPECIMEN_SUCCEEDED",
    LOAD_CATEGORIES_BY_NAME_SPECIMEN_FAILED: "LOAD_CATEGORIES_BY_NAME_SPECIMEN_FAILED",


    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * LOAD SINGLE CATEGORY ACTION TYPES
     * -------------------------------------------
     * */
    LOAD_CATEGORY: "LOAD_CATEGORY",
    LOAD_CATEGORY_SUCCEEDED: "LOAD_CATEGORY_SUCCEEDED",
    LOAD_CATEGORY_FAILED: "LOAD_CATEGORY_FAILED",


    LOAD_CATEGORIES_ENTITY_TYPE_LOGO_SUCCEEDED: "LOAD_CATEGORIES_ENTITY_TYPE_LOGO_SUCCEEDED",


    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * SAVE CATEGORY ACTION TYPES
     * -------------------------------------------
     * */
    SAVE_CATEGORY: "SAVE_CATEGORY",
    SAVE_CATEGORY_SUCCEEDED: "SAVE_CATEGORY_SUCCEEDED",
    SAVE_CATEGORY_FAILED: "SAVE_CATEGORY_FAILED",


    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * UPDATE CATEGORY ACTION TYPES
     * -------------------------------------------
     * */
    UPDATE_CATEGORY: "UPDATE_CATEGORY",
    UPDATE_CATEGORY_SUCCEEDED: "UPDATE_CATEGORY_SUCCEEDED",
    UPDATE_CATEGORY_FAILED: "UPDATE_CATEGORY_FAILED",


    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * DELETE CATEGORY ACTION TYPES
     * -------------------------------------------
     * */
    DELETE_CATEGORY: "DELETE_CATEGORY",
    DELETE_CATEGORY_SUCCEEDED: "DELETE_CATEGORY_SUCCEEDED",
    DELETE_CATEGORY_FAILED: "DELETE_CATEGORY_FAILED",

    LOAD_CATEGORIES_BY_NAME_SYMPTOM: "LOAD_CATEGORIES_BY_NAME_SYMPTOM",
    LOAD_CATEGORIES_BY_NAME_SYMPTOM_SUCCEEDED: "LOAD_CATEGORIES_BY_NAME_SYMPTOM_SUCCEEDED",
    LOAD_CATEGORIES_BY_NAME_SYMPTOM_FAILED: "LOAD_CATEGORIES_BY_NAME_SYMPTOM_FAILED",

    LOAD_CATEGORIES_BY_NAME_COMORBIDITY: "LOAD_CATEGORIES_BY_NAME_COMORBIDITY",
    LOAD_CATEGORIES_BY_NAME_COMORBIDITY_SUCCEEDED: "LOAD_CATEGORIES_BY_NAME_COMORBIDITY_SUCCEEDED",
    LOAD_CATEGORIES_BY_NAME_COMORBIDITY_FAILED: "LOAD_CATEGORIES_BY_NAME_COMORBIDITY_FAILED",


    LOAD_CATEGORIES_BY_NAME_RISK: "LOAD_CATEGORIES_BY_NAME_RISK",
    LOAD_CATEGORIES_BY_NAME_RISK_SUCCEEDED: "LOAD_CATEGORIES_BY_NAME_RISK_SUCCEEDED",
    LOAD_CATEGORIES_BY_NAME_RISK_FAILED: "LOAD_CATEGORIES_BY_NAME_RISK_FAILED",
    LOAD_CATEGORIES_ENTITY_TYPE_RECRUITMENT_SUCCEEDED: "LOAD_CATEGORIES_ENTITY_TYPE_RECRUITMENT_SUCCEEDED",



};


export default categoryActionTypes;
