import * as React from "react";
import useDocumentTitle from "../../../../../common/hooks/useDocumentTitle";
import {getIntlMsg, nameAcronym} from "../../../../../../constants/utils";
import {FormattedMessage, injectIntl} from "react-intl";
import {useParams, withRouter} from "react-router";
import {connect, useDispatch, useSelector} from "react-redux";
import useEntityManager from "../../../../../common/hooks/useEntityManager";
import actions from "../../../../../../thunks/common/coreThunks";
import {Field, reduxForm, FieldArray, reset} from "redux-form";
import {validate} from "../validator";
import {
    Company,
    CompanyMapper,
    About,
    AboutMapper,
    VisionMapper,
    Vision, Mission, MissionMapper, Origin, OriginMapper, HelpFaqs, HelphFaqsMapper
} from '../../../../../../constants/models/company'
import {useContext, useEffect, useState} from "react";
import FaqList from './listing'
import CategoryForm from "../../../../settings/refeTabs/category/form";
import CategoryList from "../../../../settings/refeTabs/category/listing";
import Slider from "../../slider/form/sliderComponent";
import {GlobalContext} from "../../../../../index";
import ModalComponent from "./form/modalComponent";
import Formcomponent from './form/formComponent'


const AboutForm = injectIntl((props) => {

    const {
        intl,
    } = props;
    const {page} = useParams();

    const [formView, setFormView] = useState(false)

    const {lang, setLang, setModalChildren, showModal,hideModal} = useContext(GlobalContext);
    const dispatch = useDispatch();

    const params = {options: [page]};


    const {item, loading, helpItem,status} = useSelector(state => ({
        loading: state.companyReducer.loadingItem,
        item: state.companyReducer.item,
        helpItem: state.companyReducer.helpItem,
        status: state.companyReducer.status,
    }));


    const removeHelp = () => {
        dispatch(actions.resetItem({options: ['helpfaq']}))
        hideModal('helpfaq')
    }

    const handleSetModal = () => {
        showModal('helpfaq')
        setModalChildren(<Formcomponent/>);
        dispatch(actions.resetItem({options: ['helpfaq']}))
    }

    const EditFaq = id => {
        const params = {intl, item: {id: id}, options: ['company','helpfaq']}
        dispatch(actions.loadItem(params))
        showModal('helpfaq')
        setModalChildren(<Formcomponent/>);
    }

    if (status === 'success') removeHelp()
    return (
        <React.Fragment>
        <div className="tab-pane fade pt-0" id="help_faqs" role="tabpanel" aria-labelledby="help_faqs">
            <div className="card card-custom gutter-b">
                <div className="card-body">
                    <div className="">
                        <div className={'row'}>
                            <div className='col-md-12'>
                                <button className="btn btn-outline-primary font-weight-bold ml-3  float-right"
                                        type="button" onClick={() => handleSetModal()}>
                                    Ajouter
                                </button>
                            </div>
                            <FaqList {...{formView, EditFaq}}/>
                        </div>


                    </div>

                </div>
            </div>
        </div>
            <ModalComponent/>
        </React.Fragment>

    );
});

const mapStateToProps = ({companyReducer: {helpItem}}) => {

    const defaultState = {
        langHelp: 'fr',
        type: 'HELP',
        frDesc: " ",
        enDesc: " ",
        htDesc: " ",
    }

    const initVal = helpItem ? new HelphFaqsMapper(helpItem) : null;
    return {
        initialValues: helpItem !== null ? {...initVal, langHelp: 'fr'} : {...defaultState}
    }
};

export default React.memo(withRouter(connect(mapStateToProps,)(reduxForm({
    form: "helpForm",
    enableReinitialize: true,
    validate,
})(AboutForm))));


