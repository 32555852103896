import React, {useContext} from 'react';
import {compose, withHandlers, withState, withPropsOnChange} from 'recompose';
import {EditorState, ContentState, convertToRaw} from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import {Editor} from 'react-draft-wysiwyg';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {GlobalContext} from "../../index";
import {FormattedMessage} from "react-intl";


const RichTextEditorWYSIWYG =
    ({
         input: {
             value,
             onChange,
         },
         meta: {touched, error},
         editorState,
         onEditorStateChange,
         lang
     }) => {

        return (
            <div className="WysiwygEditor"
                 style={{width: '100%'}}>
                {
                    editorState && <>
                        <Editor
                            key={lang}
                            style={{width: '100%'}}
                            editorState={editorState}
                            onEditorStateChange={onEditorStateChange}
                            lang={lang}
                            editorClassName="editor"
                            toolbarClassName="toolbar"

                        />

                    </>
                }

            </div>
        );
    }


export default compose(
withState('editorState', 'setEditorState', EditorState.createEmpty()),
withPropsOnChange(
['input'],
(
{
    input: {
        value,
    }
,
    meta: {
        dirty,
    }
,
    setEditorState,
}
) =>
{
    if (dirty) {
        return;
    }
    if (!value) {
        return;
    }

    const contentBlock = htmlToDraft(value);
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    const editorState = EditorState.createWithContent(contentState);
    setEditorState(editorState);
}
,
),
withHandlers(
{
    onEditorStateChange: ({
                              input: {
                                  onChange,
                              },
                              meta: {
                                  dirty = true,
                              },
                              setEditorState,
                          }) => (editorState) => {
        setEditorState(editorState);
        const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        onChange(html);
    },

}
),
)(RichTextEditorWYSIWYG);