import * as React from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {useParams, withRouter} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import Item from "./Item";
import SearchBar from "./SearchBar";
import {useContext, useEffect} from "react";
import Spinner from "../../../../../../common/utils/Spinner";
import TablePager from "../../../../../common/pagination/TablePager";
import params from "../../../../../../../constants/utils/params";
import {GlobalContext} from "../../../../../../index";
import MaterialTable from 'material-table';
import {getIntlMsg, Localization} from "../../../../../../../constants/utils";
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";
import actions from "../../../../../../../thunks/common/coreThunks";
import swal from "sweetalert";
import {canDisplay} from "../../../../../../../constants/utils/security";

const HelpList = injectIntl((props) => {

        const dispatch = useDispatch();
        const {intl, formView, EditFaq} = props;
        const {page, id} = useParams();
        const options = {options: ['categories']};

        const {loading, items, item} = useSelector(state => ({
            loading: state.companyReducer.loadingItem,
            items: state.companyReducer?.item?.helps,
            item: state.companyReducer.item,
        }));


        console.log("=========HELLOOOO=======================?", items)

        const {languages} = params;
        const {lang, setLang} = useContext(GlobalContext);

        const columns = [
            {
                title: "Titre",
                type: 'text',
                render: rowData => (<span>{rowData.title.frContent}</span>),
            },
            {
                title: "Description",
                render: rowData => (<div className={'overflow-ellipsis'}>
                    <p
                        dangerouslySetInnerHTML={{
                            __html: rowData?.description?.frContent
                        }}>
                    </p>
                </div>),
            },
            {
                title: "Type",
                field: 'type'
            },
            {
                title: "Status",
                render: rowData => rowData.status ? <span className="text-success">publié</span> :
                    <span className="text-danger">Non publié</span>
            },
        ]

        const handlePublishUnPublish = (id, status) => {

            swal({
                title: "voulez-vous vraiment",
                text: status === "publish" ? "le Publier" : "ne le pas Publier",
                icon: "warning",
                buttons: ["Non", "Oui"],
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        dispatch(actions.saveOrUpdateItem({
                            options: ['common', 'help_faq'],
                            intl,
                            action: '',
                            item: {id: id},
                        }))
                    } else {
                    }
                });
        }
        const {userSession: {roles}} = useContext(GlobalContext);

        return (
            <div
                className="col-md-12 pt-4" style={{}}>

                {items && items.length > 0 ?
                    <MaterialTable
                        className="col-md-12"
                        title="Liste des Aides et Faqs"
                        columns={columns}
                        data={items ? items : []}
                        icon='unfold_less'
                        localization={Localization('fr')}
                        options={{
                            headerStyle: {
                                backgroundColor: '#ff7f26',
                                color: '#FFF',
                                fontFamily: 'Ki',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                fontVariant: 'normal',
                                lineHeight: 1,
                                fontSize: '15px'
                            },
                            actionsColumnIndex: -1,
                            exportButton: true,
                            exportAllData: true,
                            pageSize: items?.length,
                            pageSizeOptions: [10, 20, 25, 50, 100],

                        }}
                        actions={[{
                            icon: row => (
                                <span className="material-icons">edit</span>
                            ),
                            tooltip: 'Edit',
                            onClick: (e, row) => {
                                EditFaq(row.id)
                            }
                        }, {
                            icon: row => {
                                console.log("+_=_=)=", row)
                                return (
                                    canDisplay(roles, 'publish') && <a href="#"
                                                                       className="btn btn-light-primary font-weight-bolder font-size-sm m-0 p-2 ml-3">
                                        <i className="material-icons font-size-sm p-0">publish</i>
                                    </a>
                                )
                            },
                            tooltip: 'Publier',
                            onClick: (e, row) => {

                                if (row.status) {
                                    handlePublishUnPublish(row.id, 'unpublish')
                                } else {
                                    handlePublishUnPublish(row.id, 'publish')
                                }
                            }
                        }]
                        }

                    /> :
                    <SkeletonTheme color={'rgb(184,224,245)'}>
                        <p>
                            <Skeleton count={3} delay={1}/>
                        </p>
                    </SkeletonTheme>}
            </div>
        );
    }
)

export default React.memo(withRouter(HelpList))

