const singleParams = {
    item: null,
    loadingItem: false,
    status: null
}

const counterParams = {
    counter: 0,
    loading: false,
}

const help = {
    loadItemHelp: false,
    helpItem: null,
    status: null,
    logoItem: null
}

const lovParams = {
    lovItems: [],
    loadingLov: false,
}

const dropDownParams = {
    dropDownItems: [],
    loadingDropDown: false,
}

const baseParams = {
    ...singleParams,
    items: [],
    loadingItems: false,
    pageParam: null
}

const moduleParams = {
    ...baseParams,
    firstItem: null,
    deleting: false,
    savingOrUpdating: false,
};

const otherSettingParams = {
    setting: null,
    loadingSetting: false,
}

const currency = {
    loadingCurrency: false,
    currency: null
}
const cartSummary = {
    loadingCartSummary: false,
    cartSummary: []
}
const db = {
    counts: null
}

const member = {
    memberItem: null,
    loadingMember: false,
    members: null,
    memberCategory: [],
    loadingMemberCategory: false,
    status: ''
}

const news = {
    newsItem: null,
    loadingNews: false,
    news: null,
    newsCategory: [],
    loadingNewsCategory: false,
    locale: null,
    testimonialItem: null,
    healthcenters: [],
    healthcenterItem: null,
    status: null
}

const slider = {
    sliderItem: null,
    status: null,
    statistics: [],
    statisticItem: null
}


export default {

    coreReducer: {
        locale: "fr",
        currency: "gds",
        country: "ht"
    },
    proxyReducer: {},


    patientReducer: {...moduleParams, ...counterParams},
    companyReducer: {...moduleParams, ...counterParams, ...help},
    adminReducer: {...moduleParams, ...counterParams, ...member, ...news},
    componentReducer: {...moduleParams, ...slider},
    requestReducer: {...moduleParams, ...counterParams, ...currency, ...cartSummary},
    resultReducer: {...moduleParams, ...counterParams},
    currencyReducer: {...moduleParams, ...counterParams},
    institutionReducer: {...moduleParams, ...counterParams},
    itemPriceReducer: {...moduleParams, ...counterParams},
    testIndicatorReducer: {...moduleParams, ...counterParams},
    spCenterReducer: {...moduleParams, ...counterParams},
    settingsReducer: {...moduleParams, ...counterParams, ...otherSettingParams},
    dbReducer: {
        request: {...moduleParams},
        security: {...moduleParams},
        financial: {...moduleParams},
        leadership: {...moduleParams},
        indicator: {...moduleParams},
        db: {...db}
    },

    categoryReducer: {
        ...moduleParams, ...lovParams, ...dropDownParams,
        customerTypes: [],
        loadingCustomerTypes: false,
        specimenTypes: [],
        loadingSpecimenTypes: false,
        documentTypes: [],
        symptoms: [],
        cormorbidities: [],
        recruitmentCategory: [],
        risks: [],
        loadingDocumentTypes: false
    },

    observationReducer: {...moduleParams},


};
