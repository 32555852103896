import logo from "../../components/pages/modules/components/institution/generaly/logo";

export class CompanyLogo {

    constructor(params) {
        if (params) {

            const {
                fileLogo,
                type
            } = params;

            this.file = fileLogo?.fileName ? fileLogo : null
            this.type = {id: type}

        }
    }
}

export class CompanyLogoMapper {

    constructor(params) {
        if (params) {

            const {
                fileName,
                type,

            } = params;

            this.fileLogo = fileName
            this.type = type?.id

        }
    }
}

export class Company {

    constructor(params) {
        if (params) {

            const {
                addresses,
                emails,
                copyright,
                phones,
                socialLinks,
                frdescription,
                endescription,
                htdescription,
                frname,
                enname,
                idDescription,
                idName,
                htname,
                logos
            } = params;

            this.addresses = arrayMapperAddress(addresses).join(';')
            this.copyright = copyright;
            // this.logos = logos.map(item => (
            //     {file: item?.file?.fileName ? item?.file : null, type: {id: item?.type}}))

            this.description = {
                "enContent": endescription,
                "frContent": frdescription,
                "htContent": htdescription,
                id: idDescription
            }
            this.emails = arrayMapperEmail(emails).join(';')
            this.name = {
                "enContent": enname,
                "frContent": frname,
                "htContent": htname,
                id: idName
            }
            this.phones = arrayMapperPhone(phones).join(';')
            this.socialLinks = socialLinks.map(v => ({
                link: v.socialLink,
                type: v.type
            }))
        }
    }
}


export class CompanyMapper {

    constructor(params) {
        if (params) {

            const {
                addresses,
                emails,
                copyright,
                phones,
                socialLinks,
                name,
                logos,
                description
            } = params;

            this.addresses = addresses ? addresses.map(v => (
                {address: v}
            )) : []
            this.copyright = copyright;
            this.logos = logos ? logos.map(v => (
                {type: v.type.id, file: v.fileName}
            )) : []

            this.endescription = description?.enContent;
            this.frdescription = description?.frContent;
            this.htdescription = description?.htContent;

            this.emails = emails ? emails.map(v => (
                {email: v}
            )) : []
            this.enname = name?.enContent
            this.frname = name?.frContent
            this.htname = name?.htContent

            this.phones = phones ? phones.map(v => (
                {phone: v}
            )) : []
            this.socialLinks = socialLinks ? socialLinks.map(v => (
                {socialLink: v.link, type: v.type}
            )) : []
        }
    }
}


export class About {

    constructor(params) {
        if (params) {

            const {
                frabout,
                enabout,
                htabout,
            } = params;

            this.enContent = enabout;
            this.frContent = frabout;
            this.htContent = htabout;
            this.status = true;

        }
    }
}


export class AboutMapper {

    constructor(params) {
        if (params) {

            const {
                enContent,
                frContent,
                htContent,
            } = params;

            this.enabout = enContent;
            this.frabout = frContent;
            this.htabout = htContent;


        }
    }
}

export class Vision {

    constructor(params) {
        if (params) {

            const {
                frVision,
                enVision,
                htVision,
            } = params;

            this.enContent = enVision;
            this.frContent = frVision;
            this.htContent = htVision;
            this.status = true;

        }
    }
}

export class VisionMapper {

    constructor(params) {
        if (params) {

            const {
                enContent,
                frContent,
                htContent,
            } = params;

            this.enVision = enContent;
            this.frVision = frContent;
            this.htVision = htContent;


        }
    }
}


export class Mission {

    constructor(params) {
        if (params) {

            const {
                frMission,
                enMission,
                htMission,
            } = params;

            this.enContent = enMission;
            this.frContent = frMission;
            this.htContent = htMission;
            this.status = true;

        }
    }
}

export class MissionMapper {

    constructor(params) {
        if (params) {

            const {
                enContent,
                frContent,
                htContent,
            } = params;

            this.enMission = enContent;
            this.frMission = frContent;
            this.htMission = htContent;


        }
    }
}


export class Origin {

    constructor(params) {
        if (params) {

            const {
                enOrigin,
                frOrigin,
                htOrigin,
            } = params;

            this.enContent = enOrigin;
            this.frContent = frOrigin;
            this.htContent = htOrigin;
            this.status = true;

        }
    }
}

export class OriginMapper {

    constructor(params) {
        if (params) {

            const {
                enContent,
                frContent,
                htContent,
            } = params;

            this.enOrigin = enContent;
            this.frOrigin = frContent;
            this.htOrigin = htContent;


        }
    }
}

export class HelpFaqs {

    constructor(params) {
        if (params) {

            const {
                enTitle,
                frTitle,
                htTitle,
                enDesc,
                frDesc,
                htDesc,
                idDescription,
                idTitle,
                type
            } = params;


            this.title = {
                htContent: htTitle,
                frContent: frTitle,
                enContent: enTitle,
                id: idTitle ? idTitle : null
            }
            this.description = {
                htContent: htDesc,
                frContent: frDesc,
                enContent: enDesc,
                id: idDescription ? idDescription : null
            }
            this.type = type;


        }
    }
}

export class HelphFaqsMapper {

    constructor(params) {
        if (params) {

            const {
                title,
                description,
                type,
            } = params;

            this.enTitle = title?.enContent;
            this.frTitle = title?.frContent;
            this.htTitle = title?.htContent;

            this.enDesc = description?.enContent;
            this.frDesc = description?.frContent;
            this.htDesc = description?.htContent;
            this.type = type;


        }
    }
}


export class Sliders {

    constructor(params) {
        if (params) {

            const {
                frdescription,
                endescription,
                htdescription,
                frname,
                enname,
                idDescription,
                idName,
                htname,
                slides
            } = params;


            this.description = {
                "enContent": endescription,
                "frContent": frdescription,
                "htContent": htdescription,
                id: idDescription
            }

            this.name = {
                "enContent": enname,
                "frContent": frname,
                "htContent": htname,
                id: idName
            }

            this.slides = slides.map(item => (
                {
                    file: item?.file,
                    description: {
                        frContent: item.frdescription,
                        enContent: item.endescription,
                        htContent: item.htdescription,
                    },
                    name: {
                        frContent: item.frname,
                        enContent: item.enname,
                        htContent: item.htname,
                    }
                }))
            this.pageName = 'HOME'


        }
    }
}

const arrayMapperAddress = (arrays) => {
    let str = []
    if (arrays) {
        for (const [key, value] of Object.entries(arrays)) {
            str.push(value?.address);
        }
    }
    return str;
}

const arrayMapperPhone = (arrays) => {
    let str = []
    if (arrays) {
        for (const [key, value] of Object.entries(arrays)) {
            str.push(value?.phone);
        }
    }
    return str;
}

const arrayMapperSocialLink = (arrays) => {
    let str = []
    if (arrays) {
        for (const [key, value] of Object.entries(arrays)) {
            str.push(value?.socialLink);
        }
    }
    return str;
}

const arrayMapperEmail = (arrays) => {
    let str = []
    if (arrays) {
        for (const [key, value] of Object.entries(arrays)) {
            str.push(value?.email);
        }
    }
    return str;
}