import React, {useContext} from "react";
import {injectIntl} from "react-intl";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
import {canDisplay} from "../../../../../../constants/utils/security";
import {GlobalContext} from "../../../../../index";


const Item = injectIntl(props => {
    const {item, EditMember, DeleteMember, handlePublishUnPublish} = props
    const {userSession: {roles}} = useContext(GlobalContext);
    return (
        <div className="col-xxl-4 col-xl-6 col-md-6 col-sm-6">
            <div className="card card-custom gutter-b card-stretch">
                <div className="card-body">
                    <div className="d-flex">
                        <div className="flex-shrink-0 mr-7">
                            <div
                                className="symbol symbol-50 symbol-lg-120 symbol-circle symbol-light-primary">
                                <div className="symbol symbol-circle symbol-lg-75">
                                    {item?.fileName ?
                                        <img src={item ? item?.fileName : "/assets/media/logos/camera.jpg"}
                                        /> : <Skeleton count={1} circle={true} height={50} width={50} delay={1}/>}
                                </div>

                            </div>
                        </div>
                        <div className="flex-grow-1">
                            <div className="d-flex align-items-center justify-content-between flex-wrap mt-2">
                                <div className="mr-3">
                                    {item?.firstname ? <a href="#"
                                                          className="d-flex align-items-center text-dark text-hover-primary font-size-h5 font-weight-bold pt-5">
                                            {item?.firstname?.toUpperCase()} {', '} {item?.lastname} </a> :
                                        <Skeleton count={1} circle={true} width={100} delay={1}/>}
                                </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-between flex-wrap mt-2">
                                <div className="mr-3">
                                    <div className="d-flex flex-wrap my-2">
                                        {item?.category?.name?.frContent ? <span
                                                className="font-weight-bold">
                                               <label className="text-bold">Catégorie : <span
                                                   className="text-muted"> {item?.category?.name?.frContent}</span></label>
                                          </span> :
                                            <Skeleton count={1} circle={true} width={100} delay={1}/>}
                                    </div>
                                    <div className="d-flex flex-wrap my-2">
                                        {item?.position ? <span
                                                className="font-weight-bold">
                                               <label className="text-bold">Position : <span
                                                   className="text-muted"> {item?.position?.frContent}</span></label>
                                          </span> :
                                            <Skeleton count={1} circle={true} width={100} delay={1}/>}
                                    </div>
                                    <div className="d-flex flex-wrap my-2">
                                        {item?.created ? <span
                                                className="font-weight-bold">
                                               <label className="text-bold">Date création : <span
                                                   className="text-muted"> {item ? moment(item?.created).format('DD-MM-YYYY') : null}</span></label>
                                          </span> :
                                            <Skeleton count={1} circle={true} width={100} delay={1}/>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="separator separator-solid my-4"></div>

                    <div className="d-flex align-items-center flex-wrap">
                        <div className="d-flex align-items-center flex-lg-fill mr-5 my-1">
                            <div className="my-lg-0 my-1">
                                {item && canDisplay(roles, 'update') ?
                                    <a className="btn btn-primary font-weight-bolder font-size-sm mr-2 p-2"
                                       onClick={() => EditMember(item)}>
                                        <i className="material-icons font-size-sm p-0">visibility</i>
                                    </a> : canDisplay(roles, 'update') &&
                                    <Skeleton count={1} circle={true} width={30} height={30} delay={1}
                                              className={'mr-1'}/>}

                                {item && canDisplay(roles, 'delete') ? <a
                                                                          className="btn btn-light-primary font-weight-bolder font-size-sm m-0 p-2"
                                                                          onClick={() => DeleteMember(item)}>
                                    <i className="material-icons font-size-sm p-0">delete</i>
                                </a> : canDisplay(roles, 'delete') &&
                                    <Skeleton count={1} circle={true} width={30} height={30} delay={1}
                                              className={'mr-1'}/>}
                                {!item?.status ? <>
                                        {item && canDisplay(roles, 'publish') ? <a
                                                                                   className="btn btn-light-primary font-weight-bolder font-size-sm m-0 p-2 ml-3"
                                                                                   onClick={() => handlePublishUnPublish(item, 'publish')}>
                                            <i className="material-icons font-size-sm p-0">unpublished</i>
                                        </a> : canDisplay(roles, 'publish') &&
                                            <Skeleton count={1} circle={true} width={30} height={30} delay={1}
                                                      className={'mr-1'}/>}</> :
                                    <>{item && canDisplay(roles, 'publish') ? <a
                                                                                 className="btn btn-light-primary font-weight-bolder font-size-sm m-0 p-2 ml-3"
                                                                                 onClick={() => handlePublishUnPublish(item, 'unpublish')}>
                                        <i className="material-icons font-size-sm p-0">publish</i>
                                    </a> : canDisplay(roles, 'publish') &&
                                        <Skeleton count={1} circle={true} width={30} height={30} delay={1}
                                                  className={'mr-1'}/>}</>}
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
})

export default Item