import * as React from "react";
import {useParams, withRouter} from "react-router";
import pages from "../../../../constants/options/pages";
import News from "./news/list";
import Sliders from "../components/slider";
import Member from "./member/list";
import Sectors from "./sectors/list";
import Testimonials from "./testimonials/list";
import HealhCenter from "./healthcenter/list";
import Services from "./services/list";
import Messages from "./messages";
import Staffing from "./staffing";
import Research from "./research";
import Donate from "./donate/list";
import Donations from "./donations";

export const ComponentsManager = () => {
    const {page} = useParams()
    console.log("==========PAGE==============", page)

    return _onLoadPage(page);
}

function _onLoadPage(page) {
    switch (page) {
        case pages.COMPONENT_NEWS_PAGE:
            return <News/>;
        case pages.COMPONENT_MEMBER_PAGE:
            return <Member/>;
        case pages.COMPONENT_SECTORS_PAGE:
            return <Sectors/>;
        case pages.COMPONENT_SLIDE_PAGE:
            return <Sliders/>;
        case pages.COMPONENT_TESTIMONIALS_PAGE:
            return <Testimonials/>;
        case pages.COMPONENT_HEALH_CENTER_PAGE:
            return <HealhCenter/>;
        case pages.COMPONENT_SERVICES_PAGE:
            return <Services/>;
        case pages.COMPONENT_MESSAGES_PAGE:
            return <Messages/>;
        case pages.COMPONENT_STAFFING_PAGE:
            return <Staffing/>;
            case pages.COMPONENT_RESEARCH_PAGE:
            return <Research/>;
            case pages.COMPONENT_DONATION_PAGE:
            return <Donate/>;
            case pages.COMPONENT_DONATE_PAGE:
            return <Donations/>;
        default: {
            return (<h1>hello MOTO</h1>)
        }
    }


}


export default React.memo(withRouter(ComponentsManager));