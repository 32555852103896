import * as validator from "../../../../../../constants/utils/validator";


export const validate = values => {
    const errors = {};
    errors.memberFile=values.memberFile === null;
    errors.category=values.category === null;
    errors.grade=values.grade === null;
    errors.firstName = validator.validateString('firstName', values.firstName, 3, 200, true);
    errors.lastName = validator.validateString('lastName', values.lastName, 3, 200, true);
    errors.position = validator.validateString('position', values.position, 3, 10000, true);

    return errors
};


