import * as validator from "../../../../../../constants/utils/validator";


export const validate = values => {
    const errors = {};
    // errors.symptomsStartDate=values.symptomsStartDate === undefined;
    errors.symptomsStartDate = validator.validateString('symptomsStartDate', values.symptomsStartDate, 3, 150, true);
    errors.frname = validator.validateString('frname', values.frname, 3, 150, true);
    errors.pageName = validator.validateString('pageName', values.pageName, 3, 150, true);
    errors.frdescription = validator.validateString('frdescription', values.frdescription, 10, 1000, true);
    // errors.frdES= validator.validateString('frdescription', values.frdescription, 10, 1000, true);

    // if (!values.slides || !values.slides.length) {
    //     errors.slides = {_error: 'At least one member must be entered'}
    // } else {
    //     const slidesArrayErrors = [];
    //     values.slides.forEach((slide, slideIndex) => {
    //         const slideErrors = {}
    //         if (!slide || !slide.frName) {
    //             slideErrors.frName = 'app.label.required'
    //             slidesArrayErrors[slideIndex] = slideErrors
    //         }
    //         if (!slide || !slide.frDesc) {
    //             slideErrors.frDesc = 'app.label.required'
    //             slidesArrayErrors[slideIndex] = slideErrors
    //         }
    //     });
    //     if (slidesArrayErrors.length) {
    //         console.log("=++++++++++=======", slidesArrayErrors)
    //         errors.slides = slidesArrayErrors
    //     }
    // }

    return errors
};


