import * as React from "react";
import {injectIntl} from "react-intl";
import {withRouter} from "react-router";
import {useContext} from "react";
import {GlobalContext} from "../../../index";


const SecondarySetting = injectIntl((props) => {
    const {intl, tab, history} = props

    const path = history?.location?.pathname?.split('/')[2];

    const {institution: {acronym}} = useContext(GlobalContext);

    return (
        <div className={`tab-pane fade`} id="settings" key={4}>
            <h1>Settings</h1>
        </div>

    );
})

export default React.memo(withRouter(SecondarySetting))

