import React, {useContext, useEffect} from "react"
import {Field} from "redux-form";
import {FormattedMessage} from 'react-intl';
import {renderField} from "../../../../../common/utils/FormComponent";
import {withRouter} from "react-router";
import params from "../../../../../../constants/utils/params";
import {GlobalContext} from "../../../../../../components/index"
import actions from "../../../../../../thunks/settings/categoryThunks";
import {useDispatch, useSelector} from "react-redux";

let FormItems = ({item}) => {
    const validate = {}
    const {languages} = params;
    const {lang, setLang} = useContext(GlobalContext);


    return (
        <div className={`custom_fieldset`}>
			<div className="row pb-5">
            {lang === 'fr' ? <div className="label label-light-primary label-pill label-inline col-md-12 p-6"><span className="">Francais</span></div> :
                lang === 'en' ? <div className="label label-light-info label-pill label-inline col-md-12 p-6"><span className="">Anglais</span></div> :
					<div className="label label-light-danger label-pill label-inline col-md-12 p-6"><span className="">Kreyol</span></div> }
			</div>

            <div className="row pb-5">
                <div className="col-sm-3">
                    {languages && languages.map((language, key) =>
                        <span className="mr-4" key={key}>
                            <label htmlFor={language.id} style={{cursor: "pointer"}}
                                   onClick={() => setLang(language.id)}>
                                <b className={'mr-1'}><FormattedMessage id={`app.label.${language.label}`}/></b>
                                {/*<input type="radio" name="language" value={language.id} id={language.id}*/}
                                {/*	className="ml-2"/>*/}
                                <Field type="radio" component="input" name={'lang'} value={language.id} id={language.id}
                                       onClick={() => setLang(language.id)}/>
                            </label>
                        </span>
                    )}

                </div>
            </div>

            <div className="row">

                <div className="col-sm-4">
                    <Field
                        className="form-control form-control-sm"
                        id={`${lang}name`}
                        name={`${lang}name`}
                        component={renderField}
                        type="text"
                        label={`${lang}name`}
                        validate={validate.error}
                    />
                </div>
                <div className="col-sm-4">
                    <Field
                        className="form-control form-control-sm"
                        id={`${lang}description`}
                        name={`${lang}description`}
                        component={renderField}
                        type="text"
                        label={`${lang}description`}
                        validate={validate.error}
                    />
                </div>
                <div className="col-sm-4">
                    <Field
                        className="form-control form-control-sm"
                        id={`copyright`}
                        name={`copyright`}
                        component={renderField}
                        type="text"
                        label={`frcopyright`}
                        validate={validate.error}
                    />
                </div>
            </div>


        </div>

    );
}
export default React.memo(withRouter(FormItems))
