import * as React from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {useParams, withRouter} from "react-router";
import {connect, useDispatch, useSelector} from "react-redux";
import useEntityManager from "../../../../../../common/hooks/useEntityManager";
import actions from "../../../../../../../thunks/common/coreThunks";
import {Field, reduxForm, FieldArray, reset} from "redux-form";
import {validate} from "../../validator";
import Items from './Items'
import FormFooter from "../../../../../common/form/FormFooter";
import {
    HelpFaqs, HelphFaqsMapper
} from '../../../../../../../constants/models/company'
import {useState} from "react";


const FormComponent = injectIntl((props) => {

    const {
        intl, applicant, payment, specimenCenter, state, symptoms, destination, departure, id,
        handleSubmit, pristine, valid, submitting, initialValues, history, touched
    } = props;
    const {page} = useParams();


    const dispatch = useDispatch();

    const params = {options: [page]};


    const {item, loading, helpItem, status} = useSelector(state => ({
        loading: state.companyReducer.loadingItem,
        item: state.companyReducer.item,
        helpItem: state.companyReducer.helpItem,
        status: state.companyReducer.status,
    }));


    const onFormSubmit = element => {
        element.idDescription = helpItem?.id ? helpItem.description.id : '';
        element.idTitle = helpItem?.id ? helpItem.title.id : '';
        const entity = new HelpFaqs(element)
        entity.id = item?.id ? item?.id : null
        entity.itemId = helpItem?.id ? helpItem?.id : null

        dispatch(actions.saveOrUpdateItem({
            options: ['company', 'helpfaq'], intl, action: item && item.id ? '' : 'save', item: entity,
        }))
    };

    return (
        <div className="card card-custom gutter-b">
            <div className="card-body">
                <div className="">
                    <div className={'row'}>
                        <form onSubmit={handleSubmit(onFormSubmit)}>


                            <div className={'col-lg-12'}>
                                <Items/>
                            </div>

                            <FormFooter {...{
                                item,
                                loading,
                                pristine,
                                submitting,
                                reset,
                                valid,
                                dispatch,
                                history,
                                module
                            }}/>

                        </form>
                    </div>


                </div>

            </div>
        </div>

    );
});

const mapStateToProps = ({companyReducer: {helpItem}}) => {

    const defaultState = {
        langHelp: 'fr',
        type: 'HELP',
        frDesc: " ",
        enDesc: " ",
        htDesc: " ",
    }

    const initVal = helpItem ? new HelphFaqsMapper(helpItem) : null;
    return {
        initialValues: helpItem !== null ? {...initVal, langHelp: 'fr'} : {...defaultState}
    }
};

export default React.memo(withRouter(connect(mapStateToProps,)(reduxForm({
    form: "helpForm",
    enableReinitialize: true,
    validate,
})(FormComponent))));


