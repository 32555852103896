import * as React from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {useParams, withRouter} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {useContext, useEffect} from "react";
import {GlobalContext} from "../../../../../index";
import MaterialTable from 'material-table';
import {getIntlMsg, Localization} from "../../../../../../constants/utils";
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";
import actions from "../../../../../../thunks/common/coreThunks";
import swal from "sweetalert";
import DownloadLink from "react-download-link";
import moment from "moment";

const ApplicantList = injectIntl((props) => {

        const dispatch = useDispatch();
        const {intl, EditRecruitment, showDocument, item} = props;


        const options = {options: ['applications', 'recruitment'], item: {id: item?.id}};

        useEffect(() => {
            dispatch(actions.loadItem(options));
        }, [dispatch]);


        const {items} = useSelector(state => ({
            loading: state.companyReducer.loadingItems,
            items: state.adminReducer?.applications || [],
        }));


        console.log("++++++++++++++++===========++++++",item)


        const formattedItems = [];
        items.forEach(v => {
            formattedItems.push({
                firstname: v.applicant?.firstName,
                lastname: v.applicant?.lastName,
                email: v.applicant?.email,
                phone: v.applicant?.phone,
                id: v.id,
                documents: v.applicant?.documents,
                attachment: v.applicant?.documents?.length,
                created: moment(v.applicant?.created).format('DD-MM-YYYY'),
            })
        })

        const columns = [
            {
                title: "Nom",
                type: 'text',
                field: 'lastname',
            }, {
                title: "Prenom",
                type: 'text',
                field: 'firstname',
            }, {
                title: "Email",
                type: 'text',
                field: 'email',
            }, {
                title: "Telephone",
                type: 'text',
                field: 'phone',
            },
            {
                title: "Dossiers",
                field: 'attachment',
                render: rowData => (<>
                    <span className="text-danger">{rowData.attachment}</span>
                    <span className="material-icons"
                          onClick={() => showDocument(rowData.documents)}
                          style={{
                              fontSize: '20px',
                              cursor: 'pointer'
                          }}>source</span></>)
            },
            {
                title: "Date de creation",
                field: 'created'
            },
            {
                title: "Télécharger tous les dossiers",
                render: rowData => (<>
                    <DownloadLink
                        label={<span className="material-icons">save_alt</span>}
                        filename={rowData.lastname + "." + rowData.firstname + '.zip'}
                        exportFile={() => Promise.resolve(getDataFromURL(window.BASE_URL + `/applications/download/${rowData.id}`))}
                    />
                </>)
            },
        ]

        const getDataFromURL = (url) => new Promise((resolve, reject) => {
            setTimeout(() => {
                fetch(url)
                    .then(response => response.blob())
                    .then(data => {
                        resolve(data)
                    });
            });
        }, 2000);

        const handlePublishUnPublish = (id, status) => {

            swal({
                title: "voulez-vous vraiment",
                text: status === "publish" ? "le Publier" : "ne le pas Publier",
                icon: "warning",
                buttons: ["Non", "Oui"],
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        dispatch(actions.saveOrUpdateItem({
                            options: ['common', 'recruitment'],
                            intl,
                            action: '',
                            item: {id: id},
                        }))
                    } else {
                    }
                });
        }
        const {userSession: {roles}} = useContext(GlobalContext);

        return (

            <div className="row">
                {formattedItems && formattedItems.length > 0 ?
                    <div className="col-md-12 pt-4" style={{}}>
                        <div className="d-flex align-items-center flex-wrap float-right">
                            <div data-placement="top" className="">
                                <DownloadLink
                                    style={{textDecoration:'none'}}
                                    label={<button
                                        className="btn btn-fixed-height btn-primary font-weight-bolder font-size-sm px-5 my-1">
                                        <span className="material-icons mr-2">upload</span>
                                        Télécharger tous les dossier pour ce poste.
                                    </button>}
                                    filename={item?.name + '.zip'}
                                    exportFile={() => Promise.resolve(getDataFromURL(window.BASE_URL + `/applications/download/batch/${item?.id}`))}
                                />
                            </div>
                        </div>
                    </div> : null}

                <div className="col-md-12 pt-4" style={{}}>
                    {formattedItems && formattedItems.length > 0 ?

                        <MaterialTable
                            className="col-md-12"
                            title={`Liste des Postulants`}
                            columns={columns}
                            data={formattedItems ? formattedItems : []}
                            icon='unfold_less'
                            localization={Localization('fr')}
                            options={{
                                headerStyle: {
                                    backgroundColor: '#ff7f26',
                                    color: '#FFF',
                                    fontFamily: 'Ki',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontVariant: 'normal',
                                    lineHeight: 1,
                                    fontSize: '15px'
                                },
                                actionsColumnIndex: -1,
                                exportButton: true,
                                exportAllData: true,

                            }}
                            actions={[]
                            }

                        /> :
                        <SkeletonTheme color={'rgba(255,127,38,0.68)'} highlightColor="#444">
                            <p>
                                <Skeleton count={3}/>
                            </p>
                        </SkeletonTheme>}
                </div>
            </div>
        );
    }
)

export default React.memo(withRouter(ApplicantList))

