import React, {useContext} from "react";
import {injectIntl} from "react-intl";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
import {GlobalContext} from "../../../../../index";
import {canDisplay} from "../../../../../../constants/utils/security";

const Item = injectIntl(props => {
    const {item, EditMember, DeleteMember, handlePublishUnPublish} = props
    const {userSession: {roles}} = useContext(GlobalContext);
    return (
        <div className="col-xxl-3 col-xl-6 col-md-6 col-sm-6">
            <div className="card card-custom gutter-b card-stretch">
                <div className="card-body pt-4">
                    <div className="d-flex align-items-end py-2">
                        <div className="d-flex align-items-center">
                            <div className="d-flex flex-shrink-0 mr-5">
                                <div className="symbol symbol-circle symbol-lg-75">
                                    {item?.fileName ?
                                        <img src={item ? item?.fileName : "/assets/media/logos/camera.jpg"}
                                        /> : <Skeleton count={1} circle={true} height={50} width={50} delay={1}/>}
                                </div>
                            </div>
                            <div className="d-flex flex-column">
                                {item?.title?.frContent ? <a href="#"
                                                             className="text-dark font-weight-bold text-hover-primary font-size-h4 mb-0"> {item?.title?.frContent}</a>
                                    : <Skeleton count={1} circle={true} width={100} delay={1}/>}
                                {item?.category?.name?.frContent ? <span
                                        className="text-muted font-weight-bold">{item?.category?.name?.frContent}</span>
                                    : <Skeleton count={1} circle={true} width={100} delay={1}/>}
                            </div>
                        </div>
                    </div>



                    <div className="py-2 p-0">
                        <div className="d-flex align-items-center mb-2">
                            {item?.created ? <span
                                    className="font-weight-bold"> <label>Date création : </label><span
                                    className="text-muted">  {item ? moment(item?.created).format('DD-MM-YYYY') : null}</span></span>
                                : <Skeleton count={1} circle={true} width={100} delay={1}/>}
                        </div>
                    </div>
                    <div className="pt-2">
                        {item && canDisplay(roles, 'update') ?
                            <a  className="btn btn-primary font-weight-bolder font-size-sm mr-2 p-2"
                               onClick={() => EditMember(item)}>
                                <i className="material-icons font-size-sm p-0">visibility</i>
                            </a> : canDisplay(roles, 'update') &&
                            <Skeleton count={1} circle={true} width={30} height={30} delay={1}
                                      className={'mr-1'}/>}

                        {item && canDisplay(roles, 'delete') ? <a
                                                                  className="btn btn-light-primary font-weight-bolder font-size-sm m-0 p-2"
                                                                  onClick={() => DeleteMember(item)}>
                            <i className="material-icons font-size-sm p-0">delete</i>
                        </a> : canDisplay(roles, 'delete') &&
                            <Skeleton count={1} circle={true} width={30} height={30} delay={1}
                                      className={'mr-1'}/>}
                        {!item?.status ? <>
                                {item && canDisplay(roles, 'publish') ? <a
                                                                           className="btn btn-light-primary font-weight-bolder font-size-sm m-0 p-2 ml-3"
                                                                           onClick={() => handlePublishUnPublish(item, 'publish')}>
                                    <i className="material-icons font-size-sm p-0">unpublished</i>
                                </a> : canDisplay(roles, 'publish') &&
                                    <Skeleton count={1} circle={true} width={30} height={30} delay={1}
                                              className={'mr-1'}/>}</> :
                            <>{item && canDisplay(roles, 'publish') ? <a
                                                                         className="btn btn-light-primary font-weight-bolder font-size-sm m-0 p-2 ml-3"
                                                                         onClick={() => handlePublishUnPublish(item, 'unpublish')}>
                                <i className="material-icons font-size-sm p-0">publish</i>
                            </a> : canDisplay(roles, 'publish') &&
                                <Skeleton count={1} circle={true} width={30} height={30} delay={1}
                                          className={'mr-1'}/>}</>}

                    </div>
                </div>
            </div>
        </div>
    )
})

export default Item