import React, {useContext, useEffect} from 'react'
import {injectIntl} from "react-intl";
import Item from "./Item";
import {GlobalContext} from "../../../../../index";
import {useDispatch, useSelector} from "react-redux";
import actions from "../../../../../../thunks/settings/categoryThunks";
import ModalComponent from "../form/modalComponent";
import NewsForm from '../form/form'
import AdminSubHeader from "../../subheader/adminSubheader";
import swal from "sweetalert";
import {SkeletonTheme} from "react-loading-skeleton";

const News = injectIntl((props) => {
    const {intl, setView, view} = props;
    const items = ["", ""]

    const {modalChildren, setModalChildren, showModal} = useContext(GlobalContext)
    const dispatch = useDispatch();
    const options = {options: ['news']};

    useEffect(() => {
        dispatch(actions.loadItems(options));
    }, [dispatch]);


    const handleSetModal = () => {
        showModal('news')
        setModalChildren(<NewsForm/>);
        dispatch(actions.resetItem({options: ['news']}))
    }

    const {item, loading, news} = useSelector(state => ({
        loading: state.adminReducer.loadingNews,
        news: state.adminReducer.news,
    }));

    const EditMember = et => {
        const params = {intl, item: {id: et.id}, options: ['news']}
        dispatch(actions.loadItem(params))
        showModal('news')
        setModalChildren(<NewsForm/>);
    }

    const DeleteMember = et => {

        swal({
            title: "Êtes-vous sûr?",
            text: "de supprimer cet article",
            icon: "warning",
            buttons: ["Non", "Oui"],
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    const params = {intl, item: {id: et.id}, options: ['news']}
                    dispatch(actions.removeItem(params))
                } else {
                }
            });

    }

    const handlePublishUnPublish = (et, status) => {

        swal({
            title: "voulez-vous vraiment",
            text: status === "publish" ? "Publier cet article" : "ne pas Publier cet article",
            icon: "warning",
            buttons: ["Non", "Oui"],
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    dispatch(actions.saveOrUpdateItem({
                        options: ['common', 'news'],
                        intl,
                        action: '',
                        item: {id: et.id},
                    }))
                } else {
                }
            });

    }


    return (
        <React.Fragment>
            <AdminSubHeader items={news ? news : []} handleSetModal={handleSetModal}/>
            <div className="d-flex flex-column-fluid pt-2">

                <div className="container">
                    <div className='row'>
                        <div className='col-md-6 pb-5'>


                        </div>

                        <div className="col-md-12">
                            <div className="row">
                                {news?.length > 0 ? news?.map(value => (
                                    <Item item={value} EditMember={EditMember} DeleteMember={DeleteMember}
                                          handlePublishUnPublish={handlePublishUnPublish}/>
                                )) : <div className="col-md-10">
                                    <SkeletonTheme color={'rgb(184,224,245)'}>
                                        <Item item={null} EditMember={EditMember} DeleteMember={DeleteMember}
                                              handlePublishUnPublish={handlePublishUnPublish}/>
                                    </SkeletonTheme></div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalComponent/>
        </React.Fragment>
    )
})

export default News