/**
 * Created by louidorpc on 1/20/21.
 */
import React, {useState} from "react"
import {FormattedMessage, injectIntl} from "react-intl";
import CreatableSelect from "react-select/creatable/dist/react-select.cjs.dev";

export const inputsearchselected = injectIntl(
    ({
         intl,
         input,
         formattedCats,
         label,
         style,
         valueCategory,
         name,
         placeholder,
         onChangeInput,
         type,
         id,
         readonly,
         required = false,
         meta: {asyncValidating, touched, error}
     }) => (
        <div className={`form-group`}>
            <div>
                <CreatableSelect
                    {...input}
                    name={name}
                    id={id}
                    isClearable
                    value={valueCategory ? valueCategory : input.value}
                    onChange={(v) => onChangeInput(v)}
                    onBlur={(v) => onChangeInput(valueCategory ? valueCategory : input.value)}
                    options={formattedCats}/>
            </div>
            {touched && error &&
            <div className="text-primary">
                <small className="text-red" style={{fontSize: "12px"}}>
                    <FormattedMessage id={error}/>
                </small>
            </div>
            }
        </div>
    ));