import React, {useContext, useEffect, useState} from "react"
import {Field} from "redux-form";
import {FormattedMessage, injectIntl} from 'react-intl';
import {
    renderCheckbox,
    renderField,
    renderFreeField,
    renderRadio,
    renderSelect
} from "../../../../../common/utils/FormComponent";
import {withRouter} from "react-router";
import {getIntlMsg} from "../../../../../../constants/utils";
import {FileInput} from "../../../../../common/utils/FileInput";
import {useDispatch, useSelector} from "react-redux";
import actions from "../../../../../../thunks/settings/categoryThunks";
import {GlobalContext} from "../../../../../index";
import params from "../../../../../../constants/utils/params";
import TextEditorWYSIWYG from "../../../../../common/utils/RichTextEditorWYSIWYG";
import Slides from './slideComponent'
import ModalComponent from "./modalComponent";
import {validate} from "./validator";

let renderSlides = injectIntl(props => {
    const {intl, fields, isAdd, touched, reset, status,error} = props


    const dispatch = useDispatch();
    const options = {options: ['categories']};

    const {languages} = params;


    const {lang, setLang, setModalChildren, showModal, hideModal} = useContext(GlobalContext);


    const [dirty, setDirty] = useState(false)


    const handleChangeLanguage = val => {
        setLang(val)
        setDirty(false)
    }

    const handleChangeDirty = val => {
        setDirty(true)
    }

    const pageNames = [
        {name: "Accueil", id: 'HOME'},
        {name: "Secteur", id: 'SECTOR'},
        {name: "À propos", id: 'ABOUT'},
        {name: "Notre Réseau", id: 'NETWORK'},
        {name: "Aides", id: 'HELP'},
        {name: "Covid", id: 'COVID'},
    ]

    const handleSetModal = () => {
        showModal('slides')
        setModalChildren(<Slides/>);
    }

    const resetSlider = () => {
        hideModal('slides')
        reset('sliderForm')
        dispatch(actions.resetItem({options: ['sliders']}))
    }
    if (status === "sliderSucceeded") resetSlider()
    return (
        <React.Fragment>
            <div className="row">

                <div className="col-sm-12">
                    <div className="row pb-5">
                        {lang === 'fr' ?
                            <div
                                className="label label-light-primary label-pill label-inline col-md-12 p-6"><span
                                className="">Francais</span></div> :
                            lang === 'en' ?
                                <div
                                    className="label label-light-info label-pill label-inline col-md-12 p-6"><span
                                    className="">Anglais</span></div> :
                                <div className="label label-light-danger label-pill label-inline col-md-12 p-6">
                                    <span className="">Kreyol</span></div>}
                    </div>
                </div>

                <div className="col-sm-12">
                    <div className="row pb-5">
                        <div className="col-sm-4">
                            {languages && languages.map((language, key) =>
                                    <span className="mr-4" key={key}>
                            <label htmlFor={language.id} style={{cursor: "pointer"}}
                                   onClick={() => handleChangeLanguage(language.id)}>
                                <b className={'mr-1'}><FormattedMessage id={`app.label.${language.label}`}/></b>
                                <Field type="radio" component="input" name={'lang'} value={language.id} id={language.id}
                                       onClick={() => handleChangeLanguage(language.id)}/>
                            </label>
                        </span>
                            )}

                        </div>
                    </div>
                </div>
                <div className="col-sm-12">
                    <div className={'row pt-4'}>
                        <div className="col-sm-4">
                            <div className={'row'}>

                                <div className="col-sm-12">
                                    <div className={'row'}>
                                        <div className="col-sm-12">
                                            <Field
                                                className="form-control form-control-sm"
                                                id={`${lang}name`}
                                                name={`${lang}name`}
                                                component={renderField}
                                                type="text"
                                                label={`${lang}name`}
                                                validate={validate.error}
                                            />
                                        </div>
                                        <div className="col-sm-12">
                                            <div className="form-group">

                                                <label htmlFor="test_type" className="font-weight-bold">
                                                    Page :
                                                </label>

                                                <Field name={`pageName`} component="select" className="form-control">
                                                    <option value={''}></option>
                                                    {pageNames && pageNames.length > 0 ? pageNames.map((pg, i) =>
                                                        <option value={pg?.id} key={i}>
                                                            {pg?.name}
                                                        </option>
                                                    ) : null}
                                                </Field>
                                                    {touched && error &&
                                                    <span className="form-text text-primary">
                                                        <FormattedMessage id={validate.error}/>
                                                   </span>}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-8">
                            <label><FormattedMessage id={`app.label.${lang}description`}
                                                     defaultMessage={''}/></label>
                            <div style={{border: '1px solid #000', width: '100%'}}>
                                <Field
                                    onChange={() => handleChangeDirty()}
                                    id={`${lang}description`}
                                    name={`${lang}description`}
                                    component={TextEditorWYSIWYG}
                                    meta={{dirty: dirty}}
                                    label={`${lang}description`}
                                    validate={validate.error}
                                />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </React.Fragment>
    );
})
export default React.memo(withRouter(renderSlides))
