import * as React from "react";
import useDocumentTitle from "../../../../common/hooks/useDocumentTitle";
import {getIntlMsg} from "../../../../../constants/utils";
import {injectIntl} from "react-intl";
import {useParams, withRouter} from "react-router";
import ServiceList from "./listing";
import ServiceForm from "./form";
import {useState} from "react";


const ServicePageManager = injectIntl((props) => {
    const {intl} = props;
    const {module} = useParams();
    useDocumentTitle(getIntlMsg(intl, `app.label.${module}`));
    const [formView, setFormView] = useState(false)

    return (
        <div className="tab-pane fade" id="service" role="tabpanel" aria-labelledby="service">
            <div className="cared card-custom pl-0">
                {formView && <ServiceForm {...{setFormView}}/>}
                <ServiceList {...{formView, setFormView}}/>
            </div>
        </div>
    );
})

export default React.memo(withRouter(ServicePageManager))

