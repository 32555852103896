import React from "react"
import {injectIntl} from 'react-intl';
import {withRouter} from "react-router";
import {Localization} from "../../../../../../constants/utils";
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";
import moment from "moment";
import MaterialTable from "material-table";
import DownloadLink from "react-download-link";
import FileViewer from 'react-file-viewer';
import {CustomErrorComponent} from 'custom-error';


let renderSlides = injectIntl(props => {
    const {items} = props

    const formattedItems = [];
    items.forEach(item => {
        formattedItems.push({
            documentName: item?.documentName,
            created: moment(item?.created).format('DD-MM-YYYY'),
            fileName: item?.fileName
        })
    })

    const getDataFromURL = (url) => new Promise((resolve, reject) => {
        setTimeout(() => {
            fetch(url)
                .then(response => response.blob())
                .then(data => {
                    resolve(data)
                });
        });
    }, 2000);

    const columns = [
        {
            title: "nom du document",
            type: 'text',
            field: 'documentName'
        },
        {
            title: "Date de creation",
            field: 'created'
        },
        {
            title: "Telecharcher",
            align: 'center',
            render: rowData => (<>
                    <DownloadLink
                        label={<span className="material-icons">file_download</span>}
                        filename={rowData.documentName + "." + rowData.fileName?.split('.')[1]}
                        exportFile={() => Promise.resolve(getDataFromURL(rowData.fileName))}
                    />
                </>
            )
        },
    ]

    return (
        <div className={'row'}>
            <div className="col-md-12">
                <div className="card card-custom gutter-b card-stretch">
                    <div className="card-body">
                        {/*<Test/>*/}

                            {items && items.length > 0 ?
                                <MaterialTable
                                    className="col-md-12"
                                    title="Documents"
                                    columns={columns}
                                    data={formattedItems ? formattedItems : []}
                                    icon='unfold_less'
                                    localization={Localization('fr')}
                                    options={{
                                        headerStyle: {
                                            backgroundColor: '#ff7f26',
                                            color: '#FFF',
                                            fontFamily: 'Ki',
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            fontVariant: 'normal',
                                            lineHeight: 1,
                                            fontSize: '15px'
                                        },
                                        actionsColumnIndex: -1,
                                        exportButton: true,
                                        exportAllData: true,
                                        pageSize: formattedItems?.length,
                                        pageSizeOptions: [10, 20, 25, 50, 100],

                                    }}

                                /> :
                                <SkeletonTheme color={'rgba(255,127,38,0.68)'} highlightColor="#444">
                                    <p>
                                        <Skeleton count={3}/>
                                    </p>
                                </SkeletonTheme>}


                    </div>
                </div>
            </div>
        </div>
    );
})


export default React.memo(withRouter(renderSlides))
