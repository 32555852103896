import React, {useContext, useEffect, useState} from "react"
import {Field, change} from "redux-form";
import {FormattedMessage, injectIntl} from 'react-intl';
import {
    renderCheckbox,
    renderField,
    renderFreeField,
    renderRadio,
    renderSelect
} from "../../../../../common/utils/FormComponent";
import {withRouter} from "react-router";
import {getIntlMsg} from "../../../../../../constants/utils";
import {FileInput} from "../../../../../common/utils/FileInput";
import {useDispatch, useSelector} from "react-redux";
import actions from "../../../../../../thunks/settings/categoryThunks";
import {GlobalContext} from "../../../../../index";
import params from "../../../../../../constants/utils/params";
import TextEditorWYSIWYG from "../../../../../common/utils/RichTextEditorWYSIWYG";
import {validate} from "./validator";
import CreatableSelect from 'react-select/creatable';
import renderDocuments from './logo';
import renderRequiredDocuments from './requiredDocs';
import {reduxForm, FieldArray} from "redux-form";
import {inputsearchselected} from "../../../../../common/utils/Inputsearchselected";

let renderSlides = injectIntl(props => {
    const {intl, fields, isAdd, touched, reset, status} = props


    const dispatch = useDispatch();
    const options = {options: ['categories', 'entity-type', 'RECRUITMENT']};

    useEffect(() => {
        dispatch(actions.loadItems(options));
    }, [dispatch]);


    const {languages} = params;


    const {lang, setLang, setModalChildren, showModal, hideModal} = useContext(GlobalContext);

    const categories = useSelector(state => state.categoryReducer?.recruitmentCategory);
    const formattedCats = [];
    categories.forEach(category => {
        formattedCats.push({value: category?.id, label: category?.name?.frContent})
    })

    const [dirty, setDirty] = useState(false)


    const handleChangeLanguage = val => {
        setLang(val)
        setDirty(false)
    }

    const handleChangeDirty = val => {
        setDirty(true)
    }

    const resetRecruitment = () => {
        hideModal('staffing')
        reset('recruitmentForm')
        dispatch(actions.resetItem({options: ['recruitments']}))
    }

    const [valueCategory, setValue] = useState("")

    const onChangeInput = e => {
        setValue(e)
        dispatch(change('staffingForm', 'frcategory', e))
        console.log("===============++++++++++++", 2)
    }


    if (status === "recruitmentSucceeded") resetRecruitment()
    return (
        <React.Fragment>
            <div className="row">

                <div className="col-sm-12">
                    <div className="row pb-5">
                        {lang === 'fr' ?
                            <div
                                className="label label-light-primary label-pill label-inline col-md-12 p-6"><span
                                className="">Francais</span></div> :
                            lang === 'en' ?
                                <div
                                    className="label label-light-info label-pill label-inline col-md-12 p-6"><span
                                    className="">Anglais</span></div> :
                                <div className="label label-light-danger label-pill label-inline col-md-12 p-6">
                                    <span className="">Kreyol</span></div>}
                    </div>
                </div>

                <div className="col-sm-12">
                    <div className="row pb-5">
                        <div className="col-sm-4">
                            {languages && languages.map((language, key) =>
                                    <span className="mr-4" key={key}>
                            <label htmlFor={language.id} style={{cursor: "pointer"}}
                                   onClick={() => handleChangeLanguage(language.id)}>
                                <b className={'mr-1'}><FormattedMessage id={`app.label.${language.label}`}/></b>
                                <Field type="radio" component="input" name={'lang'} value={language.id} id={language.id}
                                       onClick={() => handleChangeLanguage(language.id)}/>
                            </label>
                        </span>
                            )}

                        </div>
                    </div>
                </div>
                <div className="col-sm-12">
                    <div className={'row pt-4'}>
                        <div className="col-sm-6">
                            <div className={'row'}>

                                <div className="col-sm-12">
                                    <div className={'row'}>
                                        <div className="col-sm-12">
                                            <Field
                                                className="form-control form-control-sm"
                                                id={`${lang}name`}
                                                name={`${lang}name`}
                                                component={renderField}
                                                type="text"
                                                label={`${lang}title`}
                                                validate={validate.error}
                                            />
                                        </div>
                                        <div className="col-sm-12">
                                            <Field
                                                className="form-control form-control-sm"
                                                id={`place`}
                                                name={`place`}
                                                component={renderField}
                                                type="text"
                                                label={`place`}
                                                validate={validate.error}
                                            />
                                        </div>
                                        <div className="col-md-12 pb-4">

                                            <label htmlFor="test_type" className="font-weight-bold">
                                                Categorie :
                                            </label>
                                            <Field name={`${lang}category`}
                                                   id={'category'}
                                                   formattedCats={formattedCats}
                                                   onChangeInput={(e) => onChangeInput(e)}
                                                   valueCategory={valueCategory}
                                                   component={inputsearchselected}
                                                   validate={validate.error}
                                            />
                                        </div>
                                        <div className="col-sm-6">
                                            <Field
                                                className="form-control form-control-sm"
                                                id={`startDate`}
                                                name={`dateStarted`}
                                                component={renderField}
                                                type="date"
                                                label={`start_date`}
                                                validate={validate.error}
                                            />
                                        </div>
                                        <div className="col-sm-6">
                                            <Field
                                                className="form-control form-control-sm"
                                                id={`endDate`}
                                                name={`dateEnded`}
                                                component={renderField}
                                                type="date"
                                                label={`end_date`}
                                                validate={validate.error}
                                            />
                                        </div>

                                        <div className="col-md-12 pb-4 pt-4">
                                            <label className="card-title"><b>Document requis</b></label>
                                            <FieldArray component={renderRequiredDocuments} name={'requiredDocs'}/>
                                        </div>


                                    </div>
                                </div>


                            </div>
                        </div>
                        <div className="col-md-6 pb-4 pt-4">
                            <label className="card-title"><b>Ajouter des attachements</b></label>
                            <FieldArray component={renderDocuments} name={'documents'}/>
                        </div>
                        <div className="col-sm-12 pt-4 pb-5">
                            <div>
                                <Field label={`${lang}About`}
                                       name={`${lang}about`}
                                       type={'textarea'}
                                       id={`${lang}about`}
                                       component={renderField}
                                />
                            </div>

                        </div>

                        <div className="col-sm-12 pb-10">
                            <label><FormattedMessage id={`app.label.${lang}description`}
                                                     defaultMessage={''}/></label>
                            <div style={{border: '1px solid #000', width: '100%'}}>
                                <Field
                                    onChange={() => handleChangeDirty()}
                                    id={`${lang}description`}
                                    name={`${lang}description`}
                                    component={TextEditorWYSIWYG}
                                    meta={{dirty: dirty}}
                                    label={`${lang}description`}
                                    validate={validate.error}
                                />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </React.Fragment>
    );
})
export default React.memo(withRouter(renderSlides))
