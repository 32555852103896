const actionTypes = {


    /**
     * @Author Louidor Jean-Paul
     * LOAD COMPANY ACTION TYPES
     * -------------------------------------------
     * */

    RESET_SLIDER: "RESET_SLIDER",
    LOAD_SLIDERS: "LOAD_SLIDERS",
    LOAD_SLIDERS_SUCCEEDED: "LOAD_SLIDERS_SUCCEEDED",
    LOAD_SLIDERS_FAILED: "LOAD_SLIDERS_FAILED",
    DELETE_SLIDER_SUCCEEDED: "DELETE_SLIDER_SUCCEEDED",

    LOAD_SLIDER_SUCCEEDED: "LOAD_SLIDER_SUCCEEDED",
    UPDATE_COMMON_SLIDE_SUCCEEDED: "UPDATE_COMMON_SLIDE_SUCCEEDED",
    SAVE_SLIDER_SUCCEEDED: "SAVE_SLIDER_SUCCEEDED",
    UPDATE_SLIDER_SUCCEEDED: "UPDATE_SLIDER_SUCCEEDED",
    UPDATE_COMMON_SLIDER_SUCCEEDED: "UPDATE_COMMON_SLIDER_SUCCEEDED",

    LOAD_STATISTIC_SUCCEEDED: "LOAD_STATISTIC_SUCCEEDED",
    RESET_STATISTIC: "RESET_STATISTIC",
    LOAD_STATISTICS_SUCCEEDED: "LOAD_STATISTICS_SUCCEEDED",
    SAVE_STATISTIC_SUCCEEDED: "SAVE_STATISTIC_SUCCEEDED",
    UPDATE_STATISTIC_SUCCEEDED: "UPDATE_STATISTIC_SUCCEEDED",
    UPDATE_COMMON_STATISTIC_SUCCEEDED: "UPDATE_COMMON_STATISTIC_SUCCEEDED",
};


export default actionTypes;
