import React from "react"
import {Field} from "redux-form";
import {FormattedMessage, injectIntl} from 'react-intl';
import {
    renderCheckbox,
    renderField,
    renderFreeField,
    renderRadio,
    renderSelect
} from "../../../../../common/utils/FormComponent";
import {withRouter} from "react-router";
import {getIntlMsg} from "../../../../../../constants/utils";
import {countries} from "../../../../../../constants/options/countries";
import {useSelector} from "react-redux";
import {nameAcronym} from "../../../../../../constants/utils";
import {validate} from "../validator";

let renderSocialLink = injectIntl(props => {
    const {intl, fields, meta: {error}, isAdd} = props
    const validate = {}
    const socialLinkType = [
        {name: 'Twitter', code: "TWITTER"},
        {name: 'Facebook', code: "FACEBOOK"},
        {name: 'Istagram', code: "INSTAGRAM"},
    ]

    if (!fields.length) fields.push();
    return (
        <div className="tab-pane fade" id="socialLink" role="so" aria-labelledby="socialLink">
            <div className="row">
                <div className="col-md-12  pb-10">
                    <span className="btn btn-sm btn-outline-primary float-lg-right" onClick={() => fields.push()}>
                        <FormattedMessage id="app.label.add_social_link"/>
                    </span>
                </div>
                <div className="col-sm-12">
                    {fields.map((sc, index) => (
                        <div className={'row'} key={index}>
                            <div className="col-sm-1">
                                <h1 className="mt-5 text-center">{index + 1}</h1>
                            </div>
                            <div className="col-sm-4 ml-5">
                                <div className="form-group">
                                    <Field className="form-control form-control-sm" id={`${sc}_socialLink`}
                                           name={`${sc}socialLink`} component={renderField} type="text"
                                           label="socialLink"
                                           validate={validate.error}/>

                                </div>

                            </div>

                            <div className="col-sm-5 ml-5">
                                <div className="form-group">
                                    <div>
                                        <label>Nom du réseau</label>
                                    </div>
                                    <div>
                                        {socialLinkType && socialLinkType.map((link, key) =>
                                                <span className="mr-4" key={key}>
                                              <label htmlFor={`${sc}${link.code}`}>
                                         <b className={'mr-1'}>{link.name}</b>
                                       <Field type="radio" component="input" name={`${sc}type`} value={link.code}
                                              id={`${sc}${link.code}`}/>
                                       </label>
                                       </span>
                                        )}
                                    </div>
                                </div>

                            </div>

                            <div className="col-sm-1">
                                <button type="button" title={getIntlMsg(intl, 'app.label.remove_trip')}
                                        className="btn btn-icon btn-light-primary btn-circle mr-2 mt-5"
                                        onClick={() => fields.remove(index)}>
                                    <i className="flaticon2-trash"/>
                                </button>
                            </div>
                        </div>
                    ))}
                    {error && <li className="error">{error}</li>}
                </div>
            </div>
        </div>
    );
})
export default React.memo(withRouter(renderSocialLink))
