import * as validator from "../../../../../../constants/utils/validator";


export const validate = values => {
    const errors = {};
    errors.newsFile=values.newsFile === null;
    errors.category=values.category === null;
    errors.frTitle = validator.validateString('frTitle', values.frTitle, 3, 150, true);
    errors.lastName = validator.validateString('lastName', values.lastName, 3, 150, true);
    errors.position = validator.validateString('position', values.position, 3, 150, true);

    return errors
};


