import {combineReducers} from "redux";
import {reducer as formReducer} from 'redux-form'
import {connectRouter} from "connected-react-router";
import coreReducer from "./common/_coreReducer";
import _proxyReducer from "./common/_proxyReducer";

import requestReducer from "./request/reducer";
import settingsReducer from "./settings/reducer";
import spCenterReducer from "./settings/spCenterReducer";
import currencyReducer from "./settings/currencyReducer";
import itemPriceReducer from "./settings/itemPriceReducer";
import testIndicatorReducer from "./settings/testIndicatorReducer";
import institutionReducer from "./security/institutionReducer";
import observationReducer from "./observation/reducer";
import patientReducer from "./observation/patientReducer";
import dbReducer from "./dashboard/reducer";
import resultReducer from "./result/reducer";
import companyReducer from "./company/reducer";
import adminReducer from "./admin/reducer";
import componentReducer from "./component/reducer";


import categoryReducer from "./settings/categoryReducer";

const createRootReducer = (history) =>
    combineReducers({
        form: formReducer,
        router: connectRouter(history),
        keycloak: (keycloak = {}) => keycloak,

        coreReducer,
        proxyReducer: _proxyReducer,
        settingsReducer,

        dbReducer,
        requestReducer,
        observationReducer,
        patientReducer,
        spCenterReducer,
        currencyReducer,
        itemPriceReducer,
        testIndicatorReducer,
        institutionReducer,
        resultReducer,

        categoryReducer,
        companyReducer,
        adminReducer,
        componentReducer
    });
export default createRootReducer;