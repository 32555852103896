export class Member {

    constructor(params) {
        if (params) {

            const {
                memberFile,
                firstName,
                lastName,
                frPosition,
                enPosition,
                htPosition,
                grade,
                category,
                frAbout,
                enAbout,
                htAbout,
            } = params;

            this.file = memberFile?.fileName ? memberFile : null
            this.firstname = firstName
            this.lastname = lastName
            this.grade = grade ? parseInt(grade) : null
            this.about = {
                frContent: frAbout,
                enContent: enAbout,
                htContent: htAbout
            }
            this.position = {
                frContent: frPosition,
                enContent: enPosition,
                htContent: htPosition
            }
            this.category = {id: category}

        }
    }
}


export class MemberMapper {

    constructor(params) {
        if (params) {

            const {
                fileName,
                firstname,
                lastname,
                position,
                grade,
                category,
                about,
            } = params;

            this.memberFile = fileName
            this.firstName = firstname
            this.lastName = lastname
            this.grade = grade
            this.frPosition = position?.frContent;
            this.enPosition = position?.enContent;
            this.htPosition = position?.htContent;
            this.frAbout = about?.frContent;
            this.enAbout = about?.enContent;
            this.htAbout = about?.htContent;
            this.category = category?.id

        }
    }
}


export class News {

    constructor(params) {
        if (params) {

            const {
                newsFile,
                frTitle,
                enTitle,
                htTitle,
                category,
                frContent,
                enContent,
                htContent,
                idTitle,
                idContent
            } = params;

            this.file = newsFile?.fileName ? newsFile : null
            this.title = {
                frContent: frTitle,
                enContent: enTitle,
                htContent: htTitle,
                id: idTitle
            }
            this.content = {
                frContent: frContent,
                enContent: enContent,
                htContent: htContent,
                id: idContent
            }
            this.category = {id: category}

        }
    }
}


export class NewsMapper {

    constructor(params) {
        if (params) {

            const {
                fileName,
                title,
                content,
                category,
            } = params;

            this.newsFile = fileName
            this.frTitle = title?.frContent
            this.enTitle = title?.enContent
            this.htTitle = title?.htContent

            this.frContent = content?.frContent ? content?.frContent : null;
            this.enContent = content?.enContent ? content?.enContent : null;
            this.htContent = content?.htContent ? content?.htContent : null;
            this.category = category?.id

        }
    }
}


export class Sector {

    constructor(params) {
        if (params) {

            const {
                sectorFile,
                frName,
                enName,
                htName,
                frAbout,
                enAbout,
                htAbout,
                frDesc,
                enDesc,
                htDesc,
                nameId,
                descId,
                aboutId,
                galleryId
            } = params;

            this.file = sectorFile?.fileName ? sectorFile : null
            this.gallery = galleryId ? {
                id: galleryId ? galleryId : null,
            } : null
            this.name = {
                frContent: frName,
                enContent: enName,
                htContent: htName,
                id: nameId
            }
            this.about = {
                frContent: frAbout,
                enContent: enAbout,
                htContent: htAbout,
                id: aboutId
            }
            this.description = {
                frContent: frDesc,
                enContent: enDesc,
                htContent: htDesc,
                id: descId
            }


        }
    }
}


export class SectorMapper {

    constructor(params) {
        if (params) {

            const {
                fileName,
                name,
                about,
                description,
                gallery,
            } = params;

            this.galleryId = gallery?.id
            this.sectorFile = fileName
            this.frName = name?.frContent
            this.enName = name?.enContent
            this.htName = name?.htContent

            this.frAbout = about?.frContent
            this.enAbout = about?.enContent
            this.htAbout = about?.htContent

            this.frDesc = description?.frContent;
            this.enDesc = description?.enContent;
            this.htDesc = description?.htContent;

        }
    }
}


export class Testimonial {

    constructor(params) {
        if (params) {

            const {
                testimonialFile,
                fullname,
                occupation,
                frContent,
                enContent,
                htContent,
                publishedOn,
                contentId
            } = params;

            this.file = testimonialFile?.fileName ? testimonialFile : null
            this.fullName = fullname
            this.occupation = occupation
            this.publishedOn = publishedOn;
            this.content = {
                frContent: frContent,
                enContent: enContent,
                htContent: htContent,
                id: contentId
            }

        }
    }
}


export class TestimonialMapper {

    constructor(params) {
        if (params) {

            const {
                fullName,
                occupation,
                content,
                fileName,
                publishedOn
            } = params;

            this.testimonialFile = fileName
            this.publishedOn = publishedOn
            this.fullname = fullName
            this.occupation = occupation
            this.frContent = content?.frContent ? content?.frContent : null;
            this.enContent = content?.enContent ? content?.enContent : null;
            this.htContent = content?.htContent ? content?.htContent : null;

        }
    }
}


export class Service {

    constructor(params) {
        if (params) {

            const {
                serviceFile,
                frName,
                enName,
                htName,
                frAbout,
                enAbout,
                htAbout,
                frDesc,
                enDesc,
                htDesc,
                nameId,
                descId,
                aboutId,
            } = params;

            this.file = serviceFile?.fileName ? serviceFile : null
            this.name = {
                frContent: frName,
                enContent: enName,
                htContent: htName,
                id: nameId
            }
            this.about = {
                frContent: frAbout,
                enContent: enAbout,
                htContent: htAbout,
                id: aboutId
            }
            this.description = {
                frContent: frDesc,
                enContent: enDesc,
                htContent: htDesc,
                id: aboutId
            }


        }
    }
}


export class ServiceMapper {

    constructor(params) {
        if (params) {

            const {
                fileName,
                name,
                about,
                description,
            } = params;

            this.serviceFile = fileName
            this.frName = name?.frContent
            this.enName = name?.enContent
            this.htName = name?.htContent

            this.frAbout = about?.frContent
            this.enAbout = about?.enContent
            this.htAbout = about?.htContent

            this.frDesc = description?.frContent;
            this.enDesc = description?.enContent;
            this.htDesc = description?.htContent;

        }
    }
}


export class HealthCenter {

    constructor(params) {
        if (params) {

            const {
                addresses,
                emails,
                phones,
                services,
                frDesc,
                enDesc,
                htDesc,
                frName,
                enName,
                htName,
                descId,
                nameId,
                latitude,
                longitude,
                healthcenterFile,
                frAbout,
                enAbout,
                htAbout,
                aboutId
            } = params;

            this.addresses = arrayMapperAddress(addresses).join(';')
            this.file = healthcenterFile?.fileName ? healthcenterFile : null;
            this.latitude = latitude;
            this.longitude = longitude;

            this.description = {
                "enContent": enDesc,
                "frContent": frDesc,
                "htContent": htDesc,
                id: descId
            }
            this.emails = arrayMapperEmail(emails).join(';')
            this.name = {
                "enContent": enName,
                "frContent": frName,
                "htContent": htName,
                id: nameId
            }

            this.about = {
                "enContent": enAbout,
                "frContent": frAbout,
                "htContent": htAbout,
                id: aboutId
            }
            this.phones = arrayMapperPhone(phones).join(';')
            this.services = services && services[0] !== undefined ? services : [];
        }
    }
}


export class HealthCenterMapper {

    constructor(params) {
        if (params) {

            const {
                addressArray,
                emailArray,
                phoneArray,
                services,
                name,
                fileName,
                description,
                about,
                longitude,
                latitude,
            } = params;

            this.addresses = addressArray ? addressArray.map(v => (
                {address: v}
            )) : []
            this.latitude = latitude;
            this.longitude = longitude;
            this.healthcenterFile = fileName

            this.enDesc = description?.enContent;
            this.frDesc = description?.frContent;
            this.htDesc = description?.htContent;

            this.emails = emailArray ? emailArray.map(v => (
                {email: v}
            )) : []
            this.enName = name?.enContent
            this.frName = name?.frContent
            this.htName = name?.htContent

            this.enAbout = about?.enContent
            this.frAbout = about?.frContent
            this.htAbout = about?.htContent

            this.phones = phoneArray ? phoneArray.map(v => (
                {phone: v}
            )) : []
            this.services = services;
        }
    }
}


export class DonationModel {

    constructor(params) {
        if (params) {

            const {
                donateFile,
                frName,
                enName,
                htName,
                frDesc,
                enDesc,
                htDesc,
                nameId,
                descId,
            } = params;

            this.file = donateFile?.fileName ? donateFile : null
            this.name = {
                frContent: frName,
                enContent: enName,
                htContent: htName,
                id: nameId
            }
            this.description = {
                frContent: frDesc,
                enContent: enDesc,
                htContent: htDesc,
                id: descId
            }


        }
    }
}


export class DonationMapper {

    constructor(params) {
        if (params) {

            const {
                fileName,
                name,
                description,
            } = params;

            this.donateFile = fileName ? fileName : null
            this.frName = name?.frContent
            this.enName = name?.enContent
            this.htName = name?.htContent


            this.frDesc = description?.frContent;
            this.enDesc = description?.enContent;
            this.htDesc = description?.htContent;

        }
    }
}


const arrayMapperAddress = (arrays) => {
    let str = []
    if (arrays) {
        for (const [key, value] of Object.entries(arrays)) {
            str.push(value?.address);
        }
    }
    return str;
}

const arrayMapperPhone = (arrays) => {
    let str = []
    if (arrays) {
        for (const [key, value] of Object.entries(arrays)) {
            str.push(value?.phone);
        }
    }
    return str;
}

const arrayMapperSocialLink = (arrays) => {
    let str = []
    if (arrays) {
        for (const [key, value] of Object.entries(arrays)) {
            str.push(value?.socialLink);
        }
    }
    return str;
}

const arrayMapperEmail = (arrays) => {
    let str = []
    if (arrays) {
        for (const [key, value] of Object.entries(arrays)) {
            str.push(value?.email);
        }
    }
    return str;
}








