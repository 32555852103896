import types from "../../actions/types/company/actionTypes";
import initialState from "../../../constants/utils/initialState";


const reducer = (state = initialState.companyReducer, action) => {

        switch (action.type) {


            case types.LOAD_COMPANY:
                return {...state, loadingItem: action.payload};

            case types.RESET_HELPFAQ:
            case types.RESET_LOGO:
                return {...state, helpItem: null, status: null, logoItem: null};

            case types.LOAD_COMPANY_SUCCEEDED:
                return {...state, item: action.payload, loadingItem: false};

            case types.UPDATE_COMPANY_HELPFAQ_SUCCEEDED:
            case types.UPDATE_COMPANY_ABOUT_SUCCEEDED:
            case types.UPDATE_COMPANY_VISION_SUCCEEDED:
            case types.UPDATE_COMPANY_MISSION_SUCCEEDED:
            case types.UPDATE_COMPANY_ORIGIN_SUCCEEDED:
            case types.UPDATE_COMPANY_SUCCEEDED:
                return {...state, item: action.payload, loadingItem: false, status: 'success',};

            case types.UPDATE_COMPANY_LOGO_SUCCEEDED:
                return {...state, item: action.payload, loadingItem: false, status: 'updateLogoSucceeded', logoItem: null};

            case types.DELETE_COMPANY_LOGO_SUCCEEDED:
                return {...state, item: action.payload.item};

            case types.LOAD_COMPANY_FAILED:
                // return {...state, loadingItems: action.payload};
                return {...state, loadingItem: action.payload};


            case types.LOAD_COMPANY_HELPFAQ:
                return {...state, loadItemHelp: action.payload};

            case types.LOAD_COMPANY_HELPFAQ_SUCCEEDED:
                return {...state, helpItem: action.payload, loadItemHelp: false};

            case types.LOAD_COMPANY_HELPFAQ_FAILED:
                // return {...state, loadingItems: action.payload};
                return {...state, loadItemHelp: action.payload};

            case types.LOAD_COMPANY_LOGO_SUCCEEDED:
                // return {...state, loadingItems: action.payload};
                return {...state, logoItem: action.payload};

            case types.UPDATE_COMMON_HELP_FAQ_SUCCEEDED:
                const st = {...state.item};
                const f = st.helps.map(v => v.id === action.payload.id ? action.payload : v)
                delete st.helps;
                return {...state, item: {...st, helps: f}}
            default:
                return state;
        }
    }
;

export default reducer;
