import * as React from "react";
import useDocumentTitle from "../../../../../common/hooks/useDocumentTitle";
import {getIntlMsg, nameAcronym} from "../../../../../../constants/utils";
import {FormattedMessage, injectIntl} from "react-intl";
import {useParams, withRouter} from "react-router";
import {connect, useDispatch, useSelector} from "react-redux";
import useEntityManager from "../../../../../common/hooks/useEntityManager";
import actions from "../../../../../../thunks/common/coreThunks";
import {Field, reduxForm, FieldArray} from "redux-form";
import {validate} from "../validator";
import {renderField, renderFreeField} from "../../../../../common/utils/FormComponent";
import {RequestMapper} from "../../../../../../constants/models/request";
import renderLogo from "./logo";
import renderAddress from "./address";
import renderPhone from "./phone";
import renderEmail from "./email";
import renderSocialLink from "./socialLink";
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";
import Items from './Items'
import FormFooter from "../../../../common/form/FormFooter";
import About, {Company, CompanyMapper} from '../../../../../../constants/models/company'
import {useContext, useEffect, useState} from "react";
import ModalComponent from "./modalComponent";
import {GlobalContext} from "../../../../../index";
import Vision from "../../../../../../constants/models/company";
import Mission from "../../../../../../constants/models/company";
import Origin from "../../../../../../constants/models/company";
import HelpFaqs from "../../../../../../constants/models/company";


const GeneralyForm = injectIntl((props) => {

    const {
        intl, applicant, payment, specimenCenter, state, symptoms, destination, departure, id,
        handleSubmit, pristine, valid, submitting, reset, initialValues, history, touched
    } = props;
    const {page} = useParams();



    const dispatch = useDispatch();


    const options = {options: ['company']};

    useEffect(() => {
        dispatch(actions.loadItems(options));
    }, [dispatch]);


    const params = {options: [page]};

    const {item, loading} = useSelector(state => ({
        loading: state.companyReducer.loadingItem,
        item: state.companyReducer.item,
    }));


    const onFormSubmit = element => {
        element.idDescription = item?.id ? item.description.id : null;
        element.idName = item?.id ? item.name.id : null;
        const entity = new Company(element)
        entity.id = item?.id ? item.id : null

        dispatch(actions.saveOrUpdateItem({
            options: ['company'], intl, action: item && item.id ? '' : 'save', item: entity,
        }))
    };

    const tabIds = [
        {id: "#logo", label: "logo", icon: "collections", status: "active"},
        {id: "#socialLink", label: "socialLink", icon: "link", status: ""},
        {id: "#address", label: "address", icon: "location_on", status: ""},
        {id: "#email", label: "email", icon: "mail", status: ""},
        {id: "#phone", label: "phone", icon: "phone", status: ""},
    ]

    const tabs = [
        <FieldArray name="logos" component={renderLogo}/>,
        <FieldArray name="socialLinks" component={renderSocialLink}/>,
        <FieldArray name="addresses" component={renderAddress}/>,
        <FieldArray name="emails" component={renderEmail}/>,
        <FieldArray name="phones" component={renderPhone}/>
    ];


    return (
        <React.Fragment>
            <div className="tab-pane fade show active" id="generaly" role="tabpanel" aria-labelledby="generaly">
                <div className="card card-custom gutter-b">
                    <div className="card-body">
                        <form onSubmit={handleSubmit(onFormSubmit)}>

                            <div className={'row'}>
                                <div className={'col-sm-12'}>
                                    <Items/>
                                </div>
                                <div className={'col-sm-12'}>
                                    <div className="card card-custom">
                                        <div className="card-header card-header-tabs-line">
                                            <div className="card-toolbar">
                                                <ul className="nav nav-tabs nav-bold nav-tabs-line">
                                                    {tabIds.map((v, i) => {
                                                            return (
                                                                <li className="nav-item" key={i}>
                                                                    <a className={`nav-link ${v.status}`} data-toggle="tab"
                                                                       href={v.id}>
                                                                        <span className="material-icons">{v.icon}</span>
                                                                        <span className="nav-text pl-2">
                                                        <FormattedMessage id={`app.label.${v.label}`}/>
                                                    </span>
                                                                    </a>
                                                                </li>)
                                                        }
                                                    )}
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="tab-content">
                                                {tabs.map((item, i) => item)}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <FormFooter {...{
                                item,
                                loading,
                                pristine,
                                submitting,
                                reset,
                                valid,
                                dispatch,
                                history,
                                module
                            }}/>

                        </form>


                    </div>
                </div>
            </div>
            <ModalComponent></ModalComponent>
        </React.Fragment>
    );
});

const mapStateToProps = ({companyReducer: {item}}) => {
    console.log("-=-----====================", item)
    const defaultState = {
        lang: 'fr'
    }
    const initVal = item ? new CompanyMapper(item) : null;
    return {
        initialValues: item !== null ? {...initVal, lang: 'fr'} : {...defaultState}
    }
};

export default React.memo(withRouter(connect(mapStateToProps,)(reduxForm({
    form: "generalyForm",
    enableReinitialize: true,
    validate,
})(GeneralyForm))));


