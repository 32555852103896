import * as React from "react";
import {injectIntl} from "react-intl";
import {getComponentItems} from "../../../../constants/options/secondaryMenuItems";
import ModuleItem from "../footer/module";
import {withRouter} from "react-router";
import {useContext} from "react";
import {GlobalContext} from "../../../index";
import {canDisplay} from "../../../../constants/utils/security";


const SecondaryComponent = injectIntl((props) => {
    const {intl, tab, history} = props
    const {userSession: {roles, name, keycloak}, institution: {acronym}} = useContext(GlobalContext);
    const path = history?.location?.pathname?.split('/')[2];
    console.log("=======PATH===========", path)
    const hasAccess = () => {
        return roles.includes('RECEPTIONIST')
            || roles.includes('ARCHIVIST')
            || roles.includes('CASHIER')
            || roles.includes('DATA_OFFICER')
            || roles.includes('LAB_TECHNICIAN')
            || roles.includes('RESULT_PROVIDER')
    }


    return (
        <div className={`tab-pane fade ${path === 'components' ? 'show active' : ''}`} id="components" key={2}>
            <div className="aside-menu-wrapper flex-column-fluid px-3 px-lg-10 py-5"
                 id="kt_aside_menu_wrapper">
                <div id="kt_aside_menu" className="aside-menu min-h-lg-800px" data-menu-vertical="1"
                     data-menu-scroll="1">
                    <ul className="menu-nav">
                        {getComponentItems(acronym).map((item, i) =>
                            canDisplay(roles, item.module) && <ModuleItem {...item} key={i}/>)
                        }
                    </ul>
                </div>
            </div>
        </div>

    );
})

export default React.memo(withRouter(SecondaryComponent))

