import React, {useContext, useEffect, useState} from "react"
import {Field} from "redux-form";
import {FormattedMessage, injectIntl} from 'react-intl';
import {
    renderCheckbox,
    renderField,
    renderFreeField,
    renderRadio,
    renderSelect
} from "../../../../../common/utils/FormComponent";
import {withRouter} from "react-router";
import {getIntlMsg} from "../../../../../../constants/utils";
import {FileInput} from "../../../../../common/utils/FileInput";
import {useDispatch, useSelector} from "react-redux";
import actions from "../../../../../../thunks/settings/categoryThunks";
import {GlobalContext} from "../../../../../index";
import TextEditorWYSIWYG from "../../../../../common/utils/RichTextEditorWYSIWYG";
import params from "../../../../../../constants/utils/params";
import swal from "sweetalert";
import {validate} from "../../healthcenter/form/validator";


let renderSlides = injectIntl(props => {
    const {intl, fields, meta: {error}, isAdd, touched, reset} = props

    const dispatch = useDispatch();


    const {lang, setLang, hideModal} = useContext(GlobalContext);

    const {item, loading, status} = useSelector(state => ({
        loading: state.componentReducer.loadingItem,
        item: state.componentReducer.sliderItem,
        status: state.componentReducer.status,
    }));

    const options = {options: ['sliders']};

    useEffect(() => {
        dispatch(actions.loadItems(options));
    }, [dispatch]);

    const galeries = useSelector(state => state.componentReducer?.items || []);
    const formattedGaleries = [];
    galeries.forEach(g => g.pageName === 'SECTOR' ?
        formattedGaleries.push({code: g?.id, name: g?.name?.frContent}) : {})


    const {languages} = params;

    const [dirty, setDirty] = useState(false)

    const handleChangeLanguage = val => {
        setLang(val)
        setDirty(false)
    }

    const handleChangeDirty = val => {
        setDirty(true)
    }

    const resetSlider = () => {
        hideModal('slides')
        dispatch(actions.resetItem({options: ['sliders']}))
    }


    const DeleteSlide = et => {

        swal({
            title: "Êtes-vous sûr?",
            text: "de supprimer ce slide",
            icon: "warning",
            buttons: ["Non", "Oui"],
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    const param = 'slides/' + et;
                    const params = {intl, item: {id: item?.id}, options: ['sliders'], param: param}
                    dispatch(actions.removeItem(params))
                } else {
                }
            });
    }


    const handlePublishUnPublish = (id, status) => {

        swal({
            title: "voulez-vous vraiment",
            text: status === "publish" ? "le Publier" : "ne le pas Publier",
            icon: "warning",
            buttons: ["Non", "Oui"],
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    dispatch(actions.saveOrUpdateItem({
                        options: ['common', 'slide'],
                        intl,
                        action: '',
                        item: {id: id},
                    }))
                } else {
                }
            });
    }

    if (!fields.length) fields.push();
    if (status === "Succeeded") resetSlider()
    return (
        <div className="row">
            <div className="col-md-12 offset-5 pb-10">
                    <span className="btn btn-sm btn-outline-primary" onClick={() => fields.push()}>
                       Ajouter une nouvelle galerie
                    </span>
            </div>
            <div className="col-sm-12">
                {fields.map((sv, index) => (
                    <div className={'row'} key={index}>
                        <div className="col-sm-1">
                            <h1 className="mt-5 text-center">{index + 1}</h1>
                        </div>
                        <div className="col-sm-6 ml-5">
                            <div className="form-group">

                                <label htmlFor="test_type" className="font-weight-bold">
                                    Service :
                                </label>

                                <Field name={`${sv}id`} component="select" className="form-control">
                                    <option value={''}></option>
                                    {formattedGaleries && formattedGaleries.length > 0 ? formattedGaleries.map((item, i) =>
                                        <option value={item?.code} key={i}>
                                            {item && item?.name?.toUpperCase()}
                                        </option>
                                    ) : null}
                                </Field>
                                {touched && error &&
                                <span className="form-text text-primary">
                                <FormattedMessage id={validate.error}/>
                        </span>}

                            </div>

                        </div>

                        <div className="col-sm-4">
                            <button type="button" title={getIntlMsg(intl, 'app.label.remove_trip')}
                                    className="btn btn-icon btn-light-primary btn-circle mr-2 mt-5"
                                    onClick={() => fields.remove(index)}>
                                <span className="material-icons">close</span>
                            </button>
                            <button type="button" title={getIntlMsg(intl, 'app.label.remove_trip')}
                                    className="btn btn-icon btn-light-primary btn-circle mr-2 mt-5"
                                    onClick={() => DeleteSlide(item?.gallery[index]?.id)}>
                                <i className="flaticon2-trash"/>
                            </button>
                            {item?.gallery[index]?.status ?
                                <button type="button" title={getIntlMsg(intl, 'app.label.remove_trip')}
                                        className="btn btn-icon btn-light-primary btn-circle mr-2 mt-5"
                                        onClick={() => handlePublishUnPublish(item?.gallery[index]?.id, 'unpublish')}>
                                    <span className="material-icons">unpublished</span>
                                </button> : <button type="button" title={getIntlMsg(intl, 'app.label.remove_trip')}
                                                    className="btn btn-icon btn-light-primary btn-circle mr-2 mt-5"
                                                    onClick={() => handlePublishUnPublish(item?.gallery[index]?.id, 'publish')}>
                                    <span className="material-icons">publish</span>
                                </button>}
                        </div>
                    </div>
                ))}
                {error && <li className="error">{error}</li>}
            </div>
        </div>
    );
})
export default React.memo(withRouter(renderSlides))
