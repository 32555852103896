import * as React from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {useParams, withRouter} from "react-router";
import {connect, useDispatch, useSelector} from "react-redux";
import useEntityManager from "../../../../common/hooks/useEntityManager";
import actions from "../../../../../thunks/common/coreThunks";
import {Company, CompanyMapper, Sliders} from '../../../../../constants/models/company'
import {useContext, useEffect, useState} from "react";
import SubHeader from "../../../common/subheader";
import {getPatientValues} from "../../../../../constants/options/subheaderValues";
import ModalComponent from "./form/modalComponent";
import MessageListComponent from './listing'
import FormComponent from "./form/formComponent";
import DocumentItem from "./form/documentitem";
import Applicant from "./form/Applicant";
import {GlobalContext} from "../../../../index";

const MessageList = injectIntl((props) => {

    const {
        intl, setView, view
    } = props;

    const {page} = useParams();
    const fullNameStyle = {fontWeight: "bold"}
    const emailStyle = {display: "inline-block"}


    const dispatch = useDispatch();
    const {lang, setLang, setModalChildren, showModal} = useContext(GlobalContext);

    const options = {options: ['recruitments']};

    useEffect(() => {
        dispatch(actions.loadItems(options));
    }, [dispatch]);

    const [item, setItem] = useState('')
    const params = {options: [page]};
    const {handleLoadItems} = useEntityManager(params, page)


    const handleSetModal = item => {
        showModal('staffing')
        setModalChildren(<FormComponent item={item}/>);
        dispatch(actions.resetItem({options: ['recruitments']}))
        setItem("")

    }

    const EditRecruitment = id => {
        const params = {intl, item: {id: id}, options: ['recruitments']}
        dispatch(actions.loadItem(params))
        showModal('staffing')
        setModalChildren(<FormComponent/>);
        setItem("")
    }

    const showDocument = item => {
        showModal('logo')
        setModalChildren(<DocumentItem items={item}/>);
        setItem("")
    }


    const showApplications = item => {
        showModal('applicant')
        setModalChildren(<Applicant item={item} showDocument={showDocument}/>);
        setItem(item)
    }

    return (
        <React.Fragment>
            <SubHeader {...{changeView: setView, view, btnOptions: getPatientValues}} noButton/>

            <div className="flex-column-fluid pt-5">
                <div className="container">
                    <div className="card card-custom">
                        <div className="card-body">

                            <div className={'row'}>
                                <div className={'col-sm-12'}>
                                    <button className="btn btn-outline-primary font-weight-bold ml-3  float-right"
                                            type="button" onClick={() => handleSetModal()}>
                                        Ajout d'un nouveau recrutement
                                    </button>
                                </div>
                                <div className={'col-sm-12'}>
                                    <MessageListComponent handleSetModal={handleSetModal}
                                                          EditRecruitment={EditRecruitment} showDocument={showDocument}
                                                          showApplications={showApplications}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalComponent item={item}/>
        </React.Fragment>

    );
});

export default MessageList
