import React, {useContext, useEffect, useState} from "react"
import {Field} from "redux-form";
import {FormattedMessage, injectIntl} from 'react-intl';
import {
    renderCheckbox,
    renderField,
    renderFreeField,
    renderRadio,
    renderSelect
} from "../../../../../common/utils/FormComponent";
import {withRouter} from "react-router";
import {getIntlMsg} from "../../../../../../constants/utils";
import {FileInput} from "../../../../../common/utils/FileInput";
import {useDispatch, useSelector} from "react-redux";
import actions from "../../../../../../thunks/settings/categoryThunks";
import {GlobalContext} from "../../../../../index";
import TextEditorWYSIWYG from "../../../../../common/utils/RichTextEditorWYSIWYG";
import params from "../../../../../../constants/utils/params";
import swal from "sweetalert";
import {canDisplay} from "../../../../../../constants/utils/security";
import {FileInputDocument} from "../../../../../common/utils/FileInputDocument";
import {validate} from "./validator";


let renderRequiredDocuments = injectIntl(props => {
    const {intl, fields, meta: {error}, isAdd, touched, reset} = props

    const dispatch = useDispatch();
    const options = {options: ['categories']};

    const {lang, setLang, hideModal} = useContext(GlobalContext);

    const {item, loading, status} = useSelector(state => ({
        loading: state.componentReducer.loadingItem,
        item: state.componentReducer.sliderItem,
        status: state.componentReducer.status,
    }));


    const {languages} = params;

    const [dirty, setDirty] = useState(false)

    const handleChangeLanguage = val => {
        setLang(val)
        setDirty(false)
    }

    const handleChangeDirty = val => {
        setDirty(true)
    }

    const resetSlider = () => {
        hideModal('slides')
        dispatch(actions.resetItem({options: ['sliders']}))
    }


    const DeleteSlide = et => {

        swal({
            title: "Êtes-vous sûr?",
            text: "de supprimer cet attachement",
            icon: "warning",
            buttons: ["Non", "Oui"],
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    const param = 'slides/' + et;
                    const params = {intl, item: {id: item?.id}, options: ['sliders'], param: param}
                    dispatch(actions.removeItem(params))
                } else {
                }
            });
    }


    const {userSession: {roles}} = useContext(GlobalContext);
    if (!fields.length) fields.push();
    if (status === "sliderSucceeded") resetSlider()
    return (
        <div className="row">


            <div className="col-md-12  pb-10">
                    <span className="btn btn-sm btn-outline-primary float-lg-right" onClick={() => fields.push()}>
                      Ajouter un nom du document requis
                    </span>
            </div>
            <div className="col-sm-12">
                {fields.map((lg, index) => (
                    <div className={'row'} key={index}>

                        <div className="col-sm-1">
                            <h1 className="mt-5 text-center">{index + 1}</h1>
                        </div>
                        <div className="col-sm-7">
                            <Field
                                className="form-control form-control-sm"
                                id={`${lg}requiredDocs`}
                                name={`${lg}name`}
                                component={renderField}
                                type="text"
                                label={`doc_name`}
                                validate={validate.error}
                            />
                        </div>
                        <div className="col-sm-2">
                            <button type="button" title={getIntlMsg(intl, 'app.label.remove_trip')}
                                    className="btn btn-icon btn-light-primary btn-circle mr-2 mt-5"
                                    onClick={() => fields.remove(index)}>
                                <span className="material-icons">close</span>
                            </button>
                        </div>
                        <div className="col-sm-2">
                            <button type="button" title={getIntlMsg(intl, 'app.label.remove_trip')}
                                    className="btn btn-icon btn-light-primary btn-circle mr-2 mt-5"
                                    onClick={() => DeleteSlide(item?.slides[index]?.id)}>
                                <i className="flaticon2-trash"/>
                            </button>
                        </div>
                    </div>
                ))}
                {error && <li className="error">{error}</li>}
            </div>
        </div>
    );
})
export default React.memo(withRouter(renderRequiredDocuments))
