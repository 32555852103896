import * as validator from "../../../../../../constants/utils/validator";


export const validate = values => {
    const errors = {};
    errors.frname = validator.validateString('frname', values.frname, 3, 100000, true);
    errors.frabout = validator.validateString('frabout', values.frabout, 10, 10000000000000, true);
    errors.place = validator.validateString('place', values.place, 3, 10000000000000, true);
    errors.page = validator.validateString('page', values.page, 3, 150, true);
    // errors.frdescription = validator.validateString('frdescription', values.frdescription, 10, 1000, true);



    const minDate = new Date(Date.now() - 12 * 60 * 60 * 1000)  // can't request more than 365 days before trip
    const minEndDate = new Date(Date.now() - 24 * 60 * 60 * 1000)  // can't request more than 365 days before trip
    const maxDate = new Date(Date.now() + 30 * 24 * 60 * 60 * 1000)  // can't request more than 365 days before trip



    //
    // errors.dateStarted = validator.validateDate('dateStarted', values.dateStarted, minDate, maxDate, true);
    // errors.dateEnded = validator.validateDate('dateEnded', values.dateEnded, minDate, maxDate, true);
    // errors.dateStarted = validator.validateDate('dateStarted', values.dateStarted, minEndDate, new Date(values.dateEnded || null), true);
    return errors
};


