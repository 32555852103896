import * as React from "react";
import actions from "../../../../../../../thunks/common/coreThunks";
import {FormattedMessage, injectIntl} from "react-intl";
import {withRouter} from "react-router";
import {Link, useParams} from "react-router-dom";
import {useContext, useRef} from "react";
import {GlobalContext} from "../../../../../../index";
import {useDispatch} from "react-redux";
import DeleteItem from "../../../../../common/modal/DeleteItem";
import {getIntlMsg} from "../../../../../../../constants/utils";
import ChangeStateModal from "../../../../../common/modal/ChangeState";
import {useReactToPrint} from 'react-to-print';
import RequestForm from "../../../reports/traveler/RequestForm";

const ReceptBtn = injectIntl(props => {

    const {institution: {acronym}} = useContext(GlobalContext);
    const {module} = useParams()
    const dispatch = useDispatch()
    const {intl, handleStateChange, id,item} = props
    const handleConfirmReception = () => {
    }
    const requestFormRef = useRef();
    const params = {intl, item: {id}, options: [module]}

    const handlePrintRequestForm = useReactToPrint({
        content: () => requestFormRef.current,
    });

    return (

        <div className="btn-group btn-group-sm" role="group" aria-label="Basic example">
            {item?.applicant?.socialInfos !== null ?
            <button type="button" className="btn btn-light-primary" onClick={handlePrintRequestForm}>
                <FormattedMessage id={`app.label.print_form`}/>
            </button>: null}
            <Link to={`/${acronym}/${module}/registration/${item?.type==='TRAVELER'?'traveler':'staff'||''}/${id}/edit`} className="btn btn-light-primary"
                  onClick={() => {
                      dispatch(actions.loadItem(params))
                  }}>
                <FormattedMessage id={`app.label.adjust_request`}/>
            </Link>
            {item?.departure?
            <button type="button" className="btn btn-light-primary" data-toggle="modal" data-target={`#notification${id}`}
                    title={getIntlMsg(intl, `app.label.send_to_notification`)}>
                <FormattedMessage id={`app.label.send_to_notification`}/>
            </button>:null}

            <ChangeStateModal {...{id, title:'notification', handleStateChange}}/>

            <div style={{display: "none", overflow: "hidden", height: 0}}>
                <RequestForm ref={requestFormRef} {...{item}}/>
            </div>
        </div>
    );
});


export default React.memo(withRouter(ReceptBtn))
