import * as React from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {useParams, withRouter} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {useContext, useEffect} from "react";
import params from "../../../../../../constants/utils/params";
import {GlobalContext} from "../../../../../index";
import MaterialTable from 'material-table';
import {getIntlMsg, Localization} from "../../../../../../constants/utils";
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";
import actions from "../../../../../../thunks/common/coreThunks";
import moment from 'moment'
import Slides from "../form/sliderComponent";
import swal from "sweetalert";
import {canDisplay} from "../../../../../../constants/utils/security";

const HelpList = injectIntl((props) => {

    const dispatch = useDispatch();
    const {intl, formView, setFormView, EditSlider, handleShowSlideModal} = props;
    const {page, id} = useParams();
    const options = {options: ['categories']};

    const {items, loading} = useSelector(state => ({
        loading: state.companyReducer.loadingItems,
        items: state.componentReducer?.items || [],
    }));


    const {languages} = params;
    const {lang, setLang} = useContext(GlobalContext);

    const formattedItems = [];
    items.forEach(item => {
        formattedItems.push({
            name: item?.name?.frContent,
            description: item?.description?.frContent,
            pageName: item?.pageName,
            created: moment(item?.created).format('DD-MM-YYYY'),
            slides: item?.slides?.length,
            id: item?.id,
            status: item?.status,
        })
    })

    const handlePublishUnPublish = (id, status) => {

        swal({
            title: "voulez-vous vraiment",
            text: status === "publish" ? "le Publier" : "ne le pas Publier",
            icon: "warning",
            buttons: ["Non", "Oui"],
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    dispatch(actions.saveOrUpdateItem({
                        options: ['common', 'slider'],
                        intl,
                        action: '',
                        item: {id: id},
                    }))
                } else {
                }
            });
    }

    const columns = [
        {
            title: "name",
            type: 'text',
            field: 'name'
        },
        {
            title: "Description",
            render: rowData => (<div className={'overflow-ellipsis'}>
                <div
                    dangerouslySetInnerHTML={{
                        __html: rowData?.description
                    }}>
                </div>
            </div>),
        },
        {
            title: "Page",
            field: 'pageName'
        },
        {
            title: "Nombre de slide",
            field: 'slides'
        },
        {
            title: "Date de creation",
            field: 'created'
        },
        {
            title: "Status",
            render: rowData => rowData.status ? <span className="text-success">publié</span> :
                <span className="text-danger">Non publié</span>
        },
    ]
    const {userSession: {roles}} = useContext(GlobalContext);

    return (
        <div
            className="col-md-12 pt-4" style={{}}>

            {items && items.length > 0 ?
                <MaterialTable
                    className="col-md-12"
                    title="Liste des Diapositives"
                    columns={columns}
                    data={formattedItems ? formattedItems : []}
                    icon='unfold_less'
                    localization={Localization('fr')}
                    options={{
                        headerStyle: {
                            backgroundColor: '#ff7f26',
                            color: '#FFF',
                            fontFamily: 'Ki',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            fontVariant: 'normal',
                            lineHeight: 1,
                            fontSize: '15px'
                        },
                        actionsColumnIndex: -1,
                        exportButton: true,
                        exportAllData: true,
                        pageSize: formattedItems?.length,
                        pageSizeOptions: [10, 20, 25, 50, 100],

                    }}
                    actions={[{
                        icon: row => (
                            <span className="material-icons">edit</span>
                        ),
                        tooltip: 'Edit',
                        onClick: (e, rowData) => {
                            EditSlider(rowData.id)
                        }
                    }, {
                        icon: row => (
                            <span className="material-icons">add</span>
                        ),
                        tooltip: 'Ajouter un slide',
                        onClick: (e, row) => {
                            handleShowSlideModal(row.id)
                        }
                    }, {
                        icon: row => {
                            console.log("+_=_=)=", row)
                            return (
                                canDisplay(roles, 'publish') && <a href="#"
                                                                   className="btn btn-light-primary font-weight-bolder font-size-sm m-0 p-2 ml-3">
                                    <i className="material-icons font-size-sm p-0">publish</i>
                                </a>
                            )
                        },
                        tooltip: 'Publier',
                        onClick: (e, row) => {

                            if (row.status) {
                                handlePublishUnPublish(row.id, 'unpublish')
                            } else {
                                handlePublishUnPublish(row.id, 'publish')
                            }
                        }
                    }]
                    }

                /> :
                <SkeletonTheme color={'rgba(255,127,38,0.68)'} highlightColor="#444">
                <p>
                <Skeleton count={3}/>
                </p>
                </SkeletonTheme>}
        </div>
);
})

export default React.memo(withRouter(HelpList))

