import React, {useEffect} from "react"
import {Field} from "redux-form";
import {FormattedMessage, injectIntl} from 'react-intl';
import {
    renderCheckbox,
    renderField,
    renderFreeField,
    renderRadio,
    renderSelect
} from "../../../../../common/utils/FormComponent";
import {withRouter} from "react-router";
import {getIntlMsg} from "../../../../../../constants/utils";
import {countries} from "../../../../../../constants/options/countries";
import {useDispatch, useSelector} from "react-redux";
import {nameAcronym} from "../../../../../../constants/utils";
import {validate} from "./validator";
import actions from "../../../../../../thunks/settings/categoryThunks";

let renderService = injectIntl(props => {
    const {intl, fields, meta: {error, touched}, isAdd,} = props


    const dispatch = useDispatch();
    const options = {options: ['services']};

    useEffect(() => {
        dispatch(actions.loadItems(options));
    }, [dispatch]);

    const services = useSelector(state => state.adminReducer?.services || []);
    const formattedServs = [];
    services.forEach(service => {
        formattedServs.push({code: service?.id, name: service?.name?.frContent})
    })

    if (!fields.length) fields.push();
    return (
        <div className="row">
            <div className="col-md-12 offset-5 pb-10">
                    <span className="btn btn-sm btn-outline-primary" onClick={() => fields.push()}>
                       Ajouter un service
                    </span>
            </div>
            <div className="col-sm-12">
                {fields.map((sv, index) => (
                    <div className={'row'} key={index}>
                        <div className="col-sm-1">
                            <h1 className="mt-5 text-center">{index + 1}</h1>
                        </div>
                        <div className="col-sm-6 ml-5">
                            <div className="form-group">

                                <label htmlFor="test_type" className="font-weight-bold">
                                    Service :
                                </label>

                                <Field name={`${sv}id`} component="select" className="form-control">
                                    <option value={''}></option>
                                    {formattedServs && formattedServs.length > 0 ? formattedServs.map((item, i) =>
                                        <option value={item?.code} key={i}>
                                            {item && item?.name?.toUpperCase()}
                                        </option>
                                    ) : null}
                                </Field>
                                {touched && error &&
                                <span className="form-text text-primary">
                                <FormattedMessage id={validate.error}/>
                        </span>}

                            </div>

                        </div>

                        <div className="col-sm-1">
                            <button type="button" title={getIntlMsg(intl, 'app.label.remove_trip')}
                                    className="btn btn-icon btn-light-primary btn-circle mr-2 mt-5"
                                    onClick={() => fields.remove(index)}>
                                <i className="flaticon2-trash"/>
                            </button>
                        </div>
                    </div>
                ))}
                {error && <li className="error">{error}</li>}
            </div>
        </div>
    );
})
export default React.memo(withRouter(renderService))
