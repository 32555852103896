import * as React from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {useParams, withRouter} from "react-router";
import {connect, useDispatch, useSelector} from "react-redux";
import useEntityManager from "../../../../common/hooks/useEntityManager";
import actions from "../../../../../thunks/common/coreThunks";
import {Company, CompanyMapper, Sliders} from '../../../../../constants/models/company'
import {useContext, useEffect} from "react";
import SubHeader from "../../../common/subheader";
import {getPatientValues} from "../../../../../constants/options/subheaderValues";
import ModalComponent from "./form/modalComponent";
import MessageListComponent from './listing'
import FormComponent from "./form/formComponent";
import {GlobalContext} from "../../../../index";

const DonateList = injectIntl((props) => {

    const {
        intl, setView, view
    } = props;

    const {page} = useParams();
    const fullNameStyle = {fontWeight: "bold"}
    const emailStyle = {display: "inline-block"}


    const dispatch = useDispatch();
    const {lang, setLang, setModalChildren, showModal} = useContext(GlobalContext);

    const options = {options: ['Grants']};

    useEffect(() => {
        dispatch(actions.loadItems(options));
    }, [dispatch]);


    const params = {options: [page]};
    const {handleLoadItems} = useEntityManager(params, page)


    const handleSetModal = item => {
        showModal('grants')
        setModalChildren(<FormComponent item={item}/>);
        dispatch(actions.resetItem({options: ['grants']}))
    }


    return (
        <React.Fragment>
            <SubHeader {...{changeView: setView, view, btnOptions: getPatientValues}} noButton/>

            <div className="flex-column-fluid pt-5">
                <div className="container">
                    <div className="card card-custom">
                        <div className="card-body">

                            <div className={'row'}>
                                    <MessageListComponent handleSetModal={handleSetModal}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalComponent/>
        </React.Fragment>

    );
});

export default DonateList
