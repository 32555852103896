import React, {useContext, useEffect} from "react"
import {Field} from "redux-form";
import {FormattedMessage, injectIntl} from 'react-intl';
import {
    renderCheckbox,
    renderField,
    renderFreeField,
    renderRadio,
    renderSelect
} from "../../../../../common/utils/FormComponent";
import {withRouter} from "react-router";
import {getIntlMsg} from "../../../../../../constants/utils";
import {FileInput} from "../../../../../common/utils/FileInput";
import {useDispatch, useSelector} from "react-redux";
import actions from "../../../../../../thunks/settings/categoryThunks";
import {GlobalContext} from "../../../../../index";
import LogoForm from "./logoForm";


let renderLogo = injectIntl(props => {
    const {intl, fields, meta: {error}, isAdd, touched} = props
    const validate = {}

    const {modalChildren, setModalChildren, showModal} = useContext(GlobalContext)
    const dispatch = useDispatch();
    const options = {options: ['categories', 'entity-type', 'LOGO']};

    useEffect(() => {
        dispatch(actions.loadItems(options));
    }, [dispatch]);


    const categories = useSelector(state => state.categoryReducer.items);
    const formattedCats = [];
    categories.forEach(category => {
        formattedCats.push({code: category?.id, name: category?.entityType})
    })
    const handleSetModal = () => {
        showModal('logo')
        setModalChildren(<LogoForm/>);
    }

    const {item, loading} = useSelector(state => ({
        loading: state.companyReducer.loadingItem,
        item: state.companyReducer.item,
    }));

    const EditLogo = et => {
        const params = {intl, item: {id: et.id}, options: ['company', 'logo']}
        dispatch(actions.loadItem(params))
        showModal('logo')
        setModalChildren(<LogoForm/>);
    }

    const DeleteLogo = et => {
        const params = {intl, item: {id: et.id}, options: ['company', 'logo']}
        dispatch(actions.removeItem(params))
    }
    return (
        <div className="tab-pane fade show active" id="logo" role="tabpanel" aria-labelledby="logo">
            <div className="row">
                <div className="col-md-12 pb-10">
                    <span className="btn btn-sm btn-outline-primary float-lg-right" data-toggle="modal"
                          data-target="#exampleModalCenter" onClick={() => handleSetModal()}>
                        <FormattedMessage id="app.label.add_logo"/>
                    </span>
                </div>
                {item?.logos?.map((lg, index) => (
                    <div className="col-sm-6">
                        <div className={'row pb-2'} key={index}>
                            <div className="col-sm-1">
                                <h1 className="mt-5 text-center">{index + 1}</h1>
                            </div>
                            <div className="col-sm-2">
                                <div className="symbol symbol-60 symbol-circle symbol-xl-60">
                                    <div className="symbol-label"
                                         style={{backgroundImage: lg?.fileName ? `url(${lg?.fileName})` : "url('/assets/media/avatars/male.png')"}}></div>
                                    <i className="symbol-badge symbol-badge-bottom bg-success"></i>
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div className="form-group">
                                    <label htmlFor="test_type" className="font-weight-bold">
                                        <FormattedMessage id="app.label.logo_category"/> :
                                    </label>
                                    <div>{lg?.type?.name?.frContent?.toUpperCase()}</div>
                                </div>

                            </div>

                            <div className="col-sm-1">
                                <button type="button" title={getIntlMsg(intl, 'app.label.remove_trip')}
                                        className="btn btn-icon btn-light-primary btn-circle mr-2 mt-5"
                                        onClick={() => DeleteLogo(lg)}>
                                    <i className="flaticon2-trash"/>
                                </button>
                            </div>
                            <div className="col-sm-1">
                                <button type="button" title={getIntlMsg(intl, 'app.label.remove_trip')}
                                        className="btn btn-icon btn-light-primary btn-circle mr-2 mt-5"
                                        onClick={() => EditLogo(lg)} data-toggle="modal"
                                        data-target="#exampleModalCenter">
                                    <i className="flaticon2-edit"/>
                                </button>
                            </div>
                        </div>
                    </div>
                ))}
                {error && <li className="error">{error}</li>}

            </div>
        </div>
    );
})
export default React.memo(withRouter(renderLogo))
