import types from "../../actions/types/admin/actionTypes";
import initialState from "../../../constants/utils/initialState";


const reducer = (state = initialState.adminReducer, action) => {

        switch (action.type) {


            case types.LOAD_CATEGORIES_ENTITY_TYPE_MEMBRE:
                return {...state, loadingMemberCategory: action.payload};

            case types.LOAD_CATEGORIES_ENTITY_TYPE_MEMBER_SUCCEEDED:
                return {...state, memberCategory: action.payload};

            case types.LOAD_MEMBERS:
                return {...state, loadingMember: action.payload};

            case types.LOAD_MEMBERS_SUCCEEDED:
                return {...state, members: action.payload};

            case types.LOAD_MEMBER_SUCCEEDED:
                return {...state, memberItem: action.payload};

            case types.RESET_MEMBER:
                return {...state, memberItem: null, status: null};

            case types.UPDATE_MEMBER_SUCCEEDED:
            case types.UPDATE_COMMON_MEMBER_SUCCEEDED:
                return {
                    ...state,
                    members: state.members.map(item => item.id === action.payload.id ? action.payload : item),
                    status: 'memberSucceeded'
                };
            case types.SAVE_MEMBER_SUCCEEDED:
                return {
                    ...state,
                    members: [...state.members, action.payload],
                    status: 'memberSucceeded'
                };
            case types.LOAD_MEMBERS_FAILED:
                return {...state, loadingMember: action.payload};

            case types.DELETE_MEMBER_SUCCEEDED:
                return {...state, members: state.members.filter(item => item.id !== action.payload?.item)};


            case types.LOAD_CATEGORIES_ENTITY_TYPE_NEWS:
                return {...state, loadingNewsCategory: action.payload};

            case types.LOAD_CATEGORIES_ENTITY_TYPE_NEWS_SUCCEEDED:
                return {...state, newsCategory: action.payload};

            case types.LOAD_NEWS:
                return {...state, loadingNews: action.payload};

            case types.LOAD_NEWS_SUCCEEDED:
                return {...state, news: action.payload};

            case types.LOAD_NEW_SUCCEEDED:
                return {...state, newsItem: action.payload};

            case types.RESET_NEW:
                return {...state, newsItem: null, status: null, locale: null};

            case types.UPDATE_NEW_SUCCEEDED:
            case types.UPDATE_COMMON_NEW_SUCCEEDED:
                return {
                    ...state,
                    news: state.news.map(item => item.id === action.payload.id ? action.payload : item),
                    status: 'newsSucceeded'
                };
            case types.SAVE_NEW_SUCCEEDED:
                return {
                    ...state,
                    news: [...state.news, action.payload],
                    status: 'newsSucceeded'
                };
            case types.LOAD_NEWS_FAILED:
                return {...state, loadingNews: action.payload};

            case types.DELETE_NEW_SUCCEEDED:
                return {...state, news: state.news.filter(item => item.id !== action.payload?.item)};


            case types.LOAD_SECTORS:
                return {...state, loadingSectors: action.payload};

            case types.LOAD_SECTORS_SUCCEEDED:
                return {...state, sectors: action.payload};

            case types.LOAD_SECTOR_SUCCEEDED:
                return {...state, sectorItem: action.payload};

            case types.RESET_SECTOR:
                return {...state, sectorItem: null, status: null};

            case types.UPDATE_SECTOR_SUCCEEDED:
            case types.UPDATE_COMMON_SECTOR_SUCCEEDED:
                return {
                    ...state,
                    sectors: state.sectors.map(item => item.id === action.payload.id ? action.payload : item),
                    status: 'sectorSucceeded'
                };


            case types.SAVE_SECTOR_SUCCEEDED:
                return {
                    ...state,
                    sectors: [...state.sectors, action.payload],
                    status: 'sectorSucceeded'
                };
            case types.LOAD_SECTORS_FAILED:
                return {...state, loadingSectors: action.payload};

            case types.DELETE_SECTOR_SUCCEEDED:
                return {...state, sectors: state.sectors.filter(item => item.id !== action.payload?.item)};


            case types.LOAD_TESTIMONIALS:
                return {...state, loadingTestimonials: action.payload};

            case types.LOAD_TESTIMONIALS_SUCCEEDED:
                return {...state, testimonials: action.payload};

            case types.LOAD_TESTIMONIAL_SUCCEEDED:
                return {...state, testimonialItem: action.payload};

            case types.RESET_TESTIMONIAL:
                return {...state, testimonialItem: null, status: null};

            case types.UPDATE_TESTIMONIAL_SUCCEEDED:
            case types.UPDATE_COMMON_TESTIMONIAL_SUCCEEDED:
                return {
                    ...state,
                    testimonials: state.testimonials.map(item => item.id === action.payload.id ? action.payload : item),
                    status: 'testimonialSucceeded'
                };
            case types.SAVE_TESTIMONIAL_SUCCEEDED:
                return {
                    ...state,
                    testimonials: [...state.testimonials, action.payload],
                    status: 'testimonialSucceeded'
                };
            case types.LOAD_TESTIMONIALS_FAILED:
                return {...state, loadingTestimonials: action.payload};

            case types.DELETE_TESTIMONIAL_SUCCEEDED:
                return {...state, testimonials: state.testimonials.filter(item => item.id !== action.payload?.item)};


            case types.LOAD_SERVICES:
                return {...state, loadingServices: action.payload};

            case types.LOAD_SERVICES_SUCCEEDED:
                return {...state, services: action.payload};

            case types.LOAD_SERVICE_SUCCEEDED:
                return {...state, serviceItem: action.payload};

            case types.RESET_SERVICE:
                return {...state, serviceItem: null, status: null};

            case types.UPDATE_SERVICE_SUCCEEDED:
            case types.UPDATE_COMMON_HC_SERVICE_SUCCEEDED:
                return {
                    ...state,
                    services: state.services.map(item => item.id === action.payload.id ? action.payload : item),
                    status: 'serviceSucceeded'
                };
            case types.SAVE_SERVICE_SUCCEEDED:
                return {
                    ...state,
                    services: [...state.services, action.payload],
                    status: 'serviceSucceeded'
                };
            case types.LOAD_SERVICES_FAILED:
                return {...state, loadingServices: action.payload};

            case types.DELETE_SERVICE_SUCCEEDED:
                return {...state, services: state.services.filter(item => item.id !== action.payload?.item)};


            case types.LOAD_HEALTH_CENTERS:
                return {...state, loadingHealthcenters: action.payload};

            case types.LOAD_HEALTH_CENTERS_SUCCEEDED:
                return {...state, healthcenters: action.payload};

            case types.LOAD_HEALTH_CENTER_SUCCEEDED:
                return {...state, healthcenterItem: action.payload};

            case types.RESET_HEALTH_CENTER:
                return {...state, healthcenterItem: null, hStatus: null};

            // case types.UPDATE_HEALTH_CENTER_SUCCEEDED:
            case types.UPDATE_COMMON_HEALTH_CENTER_SUCCEEDED:
                return {
                    ...state,
                    healthcenters: state.healthcenters.map(item => item.id === action.payload.id ? action.payload : item),
                    hStatus: 'healthcenterSucceeded'
                };

            case types.UPDATE_HEALTH_CENTER_SUCCEEDED:
                return {
                    ...state,
                    healthcenters: state.healthcenters.map(item => item.id === action.payload.id ? action.payload : item),
                    hStatus: 'healthcenterSucceeded'
                };


            case types.SAVE_HEALTH_CENTER_SUCCEEDED:
                return {
                    ...state,
                    healthcenters: [...state.healthcenters, action.payload],
                    hStatus: 'healthcenterSucceeded'
                };

            case types.LOAD_HEALTH_CENTERS_FAILED:
                return {...state, loadingHealthcenters: action.payload};

            case types.DELETE_HEALTH_CENTER_SUCCEEDED:
                return {...state, healthcenters: state.healthcenters.filter(item => item.id !== action.payload?.item)};

            case types.SWITCH_LOCALE:
                return {...state, locale: action.payload};

            case types.LOAD_MESSAGES_SUCCEEDED:
                return {...state, messages: action.payload};


            case types.LOAD_RECRUITMENTS_SUCCEEDED:
                return {...state, recruitments: action.payload};

            case types.LOAD_RECRUITMENT_SUCCEEDED:
                return {...state, recruitmentItem: action.payload};

            case types.SAVE_RECRUITMENT_SUCCEEDED:
                return {
                    ...state,
                    recruitments: [...state.recruitments, action.payload],
                    rStatus: 'recruitmentSucceeded'
                };

            case types.UPDATE_RECRUITMENT_SUCCEEDED:
            case types.UPDATE_COMMON_RECRUITMENT_SUCCEEDED:
                return {
                    ...state,
                    recruitments: state.recruitments.map(item => item.id === action.payload.id ? action.payload : item),
                    rStatus: 'recruitmentSucceeded'
                };

            case types.RESET_RECRUITMENT:
                return {
                    ...state,
                    recruitmentItem: null,
                    rStatus: null
                };

            case types.SAVE_RESEARCH_SUCCEEDED:
                return {
                    ...state,
                    researchItem: action.payload,
                };

            case types.LOAD_RESEARCH_SUCCEEDED:
                return {
                    ...state,
                    researchItem: action.payload,
                };
            case types.UPDATE_RESEARCH_SUCCEEDED:
                return {
                    ...state,
                    researchItem: action.payload,
                };


            case types.LOAD_APPLICATION_RECRUITMENT_SUCCEEDED:
                return {
                    ...state,
                    applications: action.payload,
                };


            case types.LOAD_DONATIONS:
                return {...state, loadingDonations: action.payload};

            case types.LOAD_DONATIONS_SUCCEEDED:
                return {...state, donations: action.payload};

            case types.LOAD_DONATION_SUCCEEDED:
                return {...state, donationItem: action.payload};

            case types.RESET_DONATION:
                return {...state, donationItem: null, status: null};

            case types.UPDATE_DONATION_SUCCEEDED:
                return {
                    ...state,
                    donations: state.donations.map(item => item.id === action.payload.id ? action.payload : item),
                    status: 'donationSucceeded'
                };
            case types.UPDATE_DONATION_PUBLISH_SUCCEEDED:
                return {
                    ...state,
                    donations: action.payload
                };
            case types.SAVE_DONATION_SUCCEEDED:
                return {
                    ...state,
                    donations: [...state.donations, action.payload],
                    status: 'donationSucceeded'
                };
            case types.LOAD_DONATIONS_FAILED:
                return {...state, loadingDonations: action.payload};

            case types.DELETE_DONATION_SUCCEEDED:
                return {...state, donations: state.donations.filter(item => item.id !== action.payload?.item)};

            case types.LOAD_GRANTS_SUCCEEDED:
                return {...state, grants: action.payload};


            default:
                return state;
        }
    }
;

export default reducer;
