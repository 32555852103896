export class StatisticModel {

    constructor(params) {
        if (params) {

            const {
                endescription,
                frdescription,
                htdescription,
                enname,
                frname,
                htname,
                page,
                idName,
                idDesc

            } = params;


            this.description = {
                enContent: endescription,
                frContent: frdescription,
                htContent: htdescription,
                id: idDesc ? idDesc : null
            }

            this.name = {
                enContent: enname,
                frContent: frname,
                htContent: htname,
                id: idName ? idName : null
            }
            this.page = page;
        }
    }
}


export class StatisticMapper {

    constructor(params) {
        if (params) {

            const {
                name,
                description,
                page,
            } = params;

            this.endescription = description?.enContent ? description?.enContent : null
            this.frdescription = description?.frContent ? description?.frContent : null
            this.htdescription = description?.htContent ? description?.htContent : null

            this.enname = name?.enContent
            this.frname = name?.frContent
            this.htname = name?.htContent
            this.page = page;
        }
    }
}

