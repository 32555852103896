import React, {useContext, useEffect} from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {useParams, withRouter} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import useDocumentTitle from "../../../../common/hooks/useDocumentTitle";
import {getIntlMsg} from "../../../../../constants/utils";
import {GlobalContext} from "../../../../index";
import actions from "../../../../../thunks/common/coreThunks";
import GeneralyForm from "./generaly";
import SymptomForm from "../../notify/symptom";
import TravelForm from "../../notify/travel";
import DiagnosticForm from "../../notify/diagnostic";
import InvestigatorForm from "../../notify/investigator";
import SubHeader from "../../../common/subheader";
import {getPatientValues} from "../../../../../constants/options/subheaderValues";
import About from './about'
import Vision from './vision'
import Mission from './mission'
import Origin from './origin'
import HelpFaqs from './help_faqs'


const Institution = injectIntl((props) => {

    const {intl, setView, view} = props;
    const dispatch = useDispatch();
    const {page, secondLevelPage} = useParams();
    useDocumentTitle(getIntlMsg(intl, `app.label.institution`));

    const options = {intl, options: ['requests'], item: {id: secondLevelPage}}
    const {userSession} = useContext(GlobalContext);


    useEffect(() => {
        dispatch(actions.loadItem(options))
    }, [secondLevelPage]);


    const {item, loading, savingOrUpdating, status} = useSelector(state => ({
        loading: state.requestReducer.loadingItem,
        item: state.requestReducer.item,
        savingOrUpdating: state.requestReducer.savingOrUpdating,
    }));


    const tabIds = [
        {id: "#generaly", label: "generaly", icon: "admin_panel_settings", status: "active"},
        {id: "#about", label: "about", icon: "info", status: ""},
        {id: "#vision", label: "vision", icon: "visibility", status: ""},
        {id: "#mission", label: "mission", icon: "visibility", status: ""},
        {id: "#origin", label: "origin", icon: "visibility", status: ""},
        {id: "#help_faqs", label: "help_faqs", icon: "help", status: ""}
    ]

    const tabs = [
        <GeneralyForm key={0} saveOrUpdateItem={actions.saveOrUpdateItem}/>,
        <About key={1} saveOrUpdateItem={actions.saveOrUpdateItem} item={item}/>,
        <Vision key={2} saveOrUpdateItem={actions.saveOrUpdateItem}/>,
        <Mission key={3} saveOrUpdateItem={actions.saveOrUpdateItem}/>,
        <Origin key={4} saveOrUpdateItem={actions.saveOrUpdateItem}/>,
        <HelpFaqs key={5} userSession={userSession} saveOrUpdateItem={actions.saveOrUpdateItem}/>
    ];


    return (
        <React.Fragment>
            <SubHeader {...{btnOptions: getPatientValues}} noButton/>
            <div className="flex-column-fluid">
                <div className="container">
                    <div className="card card-custom">
                        <div className="card-header card-header-tabs-line">
                            <div className="card-toolbar">
                                <ul className="nav nav-tabs nav-bold nav-tabs-line">
                                    {tabIds.map((v, i) => {
                                                return (
                                                    <li className="nav-item" key={i}>
                                                        <a className={`nav-link ${v.status}`} data-toggle="tab" href={v.id}>
                                                    <span className="material-icons">{v.icon}</span>
                                                            <span className="nav-text pl-2">
                                                        <FormattedMessage id={`app.label.${v.label}`}/>
                                                    </span>
                                                        </a>
                                                    </li>)
                                        }
                                    )}
                                </ul>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="tab-content">
                                {tabs.map((item, i) => item)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
});



export default React.memo(withRouter(Institution))