import * as React from "react";
import {useParams, withRouter} from "react-router";
import pages from "../../../../constants/options/pages";
import Institution from "./institution";
import Sliders from "./slider";
import Statistic from "./statistic";

export const ComponentsManager = () => {
    const {page} = useParams()
    console.log("==========PAGE==============", page)

    return _onLoadPage(page);
}

function _onLoadPage(page) {
    switch (page) {
        case pages.COMPONENT_INSTITUTION_PAGE:
            return <Institution/>;
        case pages.COMPONENT_SLIDE_PAGE:
            return <Sliders/>;
        case pages.COMPONENT_STATISTIC_PAGE:
            return <Statistic/>;
        default: {
            return (<h1>hello MOTO</h1>)
        }
    }


}


export default React.memo(withRouter(ComponentsManager));