import React, {useContext, useEffect, useState} from "react"
import {Field} from "redux-form";
import {FormattedMessage} from 'react-intl';
import {renderField} from "../../../../../common/utils/FormComponent";
import {withRouter} from "react-router";
import params from "../../../../../../constants/utils/params";
import {GlobalContext} from "../../../../../../components/index"
import actions from "../../../../../../thunks/settings/categoryThunks";
import {useDispatch, useSelector} from "react-redux";
import RichTextEditorWYSWYG from '../../../../../common/utils/RichTextEditorWYSIWYG';

let FormItems = ({item}, props) => {
    const validate = {}
    const {languages} = params;
    const {lang, setLang} = useContext(GlobalContext);

    const [dirty, setDirty] = useState(false)

    const handleChangeLanguage = val => {
        setLang(val)
        setDirty(false)
    }

    const handleChangeDirty = val => {
        setDirty(true)
    }
    return (
        <div className={`custom_fieldset`}>
            <div className="row pb-5">
                {lang === 'fr' ? <div className="label label-light-primary label-pill label-inline col-md-12 p-6"><span
                        className="">Francais</span></div> :
                    lang === 'en' ? <div className="label label-light-info label-pill label-inline col-md-12 p-6"><span
                            className="">Anglais</span></div> :
                        <div className="label label-light-danger label-pill label-inline col-md-12 p-6"><span
                            className="">Kreyol</span></div>}
            </div>


            <div className="row pb-5">
                <div className="col-sm-12">
                    {languages && languages.map((language, key) =>
                        <span className="mr-4" key={key}>
                            <label htmlFor={`vision_${language.id}`} style={{cursor: "pointer"}}
                                   onClick={() => handleChangeLanguage(language.id)}>
                                <b className={'mr-1'}><FormattedMessage id={`app.label.${language.label}`}/></b>
                                {/*<input type="radio" name="language" value={language.id} id={language.id}*/}
                                {/*	className="ml-2"/>*/}
                                <Field type="radio" component="input" name={'langVision'} value={language.id} id={`vision_${language.id}`}
                                       onClick={() => handleChangeLanguage(language.id)}/>
                            </label>
                        </span>
                    )}

                </div>
            </div>

            <div className="row pb-5">

                <div className="col-sm-12">
                    <label><FormattedMessage id={`app.label.${lang}Content`} defaultMessage={''}/></label>
                    <div style={{border:'1px solid #000',width:'100%'}}>
                        <Field
                            onChange={()=>handleChangeDirty()}
                            label={`${lang}Vision`}
                            name={`${lang}Vision`}
                            id={`${lang}Vision`}
                            meta={{dirty:dirty}}
                            component={RichTextEditorWYSWYG}
                            value={props?.initialValues?.description || ''}
                        />
                    </div>

                </div>

            </div>


        </div>

    );
}
export default React.memo(withRouter(FormItems))
