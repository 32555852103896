import React from "react";
import {Link} from "react-router-dom";

const AccessComponent = () => {
    return (
        <div className="d-flex flex-column flex-root" style={{backgroundImage: "url(/assets/media/acces.jpg)"}}>
            <div className="d-flex flex-column-fluid align-items-first justify-content-center">
                <div className="d-flex flex-column align-items-center px-10 pt-10 pt-lg-30">
                    <div style={{
                        background: 'rgba(255,255,255,0.9',
                        height: 'auto',
                        margin: 'auto',
                        padding: '20px',
                        color: '#0000'
                    }}>
                        <h1 className="font-weight-boldest text-white mb-5 text-access"
                            style={{fontSize: "8rem", color: '#0000'}}>Demande d'accès</h1>
                        <p className="display-3 text-center text-white font-weight-normal py-3 text-access-subtitle">
                            Veuillez contacter l'administrateur du systeme</p>
                        <p className="display-3 text-center text-white font-weight-normal py-3 text-access-subtitle1">
                            rjacques@pih.org</p>
                        <p className="display-3 text-center text-white font-weight-normal py-3 text-access-subtitle1">
                            +509 4328-0323</p>
                        <p className="display-3 text-center text-white font-weight-normal py-3 text-access-subtitle1">
                            jpaullouidor@pih.org</p>
                        <p className="display-3 text-center text-white font-weight-normal py-3 text-access-subtitle1">
                            +509 3171-8645</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AccessComponent